/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, useRouteMatch } from "react-router-dom";
import AffDashBoardLayout from "layouts/dashboard";
import Campaigns from "pages/campaigns";
import AdvertiserCampaigns from "pages/campaigns/advertiser-campaigns";
import Commissions from "pages/commissions";
import DashBoard from "pages/dashboard";
import ProfileSetup from "pages/dashboard/profile-settings";
import Reports from "pages/reports";
import ReportsPayoutHistory from "pages/payouts-history";
import RecurringCommissions from "pages/recurring-commissions";
import Payouts from "pages/payouts";
import MainPayoutsHistory from "pages/main-payouts-history";
import SocialMediaAssets from "pages/marketing-assets/social-media-assets";
import ImageAssets from "pages/marketing-assets/image-assets";
import ImageAssetsViews from "pages/marketing-assets/image-assets-view";
import VideoAssets from "pages/marketing-assets/video-assets";
import DocumentAssets from "pages/marketing-assets/doc-assets";
import { fetchAdvertiserCampaigns } from "redux/actions/campaigns";
import AffiliateData from "utils/user-data";

const AffiliateWrapper = () => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const affData = new AffiliateData().get();

  useEffect(() => {
    const isLoggedin = localStorage.getItem("affiliate_token");
    if (!isLoggedin) {
      window.location.href = "/auth/login";
    } else {
      const data = {
        domain: affData.data.affiliate.domain,
        affId: affData.data.affiliate.id,
      };
      dispatch(fetchAdvertiserCampaigns(data, {}));
    }
  }, []);

  return (
    <>
      <AffDashBoardLayout>
        <Route exact path={`${path}/overview`} component={DashBoard} />
        <Route
          exact
          path={`${path}/profile-settings`}
          component={ProfileSetup}
        />
        <Route exact path={`${path}/campaigns`} component={Campaigns} />
        <Route
          exact
          path={`${path}/campaigns/join-campaigns`}
          component={AdvertiserCampaigns}
        />
        <Route exact path={`${path}/commissions`} component={Commissions} />
        <Route
          exact
          path={`${path}/commissions/recurring`}
          component={RecurringCommissions}
        />
        <Route
          exact
          path={`${path}/marketing-assets/social-media-assets`}
          component={SocialMediaAssets}
        />
        <Route
          exact
          path={`${path}/marketing-assets/image-assets`}
          component={ImageAssets}
        />
        <Route
          exact
          path={`${path}/marketing-assets/image-assets/download/:id`}
          component={ImageAssetsViews}
        />
        <Route
          exact
          path={`${path}/marketing-assets/video-assets`}
          component={VideoAssets}
        />
        <Route
          exact
          path={`${path}/marketing-assets/document-assets`}
          component={DocumentAssets}
        />
        <Route
          exact
          path={`${path}/reports/payouts-history`}
          component={ReportsPayoutHistory}
        />
        <Route exact path={`${path}/reports/overview`} component={Reports} />
        <Route exact path={`${path}/payouts`} component={Payouts} />
        <Route
          exact
          path={`${path}/payouts/payouts-history`}
          component={MainPayoutsHistory}
        />
      </AffDashBoardLayout>
    </>
  );
};

export default AffiliateWrapper;
