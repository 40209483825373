import styled from "styled-components";
import { ActionText } from "globals/styles";

export const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  padding: 0 1em;
  gap: 1em;
  font-size: ${ActionText};
  width: 160px;
  background: transparent;
  height: 40px;
  border: solid 1px ${({ theme }) => theme.selectColor};
  color: ${({ theme }) => theme.selectColor};
  box-sizing: border-box;
  filter: drop-shadow(0px 10px 20px rgba(114, 17, 212, 0.25));
  border-radius: 8px;
  cursor: pointer;
`;

export const PrimaryButton = styled.button`
  background: ${({ theme }) => theme.primaryColor};
  height: 60px;
  border-radius: 8px;
  padding: 0.7em 2em;
  border: none;
  color: #fff;
  font-weight: bold;
  justify-self: flex-end;
  font-size: ${ActionText};
  cursor: pointer;
`;
