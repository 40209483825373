/**
 * /* eslint-disable no-unused-vars
 *
 * @format
 */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../table.scss";
import { Skeleton } from "@mui/material";
import Join from "./join";
import { formatDate } from "utils/date-formatters";
import NoData from "components/nodata";
import { MainTable, TableWrapper } from "../styles";
import { useTranslation } from "react-i18next";

const AdvertiserCampaignsTable = () => {
  const {t, i18n} = useTranslation('common');
  const { campaigns2 } = useSelector((state) => state.aff_campaigns);
  const [cmpId, setCmpId] = useState(null);
  const loading = false;

  const loadingId = 1;
  const [showJoin, setShowJoin] = useState(false);

  const handleCampaignStatusChange = (id, newValue, prevValue) => {
    return true;
  };

  useEffect(() => {}, [campaigns2]);

  const handleJoin = (id) => {
    setShowJoin(true);
    setCmpId(id);
  };
  const renderCampaignsData = () =>
    campaigns2 &&
    campaigns2
      .filter((el) => el.requestStatus !== "approved")
      .map((campaign) => {
        console.log("finding afiliate campagin table data ", campaign);
        const { id, name, commission, status, createdAt, join } = campaign;

        return (
          <tr key={id}>
            <td>
              <label class="customContainer">
                <input type="checkbox" />
                <span class="customCheckmark"></span>
              </label>
            </td>
            <td>{name}</td>
            <td>{commission ?? t("general.no_commission")}</td>
            <td
              style={{
                color:
                  status === "open"
                    ? "#5cbb5c"
                    : status === "pending"
                    ? "#ddb13f"
                    : "#ed5565",
              }}
            >
              {status}
            </td>
            <td>{formatDate(new Date(createdAt), "dd, mm, yyyy")}</td>
            {/* {join && ( */}
            <td>
              <button
                className="apply"
                style={{
                  backgroundColor: `${
                    campaign?.requestStatus == "pending"
                      ? "orange"
                      : campaign?.requestStatus == "declined"
                      ? "red"
                      : "#2ea44e"
                  }`,
                  cursor: `${!join ? "pointer" : "hand"}`,
                }}
                onClick={() => handleJoin(id)}
                disabled={!join}
              >
                {campaign?.requestStatus === "not_requested"
                  ? t("campaign.requestToJoin")
                  : campaign?.requestStatus}
              </button>
            </td>
            {/* )} */}
          </tr>
        );
      });
  const renderCampaignsHeader = () => {
    let header = [
      "",
      t("general.campaign"),
      t("general.commission"),
      t("general.status"),
      t("general.dateCreated"),
      t("general.actions"),
    ];
    return header.map((key, index) => {
      return <th key={index}>{key}</th>;
    });
  };

  return (
    <>
      {!campaigns2.length ? (
        <NoData />
      ) : (
        <TableWrapper>
          <MainTable>
            <tbody>
              <tr
                style={{
                  width: "100%",
                }}
              >
                {renderCampaignsHeader()}
              </tr>
              {renderCampaignsData()}
            </tbody>
          </MainTable>
        </TableWrapper>
      )}
      {/* {showJoin && ( */}
      <Join status={showJoin} changeStatus={setShowJoin} id={cmpId} />
      {/* )} */}
    </>
  );
};

export default AdvertiserCampaignsTable;
