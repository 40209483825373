import styled from "styled-components";

export const DashBoard = styled.section`
  @media screen and (max-width: 1024px) {
    display: block;
  }
`;

export const Stack = styled.div`
  position: absolute;
`;
