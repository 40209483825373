import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { useDispatch } from "react-redux";
import { collapse } from "redux/actions/collapser";

export const CheckBox = () => {
  return (
    <svg
      width="20"
      height="27"
      viewBox="0 0 35 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.8863 3.15355C33.9999 3.26682 34.09 3.40137 34.1514 3.5495C34.2129 3.69763 34.2445 3.85644 34.2445 4.01681C34.2445 4.17719 34.2129 4.33599 34.1514 4.48413C34.09 4.63226 33.9999 4.76681 33.8863 4.88007L16.8203 21.9473C16.707 22.0609 16.5725 22.1509 16.4243 22.2124C16.2762 22.2739 16.1174 22.3055 15.957 22.3055C15.7966 22.3055 15.6378 22.2739 15.4897 22.2124C15.3416 22.1509 15.207 22.0609 15.0938 21.9473L7.77889 14.6324C7.56069 14.4016 7.44107 14.0949 7.44543 13.7773C7.44978 13.4597 7.57777 13.1563 7.80221 12.9316C8.02664 12.7068 8.32985 12.5784 8.64743 12.5736C8.96502 12.5688 9.27195 12.6881 9.50306 12.9059L15.9547 19.3587L32.1622 3.15238C32.2754 3.03884 32.41 2.94876 32.5581 2.88729C32.7062 2.82583 32.865 2.79419 33.0254 2.79419C33.1858 2.79419 33.3446 2.82583 33.4927 2.88729C33.6409 2.94876 33.7754 3.03884 33.8887 3.15238L33.8863 3.15355Z"
        fill="#06f7b9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.109377 28.3979C0.109376 28.8784 0.20408 29.3542 0.388074 29.7981C0.572067 30.242 0.841743 30.6453 1.18168 30.9849C1.52163 31.3245 1.92517 31.5938 2.36924 31.7773C2.81332 31.9609 3.28922 32.0552 3.76974 32.0547H28.1483C29.1182 32.0547 30.0483 31.6694 30.7341 30.9836C31.4199 30.2978 31.8052 29.3677 31.8052 28.3979V16.2009C31.8052 15.8775 31.6767 15.5674 31.448 15.3387C31.2194 15.1101 30.9092 14.9816 30.5858 14.9816C30.2625 14.9816 29.9523 15.1101 29.7236 15.3387C29.495 15.5674 29.3665 15.8775 29.3665 16.2009V28.3979C29.3665 28.7212 29.238 29.0314 29.0094 29.2601C28.7807 29.4887 28.4706 29.6172 28.1472 29.6172H3.76974C3.44635 29.6172 3.13621 29.4887 2.90754 29.2601C2.67886 29.0314 2.5504 28.7212 2.5504 28.3979V4.01925C2.55009 3.85893 2.5814 3.70012 2.64254 3.55191C2.70368 3.4037 2.79345 3.26901 2.90671 3.15553C3.01996 3.04206 3.15449 2.95203 3.30258 2.89061C3.45067 2.82918 3.60942 2.79757 3.76974 2.79757H23.2721C23.5955 2.79757 23.9057 2.6691 24.1344 2.44043C24.363 2.21176 24.4915 1.90162 24.4915 1.57823C24.4915 1.25484 24.363 0.944695 24.1344 0.716025C23.9057 0.487354 23.5955 0.358888 23.2721 0.358888H3.76974C3.28892 0.358426 2.81274 0.452789 2.36843 0.636575C1.92413 0.820362 1.52043 1.08996 1.18044 1.42995C0.840453 1.76994 0.57085 2.17364 0.387064 2.61794C0.203277 3.06225 0.108914 3.53844 0.109377 4.01925V28.3979Z"
        fill="#06f7b9"
      />
    </svg>
  );
};

export const UploadIcon = () => {
  return (
    <svg
      width="85"
      height="74"
      viewBox="0 0 85 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M74.2973 48.363V49.145C74.1213 55.7686 71.1864 62.0573 66.1334 66.6381C61.0803 71.2189 54.3196 73.7199 47.3271 73.595C42.1195 73.6352 37.0107 72.2489 32.6152 69.603C28.2197 66.9571 24.7249 63.1643 22.5513 58.681C16.3557 57.986 10.661 55.1133 6.59383 50.6313C2.52667 46.1492 0.383252 40.3843 0.587593 34.4769C0.791933 28.5694 3.32915 22.9498 7.69732 18.7296C12.0655 14.5095 17.9465 11.9962 24.1768 11.687C23.5864 13.269 23.5864 14.995 24.1768 16.577C19.1905 16.9359 14.5338 19.0745 11.1475 22.5606C7.76132 26.0468 5.89801 30.6206 5.93406 35.358C5.97012 40.0955 7.90285 44.6434 11.3418 48.0829C14.7807 51.5225 19.4695 53.5972 24.4608 53.888H26.1908L26.784 55.428C28.3853 59.3659 31.2238 62.7434 34.9161 65.104C38.6085 67.4646 42.9766 68.6947 47.4305 68.628C53.0533 68.7522 58.5002 66.7668 62.5849 63.1039C66.6697 59.4411 69.0614 54.3977 69.2392 49.072C69.2747 48.4204 69.2747 47.7675 69.2392 47.116L69.028 45.356L70.7053 44.622C73.9504 43.2375 76.593 40.8363 78.1885 37.8225C79.7839 34.8086 80.2349 31.3661 79.4655 28.0742C78.6961 24.7823 76.7533 21.842 73.9639 19.748C71.1746 17.654 67.709 16.5342 64.1504 16.577H60.8254C61.4158 14.995 61.4158 13.269 60.8254 11.687H64.1293C68.7194 11.6556 73.1898 13.0742 76.8338 15.7187C80.4778 18.3633 83.0876 22.0828 84.2509 26.2896C85.4142 30.4964 85.0645 34.9505 83.2571 38.9479C81.4498 42.9454 78.2878 46.2583 74.2709 48.363H74.2973Z"
        fill="#545A6D"
      />
      <path
        d="M53.5711 16.406C53.2314 16.4079 52.8947 16.3462 52.5803 16.2245C52.2658 16.1029 51.9798 15.9236 51.7387 15.697L45.1056 9.389V39.365C45.1056 40.0135 44.8337 40.6353 44.3497 41.0939C43.8657 41.5524 43.2093 41.81 42.5248 41.81C41.8403 41.81 41.1839 41.5524 40.6999 41.0939C40.2159 40.6353 39.944 40.0135 39.944 39.365V9.389L33.3109 15.677C32.8284 16.1411 32.1711 16.4047 31.4835 16.4096C30.7959 16.4146 30.1344 16.1606 29.6445 15.7035C29.1546 15.2464 28.8764 14.6236 28.8712 13.9722C28.8659 13.3208 29.134 12.6941 29.6165 12.23L42.5248 0L55.4289 12.225C55.7928 12.567 56.0411 13.0039 56.1419 13.4801C56.2428 13.9563 56.1916 14.4501 55.9951 14.8986C55.7985 15.3472 55.4654 15.7301 55.0382 15.9985C54.611 16.267 54.1091 16.4088 53.5964 16.406H53.5711Z"
        fill="#545A6D"
      />
    </svg>
  );
};

export const Create = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.875 23.9167V32.4583C35.875 33.3645 35.515 34.2335 34.8743 34.8743C34.2335 35.515 33.3645 35.875 32.4583 35.875H8.54167C7.63551 35.875 6.76647 35.515 6.12572 34.8743C5.48497 34.2335 5.125 33.3645 5.125 32.4583V8.54167C5.125 7.63551 5.48497 6.76647 6.12572 6.12572C6.76647 5.48497 7.63551 5.125 8.54167 5.125H17.0833V8.54167H8.54167V32.4583H32.4583V23.9167H35.875Z"
        fill="#7211D4"
      />
      <path
        d="M35.8763 11.9583H29.043V5.125H25.6263V11.9583H18.793V15.375H25.6263V22.2083H29.043V15.375H35.8763V11.9583Z"
        fill="#7211D4"
      />
    </svg>
  );
};

export const Reports = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 11H11.9C11.6613 11 11.4324 11.0948 11.2636 11.2636C11.0948 11.4324 11 11.6613 11 11.9V21.5M11 29H20H11ZM11 29V21.5V29ZM11 29H2.9C2.66131 29 2.43239 28.9052 2.2636 28.7364C2.09482 28.5676 2 28.3387 2 28.1V22.4C2 22.1613 2.09482 21.9324 2.2636 21.7636C2.43239 21.5948 2.66131 21.5 2.9 21.5H11V29ZM20 29V11V29ZM20 29H28.1C28.3387 29 28.5676 28.9052 28.7364 28.7364C28.9052 28.5676 29 28.3387 29 28.1V2.9C29 2.66131 28.9052 2.43239 28.7364 2.2636C28.5676 2.09482 28.3387 2 28.1 2H20.9C20.6613 2 20.4324 2.09482 20.2636 2.2636C20.0948 2.43239 20 2.66131 20 2.9V11V29Z"
        stroke="#7211D4"
        stroke-width="3"
      />
    </svg>
  );
};

export const Export = () => {
  const theme = useContext(ThemeContext);
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 26 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3613 17.4148H3.11133V6.45254H6.35508C6.35508 6.45254 7.21633 5.36118 9.06758 4.01648H1.86133C1.52981 4.01648 1.21187 4.14481 0.977445 4.37323C0.743024 4.60166 0.611328 4.91147 0.611328 5.23451L0.611328 18.6328C0.611328 18.9558 0.743024 19.2657 0.977445 19.4941C1.21187 19.7225 1.52981 19.8508 1.86133 19.8508H20.6113C20.9428 19.8508 21.2608 19.7225 21.4952 19.4941C21.7296 19.2657 21.8613 18.9558 21.8613 18.6328V14.0701L19.3613 16.0737V17.4148ZM17.3126 8.94949V13.2747L25.6113 6.93975L17.3126 0.85083V4.66447C7.23633 4.66447 7.23633 14.3697 7.23633 14.3697C10.0888 9.80454 11.8438 8.94949 17.3126 8.94949Z"
        fill={theme.selectColor}
        fillOpacity="0.8"
      />
    </svg>
  );
};

export const UserEdit = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 30 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.005 13.53C12.675 13.515 12.345 13.5 12 13.5C8.37 13.5 4.98 14.505 2.085 16.23C0.765 17.01 0 18.48 0 20.025V22.5C0 23.325 0.675 24 1.5 24H13.89C12.8305 22.4887 12.1905 20.7235 12.035 18.8844C11.8796 17.0452 12.2143 15.1977 13.005 13.53Z"
        fill="#19073B"
        fill-opacity="0.8"
      />
      <path
        d="M12 12C15.3137 12 18 9.31371 18 6C18 2.68629 15.3137 0 12 0C8.68629 0 6 2.68629 6 6C6 9.31371 8.68629 12 12 12Z"
        fill="#19073B"
        fillOpacity="0.8"
      />
      <path
        d="M28.1234 18.0006C28.1234 17.6706 28.0784 17.3706 28.0334 17.0556L29.2934 15.9606C29.5634 15.7206 29.6234 15.3306 29.4434 15.0156L28.5584 13.4856C28.4736 13.335 28.3382 13.2192 28.1762 13.1588C28.0142 13.0984 27.8361 13.0973 27.6734 13.1556L26.0834 13.6956C25.6034 13.2906 25.0634 12.9756 24.4634 12.7506L24.1334 11.1156C24.098 10.9466 24.0057 10.7948 23.872 10.6856C23.7382 10.5764 23.571 10.5164 23.3984 10.5156H21.6284C21.2684 10.5156 20.9684 10.7706 20.8934 11.1156L20.5634 12.7506C19.9634 12.9756 19.4234 13.2906 18.9434 13.6956L17.3534 13.1556C17.1904 13.0997 17.0132 13.102 16.8518 13.1622C16.6904 13.2224 16.5549 13.3367 16.4684 13.4856L15.5834 15.0156C15.4034 15.3306 15.4634 15.7206 15.7334 15.9606L16.9934 17.0556C16.9484 17.3706 16.9034 17.6706 16.9034 18.0006C16.9034 18.3306 16.9484 18.6306 16.9934 18.9456L15.7334 20.0406C15.4634 20.2806 15.4034 20.6706 15.5834 20.9856L16.4684 22.5156C16.6484 22.8306 17.0234 22.9656 17.3534 22.8456L18.9434 22.3056C19.4234 22.7106 19.9634 23.0256 20.5634 23.2506L20.8934 24.8856C20.9684 25.2306 21.2684 25.4856 21.6284 25.4856H23.3984C23.7584 25.4856 24.0584 25.2306 24.1334 24.8856L24.4634 23.2506C25.0634 23.0256 25.6034 22.7106 26.0834 22.3056L27.6734 22.8456C28.0184 22.9656 28.3784 22.8156 28.5584 22.5156L29.4434 20.9856C29.6234 20.6706 29.5634 20.2806 29.2934 20.0406L28.0334 18.9456C28.0784 18.6306 28.1234 18.3306 28.1234 18.0006ZM22.4984 21.0006C20.8484 21.0006 19.4984 19.6506 19.4984 18.0006C19.4984 16.3506 20.8484 15.0006 22.4984 15.0006C24.1484 15.0006 25.4984 16.3506 25.4984 18.0006C25.4984 19.6506 24.1484 21.0006 22.4984 21.0006Z"
        fill="#19073B"
        fillOopacity="0.8"
      />
    </svg>
  );
};

export const Mailer = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25" cy="25" r="25" fill="#9076AA" fill-opacity="0.28" />
      <path
        d="M26 31H30V28L35 32.5L30 37V34H26V31ZM33 18H17C16.4696 18 15.9609 18.2107 15.5858 18.5858C15.2107 18.9609 15 19.4696 15 20V32C15 32.5304 15.2107 33.0391 15.5858 33.4142C15.9609 33.7893 16.4696 34 17 34H24.35C24.1166 33.3591 23.9982 32.6821 24 32C24.0014 30.7116 24.4175 29.4579 25.1867 28.4244C25.9559 27.3908 27.0374 26.6323 28.2711 26.2611C29.5048 25.8899 30.8253 25.9256 32.0371 26.363C33.249 26.8005 34.2878 27.6164 35 28.69V20C35 19.4696 34.7893 18.9609 34.4142 18.5858C34.0391 18.2107 33.5304 18 33 18ZM33 22L25 27L17 22V20L25 25L33 20V22Z"
        fill="#7211D4"
      />
    </svg>
  );
};

export const MenuRight = () => {
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  return (
    <svg
      onClick={() => dispatch(collapse())}
      style={{ cursor: "pointer" }}
      width="33"
      height="20"
      viewBox="0 0 43 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.1004 26.9791C42.1004 27.25 42.0463 27.5182 41.9412 27.7685C41.836 28.0188 41.6819 28.2463 41.4876 28.4378C41.2933 28.6294 41.0626 28.7814 40.8087 28.885C40.5549 28.9887 40.2828 29.0421 40.008 29.0421H23.1559C22.6007 29.0421 22.0683 28.8246 21.6757 28.4375C21.2831 28.0505 21.0625 27.5255 21.0625 26.9781C21.0625 26.4307 21.2831 25.9057 21.6757 25.5186C22.0683 25.1315 22.6007 24.9141 23.1559 24.9141H40.008C40.2829 24.9138 40.5551 24.967 40.8091 25.0706C41.063 25.1741 41.2938 25.3261 41.4882 25.5177C41.6825 25.7093 41.8366 25.9369 41.9417 26.1873C42.0468 26.4377 42.1007 26.7061 42.1004 26.9771V26.9791Z"
        fill={theme.primaryColor}
      />
      <path
        d="M42.1024 14.5181C42.1024 15.0653 41.8819 15.59 41.4895 15.9769C41.0971 16.3638 40.5649 16.5811 40.0099 16.5811H2.11689C1.56167 16.5811 1.02919 16.3637 0.636594 15.9766C0.243997 15.5895 0.0234375 15.0645 0.0234375 14.5171C0.0234375 13.9697 0.243997 13.4447 0.636594 13.0577C1.02919 12.6706 1.56167 12.4531 2.11689 12.4531H40.0079C40.2827 12.4531 40.5548 12.5065 40.8086 12.6102C41.0625 12.7138 41.2932 12.8658 41.4875 13.0574C41.6818 13.2489 41.8359 13.4764 41.9411 13.7266C42.0462 13.9769 42.1003 14.2452 42.1003 14.5161L42.1024 14.5181Z"
        fill={theme.primaryColor}
      />
      <path
        d="M40.0074 4.128C40.5627 4.128 41.0951 3.91054 41.4877 3.52347C41.8803 3.13639 42.1009 2.61141 42.1009 2.064C42.1009 1.51659 41.8803 0.991606 41.4877 0.604531C41.0951 0.217456 40.5627 0 40.0074 0H14.738C14.1828 0 13.6503 0.217456 13.2577 0.604531C12.8651 0.991606 12.6445 1.51659 12.6445 2.064C12.6445 2.61141 12.8651 3.13639 13.2577 3.52347C13.6503 3.91054 14.1828 4.128 14.738 4.128H40.0054H40.0074Z"
        fill={theme.primaryColor}
      />
    </svg>
  );
};

export const One = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="25.616"
        cy="25.408"
        rx="24.8025"
        ry="24.8142"
        fill="#7211D4"
      />
      <path
        d="M21.2014 17.2432V14.3312H27.0254V34.7432H23.8054V17.2432H21.2014Z"
        fill="white"
      />
    </svg>
  );
};

export const Two = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="25.616"
        cy="25.4988"
        rx="24.8025"
        ry="24.8143"
        fill="#7211D4"
      />
      <path
        d="M23.3854 30.494C25.1774 28.9447 26.5867 27.6753 27.6134 26.686C28.6587 25.678 29.5267 24.6327 30.2174 23.55C30.9081 22.4673 31.2534 21.3847 31.2534 20.302C31.2534 19.182 30.9827 18.3046 30.4414 17.67C29.9187 17.0353 29.0881 16.718 27.9494 16.718C26.8481 16.718 25.9894 17.0727 25.3734 17.782C24.7761 18.4727 24.4587 19.406 24.4214 20.582H21.3414C21.3974 18.454 22.0321 16.83 23.2454 15.71C24.4774 14.5713 26.0361 14.002 27.9214 14.002C29.9561 14.002 31.5427 14.562 32.6814 15.682C33.8387 16.802 34.4174 18.2953 34.4174 20.162C34.4174 21.506 34.0721 22.8033 33.3814 24.054C32.7094 25.286 31.8974 26.3967 30.9454 27.386C30.0121 28.3567 28.8174 29.486 27.3614 30.774L26.1014 31.894H34.9774V34.554H21.3694V32.23L23.3854 30.494Z"
        fill="white"
      />
    </svg>
  );
};

export const UserIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8047 16.8292C17.8047 16.9619 17.73 17.0835 17.5806 17.1941C17.4312 17.3047 17.2015 17.3959 16.8916 17.4677C16.5817 17.5396 16.2635 17.606 15.937 17.6668C15.6105 17.7276 15.1927 17.7746 14.6836 17.8077C14.1745 17.8409 13.7318 17.8658 13.3555 17.8824C12.9792 17.899 12.495 17.9128 11.9028 17.9238C11.3107 17.9349 10.8625 17.9404 10.5581 17.9404H8.05127L6.70654 17.9238L5.25391 17.8824L3.92578 17.8077L2.67236 17.6668L1.71777 17.4677L1.02881 17.1941L0.804688 16.8292C0.826823 15.8562 1.43555 14.9965 2.63086 14.2502C3.82617 13.5038 5.34245 13.009 7.17969 12.7658V12.2185C6.60417 11.9642 6.10612 11.6048 5.68555 11.1404C5.26497 10.676 4.93294 10.1315 4.68945 9.50677C4.44596 8.88206 4.26888 8.21311 4.1582 7.49994C4.04753 6.78677 3.99219 6.01555 3.99219 5.18628C3.99219 4.47864 4.13053 3.8484 4.40723 3.29555C4.68392 2.74271 5.06576 2.29767 5.55273 1.96043C6.03971 1.62319 6.59863 1.36888 7.22949 1.1975C7.86035 1.02612 8.55208 0.94043 9.30469 0.94043C10.0573 0.94043 10.749 1.02612 11.3799 1.1975C12.0107 1.36888 12.5697 1.62319 13.0566 1.96043C13.5436 2.29767 13.9255 2.74271 14.2021 3.29555C14.4788 3.8484 14.6172 4.47864 14.6172 5.18628C14.6172 9.0562 13.5547 11.4113 11.4297 12.2516V12.7658C13.2669 13.009 14.7832 13.5038 15.9785 14.2502C17.1738 14.9965 17.7826 15.8562 17.8047 16.8292Z"
      fill="#44547C"
    />
  </svg>
);

export const Logout = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8047 3.49316C5.84169 3.49316 1.80469 7.53016 1.80469 12.4932V12.4942L6.80469 8.49416V11.4942H13.8047V13.4942H6.80469V16.4942L1.80469 12.4942C1.80569 17.4572 5.84169 21.4932 10.8047 21.4932C15.7677 21.4932 19.8047 17.4562 19.8047 12.4932C19.8047 7.53016 15.7677 3.49316 10.8047 3.49316Z"
      fill="#44547C"
    />
  </svg>
);

export const ArrowDown = () => {
  return (
    <svg
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.70192 8.94293C6.10202 9.47287 6.89798 9.47287 7.29808 8.94293L12.6513 1.85255C13.1487 1.19372 12.6787 0.25 11.8532 0.25H1.14678C0.321267 0.25 -0.148711 1.19372 0.348703 1.85255L5.70192 8.94293Z"
        fill="#838383"
      />
    </svg>
  );
};
