/** @format */

import styled from "styled-components";

export const TemplateWrapper = styled.div`
  @media screen and (max-width: 1023px) {
    .svg {
      display: none;
    }
  }
`;

export const FormalWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2em;
  padding: 3em;
  height: 100vh;
  background: ${({ bg }) => bg ?? "#ebf2f8"};
  overflow: hidden;
`;

export const Button = styled.button`
  background: ${({ bg }) => bg ?? "#ebf2f8"};
  width: 150px;
  height: 45px;
  border: none;
  font-size: 18px;
  border-radius: 6px;
`;

export const TextDiv = styled.div`
  h1 {
    font-size: 64px;
    line-height: 1.2;
    max-width: 600px;
    margin-bottom: 0.5em;
  }

  p {
    font-size: 20px;
    line-height: 1.5;
    max-width: 500px;
    max-height: 500px;
    overflow-y: scroll;
  }
`;
