/** @format */

import styled from "styled-components";
import { Small } from "globals/styles";
export const Header = styled.header`
  background: ${({ theme }) => theme.navbarBg};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75em 2em;
  position: relative;

  @media screen and (max-width: 900px) {
    & {
      display: none;
    }
  }
`;

export const MenuWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: -35px;
`;

export const SearchBox = styled.input`
  height: 40px;
  width: 300px;
  outline-color: ${({ theme }) => theme.primaryColor};
  border-radius: 50px;
  border: none;
  background: ${({ theme }) => theme.searchBox};
  padding: 0 2.5em;

  &::placeholder {
    color: #545a6d;
  }
`;

export const HeaderIcons = styled.div`
  display: flex;
  gap: 1em;
`;

export const HeaderIcon = styled.span`
  width: 40px;
  height: 40px;
  display: grid;
  place-content: center;
  border-radius: 50%;
  background: ${({ theme }) => theme.iconBg};
`;

export const InfoBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8em;
  margin-top: 0.5em;
  position: relative;
`;

export const ProfileImage = styled.img`
  border-radius: 50%;
  width: 60px;
  height: 60px;
`;

export const ProfileName = styled.h3`
  font-size: ${Small};
  color: ${({ theme }) => theme.nameText};
`;

export const ImageNameDiv = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: ${({ theme }) => theme.primaryColor};
  padding: 8px 10px;
  border-radius: 50%;
  font-size: 16px;
  color: white;
`;

export const NavContainer = styled.div`
  position: absolute;
  top: 110%;
  left: 25%;
  background: #fff;
  padding: 2em;
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 200px;
  width: 400px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 3px;
  }
  z-index: 2000;

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }

  .results {
    padding: 0.5em 1em;
    cursor: pointer;

    a {
      font-weight: normal;
      width: 100%;
      color: grey;
    }

    &:hover {
      background: #f2f2f2;
    }
  }
`;
