import React from "react";
import { useTranslation } from "react-i18next";
import BreadCrumb from "components/breadcrumb";
import MainHeader from "components/header";
import PayoutHistory from "components/main/report/payout-history";

const ReportsPayoutHistory = () => {
  const {t, i18n} = useTranslation('common');
  const nav = [
    {
      url: "/affiliate/overview",
      label: t("general.dashboard"),
    },
    {
      url: "/affiliate/reports",
      label: t("report.title"),
    },
    {
      url: "/affiliate/reports/payouts-history",
      label: t("payout.history"),
      main: true,
    },
  ];
  return (
    <>
      <MainHeader title={t("payout.history")} />
      <BreadCrumb links={nav} />
      <PayoutHistory />
    </>
  );
};

export default ReportsPayoutHistory;
