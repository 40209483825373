export const campaignOtions = [
  { value: "google", label: "Google" },
  { value: "metricks", label: "Metricks" },
  { value: "instagram", label: "Instagram" },
];

export const dateOtions = [
  { value: "15", label: "Last 15 days" },
  { value: "30", label: "Last 30 days" },
  { value: "90", label: "Last 90 days" },
];
