/**
 * /* eslint-disable no-unused-vars
 *
 * @format
 */

/**
 * /* eslint-disable no-unused-vars
 *
 * @format
 */

/** @format */

import React, { useState, useContext } from "react";
import { ThemeContext } from "styled-components";
import { useSelector, shallowEqual } from "react-redux";
import { Modal } from "@mui/material";
import { HeaderTitle } from "../minicomponents";
import Menu from "components/menu";
import {
  Header,
  HeaderIcon,
  HeaderIcons,
  InfoBox,
  ProfileImage,
  ProfileName,
  SearchBox,
  ImageNameDiv,
  NavContainer,
  MenuWrapper,
} from "./styles";
import Samson from "assets/images/samson.png";
import * as FaIcons from "react-icons/fa";
import { MenuRight } from "../icons";
import NotificationModal from "../main/dashboard/notification-modal";
import { LogoutPrompt } from "../prompt";
import AffiliateData from "utils/user-data";

export const navs = [
  {
    label: "Dashboard",
    url: "/dashboard/overview",
  },
  {
    label: "Campaigns",
    url: "/dashboard/campaigns/overview",
  },
];

const MainHeader = ({ title }) => {
  const [searchVal, setSearchVal] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [open, setOpen] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const theme = useContext(ThemeContext);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenNotification = () => setOpenNotification(true);
  const handleCloseNotification = () => setOpenNotification(false);

  const [showMenu, setShowMenu] = useState(false);

  const loggedInAff = new AffiliateData();

  const { profile_data } = useSelector(
    (state) => state.aff_profile,
    shallowEqual
  );

  const handleChange = (e) => {
    e.preventDefault();
    setSearchVal(e.target.value.toLowerCase());
    setShowResult(true);
  };

  return (
    <Header>
      <div>
        <MenuRight />
        <HeaderTitle title={title} />
      </div>

      {/* <SearchBox placeholder="Search here..." onChange={handleChange} /> */}
      {/* <HeaderIcons>
        <HeaderIcon
          style={{ cursor: "pointer" }}
          onClick={handleOpenNotification}
        >
          <FaIcons.FaBell style={{ color: theme.primaryColor }} />
        </HeaderIcon>
      </HeaderIcons> */}
      <InfoBox
        onMouseEnter={() => setShowMenu(true)}
        onMouseLeave={() => setShowMenu(false)}
      >
        {/* {profile_data?.affiliate?.profile_image !== null ? (
          <ProfileImage
            src={profile_data?.affiliate?.profile_image || Samson}
            alt="Samson"
            width={40}
          />
        ) : ( */}
        <ImageNameDiv>
          {loggedInAff.get().data.affiliate.firstname[0] + " "}
          {loggedInAff.get().data.affiliate.lastname[0]}
        </ImageNameDiv>

        <ProfileName
          style={{
            cursor: "pointer",
          }}
        >
          {loggedInAff.get()?.data?.affiliate?.firstname + " "}
          {loggedInAff.get()?.data?.affiliate?.lastname}

          <FaIcons.FaAngleDown
            style={{
              marginLeft: 10,
            }}
          />
        </ProfileName>
        <MenuWrapper>
          <Menu handleOpen={handleOpen} />
        </MenuWrapper>
      </InfoBox>
      <Modal
        open={openNotification}
        onClose={handleCloseNotification}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <NotificationModal />
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <LogoutPrompt onClose={handleClose} />
      </Modal>
      <NavContainer
        style={{ display: showResult ? "" : "none" }}
        onMouseLeave={() => setShowResult(false)}
      >
        <small style={{ padding: "0 2em", color: "#6f6f6f" }}>
          Navigate anywhere
        </small>
        {navs.length > 0 ? (
          navs
            .filter((i) => i.label.toLowerCase().includes(searchVal))
            .map((result, index) => {
              return (
                <div className="results" key={index}>
                  <a href={result.url}>{result.label}</a>
                </div>
              );
            })
        ) : (
          <div className="results">
            <a href="/dashboard">No Result</a>
          </div>
        )}
      </NavContainer>
    </Header>
  );
};

export default MainHeader;
