/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Intro } from "components/containers";
import { Title, Description } from "components/typography";
import { Section, FilterActions, FilterTitle, Wrapper } from "../../styles";
import { ExportButton } from "components/buttons";
import MainPayoutsHistoryTable from "components/tables/main-payouts-history";
import PayoutsFilterer from "components/filters/payouts";
import { fetchAffiliatePayoutsHistory } from "redux/actions/payouts";
import AffiliateData from "utils/user-data";

const MainPayoutHistory = () => {
  const dispatch = useDispatch();
  const loggedInAff = new AffiliateData();

  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    dispatch(
      fetchAffiliatePayoutsHistory(
        {
          domain: loggedInAff.get().data.affiliate.domain,
          affId: loggedInAff.get().data.affiliate.id,
        },
        {}
      )
    );
  }, []);

  return (
    <Section>
      <Intro>
        <Title>Payouts History</Title>
        <Description>
          Find a summary of all commissions you have received for your
          conversions.
        </Description>
      </Intro>
      <Wrapper>
        <FilterActions>
          <FilterTitle onClick={() => null}>
            {/* Filter
            <FaIcons.FaFilter
              style={{
                background: "#dbcee9",
                padding: 7,
                fontSize: 28,
              }}
            /> */}
          </FilterTitle>

          <ExportButton caption="Export Payout" />
        </FilterActions>
        {showFilter && <PayoutsFilterer />}
        <MainPayoutsHistoryTable />
      </Wrapper>
    </Section>
  );
};

export default MainPayoutHistory;
