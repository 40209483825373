import {
  FETCH_ADV_CAMPAIGNS,
  JOIN_CAMPAIGN,
  FETCH_CAMPAIGNS,
} from "redux/types";

const INITIAL_STATE = {
  loading: false,
  loading2: false,
  campaigns: [],
  campaigns2: [],
  joinLoading: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ADV_CAMPAIGNS.PENDING: {
      return { ...state, loading: true };
    }
    case FETCH_ADV_CAMPAIGNS.SUCCESS: {
      return { ...state, loading: false, campaigns: action.payload.data };
    }
    case FETCH_ADV_CAMPAIGNS.ERROR: {
      return { ...state, loading: false };
    }
    case FETCH_CAMPAIGNS.PENDING: {
      return { ...state, loading2: true };
    }
    case FETCH_CAMPAIGNS.SUCCESS: {
      return { ...state, loading2: false, campaigns2: action.payload.data };
    }
    case FETCH_CAMPAIGNS.ERROR: {
      return { ...state, loading2: false };
    }
    case JOIN_CAMPAIGN.PENDING: {
      return { ...state, joinLoading: true };
    }
    case JOIN_CAMPAIGN.SUCCESS: {
      return { ...state, joinLoading: false };
    }
    case JOIN_CAMPAIGN.ERROR: {
      return { ...state, joinLoading: false };
    }

    default:
      return state;
  }
};

export default reducer;
