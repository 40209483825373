/** @format */

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { MenuItem, SubMenu, SubMenuItem } from "./menu.styles";
import { subMenuAnimate } from "./menu.variants";

const Menu = ({ handleOpen }) => {
  const [isHover, toggleHover] = useState(false);
  const toggleHoverMenu = () => {
    toggleHover(!isHover);
  };
  const history = useHistory();

  return (
    <MenuItem onHoverStart={toggleHoverMenu} onHoverEnd={toggleHoverMenu}>
      <p style={{ visibility: "hidden" }}>lkyjthrgefghj</p>
      <SubMenu
        initial="exit"
        animate={isHover ? "enter" : "exit"}
        variants={subMenuAnimate}
      >
        <div className="sub-menu-background" />
        <SubMenuItem
          onClick={() => history.push("/affiliate/profile-settings")}
        >
          Account Settings
        </SubMenuItem>
        <SubMenuItem onClick={handleOpen}>Logout</SubMenuItem>
      </SubMenu>
    </MenuItem>
  );
};

export default Menu;
