export const asyncType = (type) => ({
  PENDING: `${type}_PENDING`,
  ERROR: `${type}_ERROR`,
  SUCCESS: `${type}_SUCCESS`,
});

export const stepType = (type) => ({
  INCREASE: `${type}_INCREASE`,
  DECREASE: `${type}_DECREASE`,
});
