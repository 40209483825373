import styled, { css } from "styled-components";

export const DashboardSidebarMainContainer = styled.nav`
  position: fixed;
  top: 0;
  grid-area: sidebar;
  height: 100vh;
  z-index: 5;
  width: 250px;
  transition: width 250ms ease;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.sidebarBg};

  ${({ fullVisibility }) =>
    fullVisibility &&
    css`
      width: 75px;
    `}

  .dashboard__drawer--icon-container {
    position: absolute;
    top: 20%;
    right: -14px;
    background-color: ${({ theme }) => theme.sidebarBg};
    color: #fff;
    z-index: 22220;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    // border-top-right-radius: 4px;
    // border-bottom-right-radius: 4px;
    box-shadow: inset 0px 3px 15px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: transform 200ms ease-in-out;
    display: flex;
    align-items: center;
    outline: none;
    border: none;
    padding: 0;
    margin: 0;

    &:hover {
      transform: translateX(-2px);
    }

    .dashboard__drawer--icon {
      transition: transform 200ms ease-in-out;
      font-size: 5rem;

      ${({ fullVisibility }) =>
        !fullVisibility &&
        css`
          transform: rotate(180deg);
        `}
    }
  }
`;

export const DashboardSidebarContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SidebarLink = styled.div`
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.5px;

  .a-link {
    text-decoration: none;
  }

  span {
    margin-left: 15px;
  }
`;

export const SidebarLinks = styled.div`
  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const SidebarChildLink = styled.div`
  padding-left: 18px;
  margin-left: 40px;
  border-left: 1px dashed #fff;
  max-height: 600px;
  opacity: 1;
  transition: max-height 300ms ease-in-out, opacity 400ms ease-out,
    visibility 400ms ease-out;
  visibility: visible;
  overflow: hidden;
  margin-bottom: 10px;

  .active-link {
    color: #fff;
    background: #ffffff10;
  }

  ${({ visible }) =>
    visible &&
    css`
      max-height: 0;
      opacity: 0;
      visibility: hidden;
      margin-bottom: 0px;
    `}
`;

export const ParentSideLink = styled.div`
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 30px;
  padding: 10px 30px;

  ${({ fullVisibility }) =>
    fullVisibility &&
    css`
      padding: 10px 20px;
    `}

  .img-container {
    display: block;
    position: relative;
    top: 3px;
  }

  ${({ active }) =>
    active &&
    css`
      color: #fff;
      background: #ffffff10;
    `}

  .icon {
    transition: all 300ms ease-in-out;

    ${({ fullVisibility }) =>
      fullVisibility &&
      css`
        display: none;
      `}

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .rotate {
    transform: rotate(180deg);
  }

  span {
    ${({ fullVisibility }) =>
      fullVisibility &&
      css`
        display: none;
      `}

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;
export const ChildSideLink = styled.div`
  color: #fff;
  padding: 4px 5px;
  font-weight: 400;
  font-size: 14.5px;
  cursor: pointer;
  margin-left: 5px;

  ${({ fullVisibility }) =>
    fullVisibility &&
    css`
      display: none;
    `}

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const BrandHolder = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #fff;
  margin: 20px 0 50px 0;
  height: 40px;
  width: 100%;
  cursor: pointer;
  padding: 0 30px;

  ${({ fullVisibility }) =>
    fullVisibility &&
    css`
      padding: 0 10px;
    `}

  span {
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: capitalize;

    ${({ fullVisibility }) =>
      fullVisibility &&
      css`
        display: none;
      `}

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;
