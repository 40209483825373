import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MainTable, TableWrapper } from "../styles";
import "../table.scss";
import { Skeleton } from "@mui/material";
import { formatDate } from "utils/date-formatters";

import NoData from "components/nodata";

const CommissionsTable = ({ getData }) => {
  const {t, i18n} = useTranslation('common');
  const { loading, commissions } = useSelector(
    (state) => state.aff_commissions
  );

  useEffect(() => {
    getData([
      [
        "CAMPAIGN NAME",
        "CREATED AT",
        "ORDER ID",
        "COMMISSION AMOUNT",
        "CONVERSION AMOUNT",
        "STATUS",
      ],

      [
        commissions && commissions.length > 0
          ? commissions.map((comm) => comm.campaign_name)
          : "No data",
        commissions && commissions.length > 0
          ? commissions.map((comm) => comm.createdAt)
          : "No data",
        commissions && commissions.length > 0
          ? commissions.map((comm) => comm.orderId)
          : "No data",
        commissions && commissions.length > 0
          ? commissions.map((comm) => comm.commissionAmount)
          : "No data",
        commissions && commissions.length > 0
          ? commissions.map((comm) => comm.conversionAmount)
          : "No data",
        commissions && commissions.length > 0
          ? commissions.map((comm) => comm.status)
          : "No data",
      ],
    ]);
  }, [commissions]);

  const renderCommissionsData = () => {
    return loading ? (
      <>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
      </>
    ) : (
      commissions &&
        commissions.map((commission) => {
          const {
            id,
            campaign_name,
            createdAt,
            orderId,
            commissionAmount,
            conversionAmount,
            status,
          } = commission;

          return (
            <tr key={id}>
              <td>
                <label class="customContainer">
                  <input type="checkbox" />
                  <span class="customCheckmark"></span>
                </label>
              </td>
              <td>{campaign_name}</td>
              <td>{formatDate(new Date(createdAt), "dd, mm, yyyy")}</td>
              <td>{orderId}</td>
              <td
                style={{
                  color:
                    status === "approved"
                      ? "#5cbb5c"
                      : status === "pending"
                      ? "#ddb13f"
                      : "#ed5565",
                }}
              >
                {status}
              </td>
              <td>{conversionAmount}</td>
              <td>{commissionAmount}</td>
            </tr>
          );
        })
    );
  };

  const renderCommissionsHeader = () => {
    let header = [
      "",
      t("general.campaign"),
      t("general.date"),
      t("commission.orderId"),
      t("general.status"),
      t("commission.conversionAmount"),
      t("commission.commissionAmount"),
    ];
    return header.map((key, index) => {
      return <th key={index}>{key}</th>;
    });
  };

  return (
    <>
      {commissions && commissions.length === 0 && !loading ? (
        <NoData />
      ) : (
        <TableWrapper>
          <MainTable>
            <tbody>
              <tr
                style={{
                  width: "100%",
                }}
              >
                {renderCommissionsHeader()}
              </tr>
              {renderCommissionsData()}
            </tbody>
          </MainTable>
        </TableWrapper>
      )}
    </>
  );
};

export default CommissionsTable;
