import React from "react";
import noData from "assets/icons/nodata.png";
import { Board, OuterDiv } from "./styles";

const NoData = () => {
  return (
    <OuterDiv>
      <Board>
        <img src={noData} alt="Data Unavailable" height={300} />
      </Board>
    </OuterDiv>
  );
};

export default NoData;
