import React from "react";
// import { useSelector } from "react-redux";
// import Aside from "components/aside";
import { DashboardSidebar } from "components/sidebar";
import { useToggleSidebar } from "hooks/useToggleSidebar";
import { DashBoard, Stack } from "./styles";

const AffDashBoardLayout = ({ children }) => {
  const { toggle } = useToggleSidebar();

  return (
    <DashBoard>
      <DashboardSidebar />
      <main className={toggle ? "collapsed" : "full"}>{children}</main>
    </DashBoard>
  );
};

export default AffDashBoardLayout;
