import styled from "styled-components";
import { MainColor } from "globals/styles";

export const Intro = styled.div`
  display: block;
  padding: 1em 0;
`;

export const Reports = styled.div`
  background: ${({ theme }) => theme.sectionBg};
  border-radius: 10px;
  padding: 2em;
  border: ${({ theme }) => theme.bordered && theme.mainBorder};
`;

export const ReportsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4em;
  margin: 3em 0;
`;

export const Stats = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5em;
  padding: 1em;
  background: ${({ theme }) => theme.cardBg};
  box-shadow: ${({ theme }) => theme.cardShadow};
  border-radius: 10px;
`;

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ControlGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5em;
`;

export const BoxContent = styled.div`
  background: ${({ theme }) => theme.sectionBg};
  padding: 2em;
  color: ${({ theme }) => theme.mainText};
`;

export const Box = styled.div`
  background: transparent;
  margin-top: 2.5em;
`;

export const Menu = styled.ul`
  position: absolute;
  width: 250px;
  right: 3%;
  top: 110%;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(180, 175, 186, 0.25);
  border-radius: 10px;
  list-style: none;
  padding: 1em 2em;

  & li {
    cursor: pointer;
  }

  & li a {
    display: flex;
    padding: 0.5em 0;
    gap: 1em;
    text-decoration: none;
  }
`;

export const NotificationWrapper = styled.div`
  position: absolute;
  background: transparent;
  top: 100px;
  right: 15%;
  width: 450px;
  border-radius: 15px;
  z-index: 1000;
  background: #fff;
  padding-bottom: 1em;
  box-shadow: 1px 2px 5px rgba(86, 83, 83, 0.25);
`;

export const NotificationItem = styled.div`
  display: flex;
  padding: 1em 1.5em;
  gap: 2em;
  background: #f1edf6;
  border-top: 1px solid #c1c0ff;
`;
