import React from "react";
import BreadCrumb from "components/breadcrumb";
import MainHeader from "components/header";
import Campaign from "components/main/campaign";
import { useTranslation } from "react-i18next";

const Campaigns = () => {
  const {t, i18n} = useTranslation('common');
  const nav = [
    {
      url: "/affiliate/overview",
      label: `${t("campaign.dashboard")}`,
    },
    {
      url: "/affiliate/campaigns",
      label: `${t("campaign.title")}`,
      main: true,
    },
  ];
  return (
    <>
      <MainHeader title={t("campaign.title")} />
      <BreadCrumb links={nav} />
      <Campaign />
    </>
  );
};

export default Campaigns;
