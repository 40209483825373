import { SIGN_IN_AFF } from "redux/types";

const INITIAL_STATE = {
  affiliate: {},
  loading: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_IN_AFF.PENDING: {
      return { ...state, loading: true };
    }
    case SIGN_IN_AFF.SUCCESS: {
      return { ...state, loading: false, affiliate: action.payload.data };
    }
    case SIGN_IN_AFF.ERROR: {
      return { ...state, loading: false, affiliate: {} };
    }
    default:
      return state;
  }
};

export default reducer;
