/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import Select from "react-select";
import {
  Div,
  ErrorP,
  InputFieldWrapperDiv,
  Label,
  SelectInputComponentStyle,
} from "./select.styles";

const MainSelect = ({
  label,
  error,
  placeholder,
  required,
  hideArrow = false,
  ...rest
}) => {
  const themex = useContext(ThemeContext);
  return (
    <Div fullWidth={true}>
      <InputFieldWrapperDiv>
        <SelectInputComponentStyle error={!!error} className="other-selct">
          {label && (
            <Label className="select-label">{`${label}${
              required ? "*" : ""
            }`}</Label>
          )}
          <Select
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 100 }) }}
            classNamePrefix="react-select"
            className="red"
            placeholder={placeholder}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                // primary25: themex.selectColor,
                primary: themex.primaryColor,
              },
            })}
            menuPortalTarget={
              typeof window !== "undefined" ? window.document.body : null
            }
            components={{
              IndicatorSeparator: () => null,
            }}
            {...rest}
          />
          {error && (
            <ErrorP
              easyFlow={true}
              initial="pageInitial"
              animate="pageAnimate"
              exit="pageExit"
              variants={{
                pageInitial: {
                  opacity: 1,
                  x: 0,
                  y: -5,
                },
                pageAnimate: {
                  opacity: 1,
                  x: 0,
                  y: 0,
                },
                pageExit: {
                  opacity: 0,
                },
              }}
            >
              {error}
            </ErrorP>
          )}
        </SelectInputComponentStyle>
      </InputFieldWrapperDiv>
    </Div>
  );
};

MainSelect.defaultProps = {
  label: "Select...",
};

export default MainSelect;
