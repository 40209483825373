import axios from "axios";
import { toast } from "react-toastify";
import AffiliateData from "utils/user-data";
import { SIGN_IN_AFF } from "redux/types";
import AffiliateAuthSession from "utils/session-storage";
import { affSignin } from "redux/apiRoutes";

const session = new AffiliateAuthSession();
const date = new Date();

export const signInAff = (data, domain) => (dispatch) => {
  dispatch({ type: SIGN_IN_AFF.PENDING, payload: null });
  const campaignUrl = data.campaignUrl;
  const history = data.history;
  delete data.history;
  delete data.campaignUrl;
  axios
    .post(
      `${affSignin}${domain}?campaignUrl=${campaignUrl ? campaignUrl : ""}`,
      data
    )
    .then((response) => {
      dispatch({ type: SIGN_IN_AFF.SUCCESS, payload: response.data });
      const affiliateData = new AffiliateData();
      affiliateData.set(response.data);
      const affToken = affiliateData.get().data.token;
      session.set({
        token: affToken,
        expiredAt: new Date(date).setHours(date.getHours() + 20),
      });
      toast.success("Login Successful");
      setTimeout(() => {
        history.push("/affiliate/overview");
      }, 1000);
    })
    .catch((error) => {
      console.log(error);
      toast.error("Incorrect Login Details");
      dispatch({ type: SIGN_IN_AFF.ERROR, payload: null });
    });
};
