/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ExportButton } from "components/buttons";
import { Intro } from "components/containers";
import CommissionsFilterer from "components/filters/commissions";
import RecurringCommissionsTable from "components/tables/recurring-commissions";
import { Description, Title } from "components/typography";
import { FilterActions, FilterTitle, Section, Wrapper } from "../../styles";
import { fetchAffRecurringCommission } from "redux/actions/commissions";
import AffiliateData from "utils/user-data";
import { CSVLink } from "react-csv";

const Recurring = () => {
  const [showFilter, setShowFilter] = useState(false);

  const dispatch = useDispatch();
  const loggedInAff = new AffiliateData();

  useEffect(() => {
    dispatch(
      fetchAffRecurringCommission(
        {
          domain: loggedInAff.get().data.affiliate.domain,
          affId: loggedInAff.get().data.affiliate.id,
        },
        {}
      )
    );
  }, []);

  const [csvData, setCsvData] = useState([]);
  const getData = (data) => {
    setCsvData(data);
  };

  return (
    <Section>
      <Intro>
        <Title>Recurring Commissions</Title>
        <Description>Find useful details of all your commissions.</Description>
      </Intro>
      <Wrapper>
        <FilterActions>
          <FilterTitle onClick={() => null}>
            {/* Filter
            <FaIcons.FaFilter
              style={{
                background: "#dbcee9",
                padding: 7,
                fontSize: 28,
              }}
            /> */}
          </FilterTitle>

          <CSVLink
            data={csvData}
            filename={"Recurring commissions report.csv"}
            style={{ textDecoration: "none" }}
          >
            <ExportButton caption="Export Report" />
          </CSVLink>
        </FilterActions>
        {showFilter && <CommissionsFilterer />}
        <RecurringCommissionsTable getData={getData} />
      </Wrapper>
    </Section>
  );
};

export default Recurring;
