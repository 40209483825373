import React from "react";
import { useTranslation } from "react-i18next";
import BreadCrumb from "components/breadcrumb";
import MainHeader from "components/header";
import MainPayoutHistory from "components/main/payout/payout-history";

const MainPayoutsHistory = () => {
  const {t, i18n} = useTranslation('common');
  const nav = [
    {
      url: "/affiliate/overview",
      label: t("general.dashboard"),
    },
    {
      url: "/affiliate/payouts",
      label: t("payout.title"),
    },
    {
      url: "/affiliate/payouts/payouts-history",
      label: t("payout.history"),
      main: true,
    },
  ];
  return (
    <>
      <MainHeader title={t("payout.history")} />
      <BreadCrumb links={nav} />
      <MainPayoutHistory />
    </>
  );
};

export default MainPayoutsHistory;
