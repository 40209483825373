/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { BrandImage } from "components/brand-image";
import { useToggleSidebar } from "hooks/useToggleSidebar";
import { MdKeyboardArrowRight } from "react-icons/md";
import { NavLink, useLocation } from "react-router-dom";
import { useCurrentSideLinks } from "./links";
import {
  DashboardSidebarContainer,
  SidebarLink,
  SidebarLinks,
  SidebarChildLink,
  ChildSideLink,
  ParentSideLink,
  BrandHolder,
  DashboardSidebarMainContainer,
} from "./sidebar.styles";

const isClient = typeof window !== "undefined";

const DashboardSidebar = (props) => {
  const { toggle, setToggleSidebar } = useToggleSidebar();

  const location = useLocation();
  console.log(location);

  function handleFullVisibility() {
    setToggleSidebar();
  }

  const { links: Links } = useCurrentSideLinks();

  return (
    <DashboardSidebarMainContainer fullVisibility={toggle}>
      <button
        className="dashboard__drawer--icon-container"
        onClick={handleFullVisibility}
      >
        <MdKeyboardArrowRight className="dashboard__drawer--icon" />
      </button>
      <DashboardSidebarContainer fullVisibility={toggle}>
        <SidebarLinks fullVisibility={toggle}>
          <BrandHolder fullVisibility={toggle}>
            <NavLink to="/">
              <BrandImage />
            </NavLink>
          </BrandHolder>
        </SidebarLinks>
        {Links.map((link) => {
          return (
            <LinksComponent
              key={link.label}
              link={link}
              fullVisibility={toggle}
            />
          );
        })}
      </DashboardSidebarContainer>
    </DashboardSidebarMainContainer>
  );
};

export const LinksComponent = ({ link, fullVisibility }) => {
  const [visibility, setVisibility] = useState(false);

  return (
    <SidebarLink key={link.label}>
      <NavLink
        to={link.url}
        className="a-link"
        onClick={() => setVisibility(!visibility)}
        onKeyPress={() => setVisibility(!visibility)}
      >
        <ParentSideLink
          fullVisibility={fullVisibility}
          active={location.pathname === link.url && isClient ? true : false}
        >
          <div className="img-container">
            <img src={link.icon} alt={link?.label} />
          </div>
          <span>{link.label}</span>
          {link.children && link.children.length > 0 && (
            <KeyboardArrowDownIcon
              role="button"
              style={{
                fontSize: "20px",
                height: "100%",
                marginLeft: "auto",
                justifySelf: "flex-end",
                position: "relative",
                top: "3px",
                border: "1px solid",
                borderRadius: "10px",
              }}
              className={`icon ${visibility ? "rotate" : ""}`}
            />
          )}
        </ParentSideLink>
      </NavLink>
      <SidebarChildLink visible={!visibility || fullVisibility}>
        {link.children &&
          link.children.map((child) => (
            <NavLink to={child.url} key={child.label}>
              <ChildSideLink
                className={`${
                  location.pathname === child.url ? "active-link" : null
                }`}
                fullVisibility={fullVisibility}
              >
                {child.label}
              </ChildSideLink>
            </NavLink>
          ))}
      </SidebarChildLink>
    </SidebarLink>
  );
};

export { DashboardSidebar };
