import styled, { css } from "styled-components";

export const Component = styled.div`
  margin: 10px 0 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  position: relative;
`;
export const Input = styled.input`
  appearance: none;
  padding: 0 25px;
  max-width: 500px;
  height: 60px;
  border-radius: 20px;
  background: #fdfdfd;
  &:hover,
  &:focus {
    outline-color: #7211d4;
  }
  ${({ outline }) =>
    outline &&
    css`
        outline-color: #7211d4;
    `}

`;
export const Label = styled.label`
  letter-spacing: 0.66px;
  padding: 16px 0;
  color: #213e71;
  font-size: 18px;
  opacity: 1;
`;

export const InputIcon = styled.span`
  position: absolute;
  left: 80%;
  bottom: 17.5px;
  height: 25px;
  width: 25px;

  svg {
    ${({ outline }) =>
      outline &&
      css`
        fill: white;
      `}
  }
`;
export const Checkbox = styled.div`
  margin-right: 15px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: #fff;
`;
