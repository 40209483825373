/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import "./styles.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ArrowDown } from "../icons";
import { fetchCampaigns } from "redux/actions/campaigns";
import { formatFilterDate } from "utils/date-formatters";

const Filterer = ({ hide }) => {
  const dispatch = useDispatch();
  const [day, setDay] = useState(null);
  const [status, setStatus] = useState(null);
  const [country, setCountry] = useState(null);
  const [limit, setLimit] = useState(null);
  const [affID, setAffId] = useState(null);
  const [showDayFilter, setShowDayFilter] = useState(false);
  const [showCampaignFilter, setShowCampaignFilter] = useState(false);
  const [campaign, setCampaign] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showStatusFilter, setShowStatusFilter] = useState(false);
  const [isNumberDay, setIsNumberDay] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const handleDatePicked = (date, start) => {
    console.log(date);
    start ? setStartDate(date) : setEndDate(date);
  };
  const handleDaySelect = (days) => {
    setDay(days);
    console.log(parseInt(days));
    const event = new Date();
    event.setDate(new Date().getDate() - parseInt(days));
    console.log(event);
    setStartDate(event);
  };

  const applyFilter = useCallback(() => {
    dispatch(
      fetchCampaigns(
        {
          domain: localStorage.getItem("portalUrl"),
        },
        {
          status,
        }
      )
    );
  }, [dispatch, status]);
  return (
    <div
      className="Conversions
  filterer"
    >
      <div className="col widerCol">
        <div className="box">
          <div
            className="selectBox"
            onClick={() => setShowDayFilter(!showDayFilter)}
          >
            {day ? (isNumberDay ? `Last ${day} day(s)` : day) : "Select Date"}
            <ArrowDown />
          </div>
          {showDayFilter && (
            <>
              <div className="check">
                <div>
                  <label class="container">
                    <input
                      type="radio"
                      name="day"
                      value={1}
                      onChange={(e) => handleDaySelect(e.target.value)}
                    />
                    last day
                    <span class="radiobutt"></span>
                  </label>
                </div>
                <div>
                  <label class="container">
                    <input
                      type="radio"
                      name="day"
                      value={15}
                      onChange={(e) => handleDaySelect(e.target.value)}
                    />
                    last 15 days
                    <span class="radiobutt"></span>
                  </label>
                </div>
                <div>
                  <label class="container">
                    <input
                      type="radio"
                      name="day"
                      value={30}
                      onChange={(e) => handleDaySelect(e.target.value)}
                    />
                    last 30 days
                    <span class="radiobutt"></span>
                  </label>
                </div>
                <div>
                  <label class="container">
                    <input
                      type="radio"
                      name="day"
                      value={90}
                      onChange={(e) => handleDaySelect(e.target.value)}
                    />
                    last quarter
                    <span class="radiobutt"></span>
                  </label>
                </div>
                <div>
                  <label class="container">
                    last year
                    <input
                      type="radio"
                      name="day"
                      value={365}
                      onChange={(e) => handleDaySelect(e.target.value)}
                    />
                    <span class="radiobutt"></span>
                  </label>
                </div>
                <hr />
                <button
                  onClick={() => {
                    setShowDatePicker(!showDatePicker);
                    setIsNumberDay(false);
                    setDay("Custom Date");
                  }}
                >
                  Custom Date
                </button>
              </div>
              {showDatePicker && (
                <div className="customDates">
                  <div>
                    <small style={{ margin: "0px 10px" }}>Start Date</small>

                    <DatePicker
                      className="dateInput"
                      selected={startDate}
                      onChange={(date) => handleDatePicked(date, true)}
                    />
                  </div>

                  <div>
                    <small style={{ margin: "0px 10px" }}>End Date</small>

                    <DatePicker
                      className="dateInput"
                      selected={startDate}
                      onChange={(date) => handleDatePicked(date, false)}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <div className="box">
          <div
            className="selectBox"
            onClick={() => setShowStatusFilter(!showStatusFilter)}
          >
            {status ? status : "Status"}
            <ArrowDown />
          </div>
          {showStatusFilter && (
            <div className="check">
              <div>
                <label class="container">
                  <input
                    type="radio"
                    name="status"
                    value="open"
                    onChange={(e) => setStatus(e.target.value)}
                  />
                  Open
                  <span class="radiobutt"></span>
                </label>
              </div>

              <div>
                <label className="container">
                  <input
                    type="radio"
                    name="status"
                    value="Pending"
                    onChange={(e) => setStatus(e.target.value)}
                  />
                  Pending
                  <span class="radiobutt"></span>
                </label>
              </div>

              <div>
                <label className="container">
                  <input
                    type="radio"
                    name="status"
                    value="closed"
                    onChange={(e) => setStatus(e.target.value)}
                  />
                  Closed
                  <span class="radiobutt"></span>
                </label>
              </div>
            </div>
          )}
        </div>
        <div className="box">
          <div
            className="selectBox"
            onClick={() => setShowCampaignFilter(!showCampaignFilter)}
          >
            {campaign ? campaign : "Campaign"}
            <ArrowDown />
          </div>
          {showCampaignFilter && (
            <div className="check">
              <div>
                <label className="container">
                  <input
                    type="checkbox"
                    name="campaign"
                    value="All"
                    onChange={(e) =>
                      e.target.checked
                        ? setCampaign(e.target.value)
                        : setCampaign(null)
                    }
                  />
                  All
                  <span class="radiobutt"></span>
                </label>
              </div>
              <hr />
              {campaign !== "All" && (
                <input
                  type="text"
                  className="subInputBox"
                  placeholder="Search Campaign"
                  onChange={(e) => setCampaign(e.target.value)}
                  style={{
                    border: "none",
                    outline: "none",
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>

      <hr style={{ border: "solid 0.5px #a7a7a7", opacity: 0.3 }} />

      <div className="btns">
        <button className="cancel" onClick={hide}>
          Cancel
        </button>
        <button className="apply" onClick={applyFilter}>
          Apply Filter
        </button>
      </div>
    </div>
  );
};

export default Filterer;

// () => {
// console.log({
// startDate: formatFilterDate(startDate, "yyyy-mm-dd"),
// limit,
// status,
// country,
// affID,
// endDate: formatFilterDate(endDate, "yyyy-mm-dd"),
// });
// }}
