/** @format */

import { motion } from "framer-motion";
import styled from "styled-components";

export const MenuItem = styled(motion.div)`
  width: 200px;
  perspective: 2000px;
`;

export const SubMenu = styled(motion.div)`
  position: absolute;
  top: 30px;
  padding: 15px;
  background-color: #fff;
  border-radius: 6px;
  transform-origin: 50% -30px;

  .sub-menu-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform-origin: 0 0;
    z-index: -1000;
    box-shadow: 0px 5px 10px #f1f2fa;
  }
`;

export const SubMenuItem = styled(motion.div)`
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  padding: 0.5em;
  &:hover {
    background-color: #f2f2f2;
  }
`;
