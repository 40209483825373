import { motion } from "framer-motion";
import styled from "styled-components";

export const Div = styled.div`
  position: relative;
  display: inline-block;
`;

export const Label = styled.label`
  pointer-events: none;
  color: ${({ theme }) => theme.selectColor};
  white-space: nowrap;
  margin-bottom: 0.5rem;
  font-size: 14px;
  display: block;
  font-weight: 400;
  color: #000;

  ${Div}:focus-within & {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

export const InputFieldWrapperDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  ::before,
  ::after {
    content: "";
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  ::before {
    /* border-left: linear-gradient(270deg, #00d2ff -8.34%, #7e51ff 50.93%, #fa4a84 106.85%); */
    border-left: 1px solid grey;
  }
`;

export const ErrorP = styled(motion.p)`
  margin: 0.1rem 0 0 0;
  color: red;
  font-size: 14px;
  letter-spacing: -0.01rem;
  font-weight: 400;
`;

export const SelectInputComponentStyle = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  .react-select__value-container
    .react-select__value-container--is-multi
    .css-g1d714-ValueContainer {
    &:focus-visible {
      outline: none;
      border: 1px solid transparent;
    }
  }

  .react-select__value-container {
    width: 100%;
    min-height: 35px;
  }

  .react-select__control {
    background: transparent;
    border: 1px solid ${({ theme }) => theme.selectColor};
    height: 35px;
    width: 100%;
    max-width: 100%;
    min-width: 150px;
    font-size: 16px;
    color: ${({ theme }) => theme.selectColor};
    outline: none;
  }

  .react-select__placeholder {
    font-weight: 400;
    color: ${({ theme }) => theme.selectColor};
  }

  .react-select__indicator-seperator {
    display: none !important;
  }

  .react-select__single-value {
    font-weight: 400;
    color: ${({ theme }) => theme.selectColor};
  }

  .react-select__multi-value__label {
    background: ${({ theme }) => theme.primaryColor};
    color: ${({ theme }) => theme.primaryColor};
  }

  .react-select__multi-value {
    background: ${({ theme }) => theme.primaryColor};
    color: ${({ theme }) => theme.primaryColor};
    border: 1px solid rgba(0, 126, 255, 0.24);
    margin: 5px 8px;

    &:not(:first-child) {
      margin-left: 8px;
    }
  }
`;

export const SelectInputWrapper = styled.div``;
