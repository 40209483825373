/** @format */

import { FETCH_REPORTS, FETCH_DETAILS } from "redux/types";

const INITIAL_STATE = {
  loading: false,
  reports: null,
  affDetails: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_REPORTS.PENDING: {
      return { ...state, loading: true };
    }
    case FETCH_REPORTS.SUCCESS: {
      return { ...state, loading: false, reports: action.payload.data };
    }
    case FETCH_REPORTS.ERROR: {
      return { ...state, loading: false };
    }
    case FETCH_DETAILS.PENDING: {
      return { ...state, loading: true };
    }
    case FETCH_DETAILS.SUCCESS: {
      return { ...state, loading: false, affDetails: action.payload.data };
    }
    case FETCH_DETAILS.ERROR: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
};

export default reducer;
