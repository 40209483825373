import { FETCH_AFF_COMMISSIONS, FETCH_AFF_REC_COMMISSIONS } from "redux/types";

const INITIAL_STATE = {
  loading: false,
  recurringLoading: false,
  commissions: [],
  recurring_commissions: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_AFF_COMMISSIONS.PENDING: {
      return { ...state, loading: true };
    }
    case FETCH_AFF_COMMISSIONS.SUCCESS: {
      return { ...state, loading: false, commissions: action.payload.data };
    }
    case FETCH_AFF_COMMISSIONS.ERROR: {
      return { ...state, loading: false };
    }
    case FETCH_AFF_REC_COMMISSIONS.PENDING: {
      return { ...state, recurringLoading: true };
    }
    case FETCH_AFF_REC_COMMISSIONS.SUCCESS: {
      return {
        ...state,
        recurringLoading: false,
        recurring_commissions: action.payload.data,
      };
    }
    case FETCH_AFF_REC_COMMISSIONS.ERROR: {
      return { ...state, recurringLoading: false };
    }

    default:
      return state;
  }
};

export default reducer;
