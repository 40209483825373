/**
 * /* eslint-disable jsx-a11y/alt-text
 *
 * @format
 */

/**
 * /* eslint-disable jsx-a11y/alt-text
 *
 * @format
 */

import React, { useState, useEffect } from "react";
import { Skeleton } from "@mui/material";
import { toast } from "react-toastify";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchAffDetails } from "redux/actions/dashboard";
import { Intro } from "components/containers";
import { useDropzone } from "react-dropzone";
import { Title, Description } from "components/typography";
import { Section, Flex } from "../styles";
import { PrimaryButton } from "components/buttons/button-styles";
import AffiliateData from "utils/user-data";
import {
  updateAffiliateProfile,
  deleteProfileImage,
  uploadProfileImage,
} from "redux/actions/profile";
import {
  FormBox,
  FormGrid,
  FormGroup,
  Input,
  Label,
} from "./pf-settings.styles";

const ProfileSettings = () => {
  const {t, i18n} = useTranslation('common');
  const dispatch = useDispatch();

  const { loading, profile_data } = useSelector(
    (state) => state.aff_profile,
    shallowEqual
  );

  const { affDetails } = useSelector(
    (state) => state.aff_reports,
    shallowEqual
  );

  const [confirmPassword, setConfirmPassword] = useState("");
  const [fileToUpload, setFileToUpload] = useState("");
  const loggedInAff = new AffiliateData();
  const [src, setSrc] = useState(null);
  const [form, setForm] = useState({});

  useEffect(() => {
    dispatch(
      fetchAffDetails({
        domain: loggedInAff.get().data.affiliate.domain,
        affId: loggedInAff.get().data.affiliate.id,
      })
    );
    setForm({
      affId: affDetails?.affiliate?.id,
      domain: affDetails?.affiliate?.domain,
      firstname: affDetails?.affiliate?.firstname,
      lastname: affDetails?.affiliate?.lastname,
      email: affDetails?.affiliate?.email,
      currentPassword: "",
      newPassword: "",
      country: affDetails?.affiliate?.country,
      postal_code: affDetails?.affiliate?.postal_code,
      region: "",
    });
  }, []);

  function handleChange(evt) {
    const value = evt.target.value;
    setForm({
      ...form,
      [evt.target.name]: value,
    });
  }

  function onSubmit() {
    if (form.newPassword !== confirmPassword) {
      toast.error(t("profile.passwordError"));
      return;
    }
    dispatch(updateAffiliateProfile(form));
  }

  function handleDelete() {
    setSrc(null);
    dispatch(deleteProfileImage());
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    maxFiles: 1,
    maxSize: 2000000,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length === 0) {
        toast.error("Not Accepted");
      } else {
        const SRC = URL.createObjectURL(acceptedFiles[0]);
        setSrc(SRC);
        console.log(acceptedFiles[0]);
        setFileToUpload(acceptedFiles[0]);
        dispatch(uploadProfileImage(fileToUpload));
      }
    },
  });

  return (
    <Section>
      <Intro>
        <Title>{t("profile.settings")}</Title>
        <Description>{t("profile.description")}</Description>
      </Intro>
      <FormBox
        style={{
          height: "auto",
        }}
      >
        <Flex>
          <img
            src={src || affDetails?.affiliate?.profile_image}
            style={{ background: "#f3f3f3" }}
            height={60}
          />

          <Label style={{ marginLeft: "2em" }}>
            {loading ? (
              <span style={{ paddingLeft: ".8em" }}>
                <Skeleton variant="text" width={250} />
              </span>
            ) : (
              <span style={{ paddingLeft: ".8em" }}>
                {`${profile_data?.affiliate?.firstname} ${profile_data?.affiliate?.lastname}`}
              </span>
            )}
            <br />
            <Flex style={{ gap: "1em" }}>
              <p
                style={{
                  cursor: "pointer",
                  color: "#ed5565",
                  fontWeight: 700,
                }}
                onClick={handleDelete}
              >
                {t("profile.delete")}
              </p>
            </Flex>
          </Label>
        </Flex>
        <p
          {...getRootProps()}
          style={{
            position: "absolute",
            top: "90px",
            cursor: "pointer",
            left: 230,
            color: "#7211d4",
            fontWeight: 600,
          }}
        >
          <input {...getInputProps()} />
          {t("profile.uploadPicture")}
        </p>
        <FormGrid>
          <FormGroup>
            <Label>{t("profile.fName")}</Label>
            <Input
              type="text"
              defaultValue={form.firstname}
              name="firstname"
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>{t("profile.pCode")}</Label>
            <Input
              type="number"
              placeholder="e.g 100001"
              defaultValue={form.postal_code}
              name="postal_code"
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>{t("profile.lName")}</Label>
            <Input
              type="text"
              defaultValue={form.lastname}
              name="lastname"
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>{t("profile.country")}</Label>
            <Input
              type="text"
              defaultValue={form.country}
              name="country"
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>{t("profile.email")}</Label>
            <Input
              type="email"
              defaultValue={form.email}
              name="email"
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>{t("profile.cPassword")}</Label>
            <Input
              type="password"
              defaultValue={form.currentPassword}
              name="currentPassword"
              onChange={handleChange}
            />
          </FormGroup>
          {/* <FormGroup>
            <Label>Phone Number</Label>
            <Input
              type="number"
              defaultValue={profile_data?.affiliate?.phone_number}
            />
          </FormGroup> */}
          <FormGroup>
            <Label>{t("profile.nPassword")}</Label>
            <Input
              type="password"
              defaultValue={form.newPassword}
              name="newPassword"
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>{t("profile.city")}</Label>
            <Input
              type="text"
              defaultValue={form.region}
              name="region"
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>{t("profile.confirmPassword")}</Label>
            <Input
              type="password"
              defaultValue={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </FormGroup>
          {/* <FormGroup>
            <Label>State</Label>
            <Input />
          </FormGroup> */}
        </FormGrid>
        <PrimaryButton
          style={{
            marginTop: "14em",
            cursor: loading ? "not-allowed" : "pointer",
          }}
          disabled={loading}
          onClick={onSubmit}
        >
          {t("profile.cta")}
        </PrimaryButton>
      </FormBox>
    </Section>
  );
};

export default ProfileSettings;
