import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Input from "../component";
import { Box, FormGroup, BoxTitle, Small, Button, Main } from "../styles";
import { forgotPasswordRequest } from "redux/actions/auth/auth";

const ForgotPassword = () => {
  const {t, i18n} = useTranslation('common');
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();
  //   const signin = useSelector(state => state.signin);
  const history = useHistory();

  const data = {
    email: email,
    domain: "trace",
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(forgotPasswordRequest(data, history));
  };

  return (
    <Main style={{ height: "100vh" }}>
      <Box>
        <BoxTitle>{t("auth.forgotTitle")}</BoxTitle>
        <Small>
          {t("auth.forgotDesc")}
        </Small>
        <FormGroup>
          <Input
            type="email"
            label={t("auth.email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormGroup>

        <FormGroup>
          <Button
            disabled={!email ? true : false}
            style={{
              cursor: !email ? "not-allowed" : "pointer",
            }}
            onClick={onSubmit}
          >
            {t("auth.forgotCta")}
          </Button>
        </FormGroup>
        <div>
          <Small style={{ width: "100%" }}>
            {t("auth.forgotHelp")}{" "}
            <a href="mailto:support@metricks.io">support@metricks.io</a>
          </Small>
        </div>
      </Box>
    </Main>
  );
};

export default ForgotPassword;
