import React from "react";
import { Link } from "react-router-dom";
import { BreadCrumbWrapper } from "./styles";
import "./styles.scss";

const BreadCrumb = ({ links }) => {
  return (
    <BreadCrumbWrapper className="breadcrumb">
      {links.map((link, index) => (
        <li key={index} className={"main" in link ? "fade" : ""}>
          <Link to={link.url}>{link.label}</Link>
        </li>
      ))}
    </BreadCrumbWrapper>
  );
};

export default BreadCrumb;
