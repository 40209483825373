import { UPDATE_PROFILE, GET_PROFILE, PROFILE_ACTION } from "redux/types";

const INITIAL_STATE = {
  loading: false,
  message: "",
  profile_data: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_PROFILE.PENDING: {
      return { loading: true };
    }
    case UPDATE_PROFILE.SUCCESS: {
      return { loading: false, message: "Profile Updated Successfully" };
    }
    case UPDATE_PROFILE.ERROR: {
      return { loading: false, message: "Error Updating Profile" };
    }
    case GET_PROFILE.PENDING: {
      return { loading: true, message: null };
    }
    case GET_PROFILE.SUCCESS: {
      return {
        loading: false,
        profile_data: action.payload.data,
        message: "Successful",
      };
    }
    case GET_PROFILE.ERROR: {
      return {
        loading: false,
        message: "Error getting Profile details",
      };
    }
    case PROFILE_ACTION.PENDING: {
      return { loading: true };
    }
    case PROFILE_ACTION.SUCCESS: {
      return { loading: false, message: "Profile Image updated successfully" };
    }
    case PROFILE_ACTION.ERROR: {
      return { loading: false, message: "Error uploading image" };
    }
    default:
      return state;
  }
};

export default reducer;
