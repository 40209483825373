import axios from "axios";
import { fetchAffCommissionsApi, fetchAffRecCommission } from "redux/apiRoutes";
import { FETCH_AFF_COMMISSIONS, FETCH_AFF_REC_COMMISSIONS } from "redux/types";

import AuthSession from "utils/session-storage";

export const fetchAffiliateCommissions = (data, filterData) => (dispatch) => {
  dispatch({ type: FETCH_AFF_COMMISSIONS.PENDING, payload: null });
  const authSessionToken = new AuthSession().get();
  const headers = {
    Authorization: `Bearer ${authSessionToken.token}`,
  };
  const fetchSearchParams = new URLSearchParams(filterData);
  axios
    .post(`${fetchAffCommissionsApi}?${fetchSearchParams.toString()}`, data, {
      headers,
    })
    .then((response) => {
      if (response.data.success === false) {
        dispatch({ type: FETCH_AFF_COMMISSIONS.ERROR, payload: null });
      } else {
        dispatch({
          type: FETCH_AFF_COMMISSIONS.SUCCESS,
          payload: response.data,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: FETCH_AFF_COMMISSIONS.ERROR, payload: null });
    });
};

export const fetchAffRecurringCommission = (data) => (dispatch) => {
  dispatch({ type: FETCH_AFF_REC_COMMISSIONS.PENDING, payload: null });
  const authSessionToken = new AuthSession().get();
  const headers = {
    Authorization: `Bearer ${authSessionToken.token}`,
  };
  axios
    .post(fetchAffRecCommission, data, {
      headers,
    })
    .then((response) => {
      if (response.data.success === false) {
        dispatch({ type: FETCH_AFF_REC_COMMISSIONS.ERROR, payload: null });
      } else {
        dispatch({
          type: FETCH_AFF_REC_COMMISSIONS.SUCCESS,
          payload: response.data,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: FETCH_AFF_REC_COMMISSIONS.ERROR, payload: null });
    });
};
