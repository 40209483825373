export const subMenuAnimate = {
   enter: {
      opacity: 1,
      rotateX: 0,
      transition: {
         duration: 0.3,
      },
      display: "block",
   },
   exit: {
      opacity: 0,
      rotateX: -15,
      transition: {
         duration: 0.3,
         delay: 0.2,
      },
      transitionEnd: {
         display: "none",
      },
   },
};
