import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../table.scss";
import { MainTable, TableWrapper } from "../styles";
import { Skeleton } from "@mui/material";
import NoData from "components/nodata";
import { formatDate } from "utils/date-formatters";

const MainPayoutsHistoryTable = () => {
  const {t, i18n} = useTranslation('common');
  const { hostoryLoading: loading, payouts_history } = useSelector(
    (state) => state.aff_payouts
  );
  const renderPayoutsData = () => {
    return loading ? (
      <>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
      </>
    ) : (
      payouts_history.map((payout) => {
        const {
          campaignId: id,
          campaignName,
          totalCommission,
          totalConversion,
          createdAt,
        } = payout;
        return (
          <tr key={id}>
            <td>
              <label class="customContainer">
                <input type="checkbox" />
                <span class="customCheckmark"></span>
              </label>
            </td>
            <td>{campaignName}</td>
            <td>{formatDate(new Date(createdAt), "dd, mm, yyyy")}</td>
            <td>{totalConversion}</td>
            <td>{totalCommission}</td>
          </tr>
        );
      })
    );
  };

  const renderPayoutsHeader = () => {
    let header = [
      "",
      t("general.campaign"),
      t("general.date"),
      t("commission.conversionAmount"),
      t("commission.commissionAmount"),
    ];
    return header.map((key, index) => {
      return <th key={index}>{key}</th>;
    });
  };

  return (
    <>
      {payouts_history.length === 0 && !loading ? (
        <NoData />
      ) : (
        <TableWrapper>
          <MainTable>
            <tbody>
              <tr
                style={{
                  width: "100%",
                }}
              >
                {renderPayoutsHeader()}
              </tr>
              {renderPayoutsData()}
            </tbody>
          </MainTable>
        </TableWrapper>
      )}
    </>
  );
};

export default MainPayoutsHistoryTable;
