import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import AffiliateIcon from "assets/icons/conver.png";
import CampaignIcon from "assets/icons/campaign.png";
import CommissionsIcon from "assets/icons/commissions.png";
import MarketingIcon from "assets/icons/marketing.png";
import PayoutIcon from "assets/icons/payout.png";
import ReportIcon from "assets/icons/report.png";

export function useCurrentSideLinks() {
  const {t, i18n} = useTranslation('common');
  const links = useMemo(() => {
    return [
      {
        label: t("general.dashboard"),
        url: "/affiliate/overview",
        icon: AffiliateIcon,
      },
      {
        label: t("general.campaigns"),
        url: "#/affiliate/campaigns",
        icon: CampaignIcon,
        children: [
          {
            label: t("general.overview"),
            url: "/affiliate/campaigns",
          },
          {
            label: t("campaign.join"),
            url: "/affiliate/campaigns/join-campaigns",
          },
        ],
      },
      {
        label: t("commission.title"),
        url: "#/affiliate/commissions",
        icon: CommissionsIcon,
        children: [
          {
            label: t("general.overview"),
            url: "/affiliate/commissions",
          },
          {
            label: t("commission.recurring"),
            url: "/affiliate/commissions/recurring",
          },
        ],
      },
      {
        label: t("assets.marketing"),
        url: "#/affiliate/marketing-assets",
        icon: MarketingIcon,
        children: [
          {
            label: t("assets.socialMedia"),
            url: "/affiliate/marketing-assets/social-media-assets",
          },
          {
            label: t("assets.image"),
            url: "/affiliate/marketing-assets/image-assets",
          },
          {
            label: t("assets.video"),
            url: "/affiliate/marketing-assets/video-assets",
          },
          {
            label: t("assets.document"),
            url: "/affiliate/marketing-assets/document-assets",
          },
        ],
      },
      {
        label: t("report.title"),
        url: "/affiliate/reports/overview",
        icon: ReportIcon,
      },
      {
        label: t("payout.title"),
        url: "#/affiliate/payouts",
        icon: PayoutIcon,
        children: [
          {
            label: t("general.overview"),
            url: "/affiliate/payouts",
          },
          // {
          //   label: "Recurring Commissions",
          //   url: "/affiliate/payouts/payouts-history",
          // },
        ],
      },
    ];
  }, []);

  return {
    links,
  };
}
