import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

export const ProtectedAffiliateDashboard = ({
  component: Component,
  ...rest
}) => {
  const { loggedIn } = useSelector((state) => state.auth_state);

  return (
    <Route
      {...rest}
      render={() =>
        !loggedIn ? <Component /> : <Redirect to="/auth/affiliate/login" />
      }
    />
  );
};

export const ProtectedAuth = ({ component: Component, ...rest }) => {
  const { loggedIn } = useSelector((state) => state.auth_state);

  return (
    <Route
      {...rest}
      render={() =>
        !loggedIn ? <Component /> : <Redirect to="/affiliate/overview" />
      }
    />
  );
};
