import React from "react";
import BreadCrumb from "components/breadcrumb";
import MainHeader from "components/header";
import Commission from "components/main/commissions";

const Commissions = () => {
  const nav = [
    {
      url: "/affiliate/overview",
      label: "Dashboard",
    },
    {
      url: "/affiliate/commissions",
      label: "Commissions",
      main: true,
    },
  ];
  return (
    <>
      <MainHeader title="Commissions" />
      <BreadCrumb links={nav} />
      <Commission />
    </>
  );
};

export default Commissions;
