class AffiliateData {
  constructor() {
    this.localStoragekey = "aff_user_data";
  }
  update(key, value) {
    if (typeof key !== "string") {
      throw new Error(
        "Invalid type passed as key, only string are accepted as key"
      );
    }
    let answers = window.localStorage.getItem(this.localStoragekey);
    answers = answers === null ? {} : JSON.parse(answers);
    answers[key] = value;
    window.localStorage.setItem(
      this.localStoragekey,
      JSON.stringify({
        ...answers,
      })
    );
  }
  set(answers) {
    const obj = {};
    for (let key in answers) {
      if (
        answers.hasOwnProperty(key) &&
        !["", undefined, null].includes(answers[key])
      ) {
        obj[key] = answers[key];
      }
    }
    window.localStorage.setItem(this.localStoragekey, JSON.stringify(obj));
  }
  get() {
    let answers = window.localStorage.getItem(this.localStoragekey);
    if (answers === null) return null;
    answers = JSON.parse(answers);
    return answers;
  }
  clearCache = () =>
    window.localStorage.setItem(this.localStoragekey, JSON.stringify({}));
  clear = () => window.localStorage.removeItem(this.localStoragekey);
}

export default AffiliateData;
