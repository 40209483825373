export const File = () => (
  <svg
    width="17"
    height="22"
    viewBox="0 0 17 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 5.23965V5.5H11V0H11.2603C11.5338 1.42998e-06 11.7961 0.108643 11.9895 0.302027L16.1979 4.51043C16.3913 4.70383 16.5 4.96614 16.5 5.23965ZM10.6562 6.875C10.0891 6.875 9.625 6.41094 9.625 5.84375V0H1.03125C0.461699 0 0 0.461699 0 1.03125V20.9688C0 21.5383 0.461699 22 1.03125 22H15.4688C16.0383 22 16.5 21.5383 16.5 20.9688V6.875H10.6562ZM4.83592 7.5625C5.97502 7.5625 6.89842 8.4859 6.89842 9.625C6.89842 10.7641 5.97502 11.6875 4.83592 11.6875C3.69682 11.6875 2.77342 10.7641 2.77342 9.625C2.77342 8.4859 3.69686 7.5625 4.83592 7.5625ZM13.7734 17.875H2.77342L2.79426 15.7917L4.49217 14.0938C4.69352 13.8924 4.99916 13.9132 5.20051 14.1146L6.89842 15.8125L11.3463 11.3646C11.5477 11.1632 11.8742 11.1632 12.0756 11.3646L13.7734 13.0625V17.875Z"
      fill="#7211D4"
    />
  </svg>
);
