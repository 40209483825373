import React from "react";
import { useTranslation } from "react-i18next";
import BreadCrumb from "components/breadcrumb";
import MainHeader from "components/header";
import SocialMA from "components/main/marketing-assets/social-media";

const SocialMediaAssets = () => {
  const {t, i18n} = useTranslation('common');
  const nav = [
    {
      url: "/affiliate/overview",
      label: t("general.dashboard"),
    },
    {
      url: "/affiliate/marketing-assets/social-media-assets",
      label: t("assets.marketing"),
    },
    {
      url: "/affiliate/marketing-assets/social-media-assets",
      label: t("assets.socialMedia"),
      main: true,
    },
  ];
  return (
    <>
      <MainHeader title={t("assets.marketing")} />
      <BreadCrumb links={nav} />
      <SocialMA />
    </>
  );
};

export default SocialMediaAssets;
