import styled from "styled-components";
import { Heading, Small, MainColor } from "globals/styles";

export const Title = styled.p`
  font-size: ${Heading};
  font-weight: bold;
  line-height: 0;
  padding: 1em 0;
  color: ${({ theme }) => theme.mainText};
`;

export const Description = styled.div`
  font-size: ${Small};
  color: ${({ theme }) => theme.secondaryText};
`;

export const SmallText = styled.small`
  font-size: ${Small};
  color: ${({ theme }) => theme.mainText};
`;

export const BoxTitle = styled.p`
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background: ${({ theme }) => theme.sectionBg};
  font-size: ${Heading};
  font-weight: 600;
  padding: 1em 2em;
  margin-bottom: 0.2em;
  color: ${({ theme }) => theme.mainText};
`;

export const NotificationTitle = styled.p`
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  font-size: ${Small};
  font-weight: 600;
  padding: 1em 2em;
  background: #fff;
  margin-bottom: 0.2em;
  color: #1f0042;
`;

export const Message = styled.p`
  color: #c2c2c2;
  font-size: ${Small};
  margin-bottom: 0.5em;
  line-height: 1.5;
`;
