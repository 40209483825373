import { LOGIN_AFF, SIGN_OUT_AFF } from "redux/types";

const INITIAL_STATE = {
  loggedIn: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_AFF: {
      return { loggedIn: true };
    }
    case SIGN_OUT_AFF: {
      return { loggedIn: false };
    }
    default:
      return state;
  }
};

export default reducer;
