import axios from "axios";
import { toast } from "react-toastify";
import {
  SIGN_UP_STEP,
  SIGN_UP_AFF,
  SETUP_PROFILE_THREE,
  SETUP_PROFILE_TWO,
  COMPLETE_SETUP,
} from "redux/types";
import {
  affSignUp,
  affSignUpInvite,
  updateProfile,
  affForgotPassword,
  affResetPassword,
} from "redux/apiRoutes";
import AffiliateData from "utils/user-data";
import AffiliateAuthSession from "utils/session-storage";
import AuthSession from "utils/session-storage";

const session = new AffiliateAuthSession();
const date = new Date();

export const increaseStep = () => (dispatch) => {
  dispatch({ type: SIGN_UP_STEP.INCREASE, payload: null });
};

export const decreaseStep = () => (dispatch) => {
  dispatch({ type: SIGN_UP_STEP.DECREASE, payload: null });
};

export const forgotPasswordRequest = (data, history) => (dispatch) => {
  axios
    .put(affForgotPassword, data)
    .then((response) => {
      if (response.data.success === false) {
        toast.error("An Account does not exist for this email");
      } else {
        toast.success("Request sent successfully");
        setTimeout(() => {
          history.push("/auth/proceed-reset/");
        });
      }
    })
    .catch((error) => {
      toast.error("Email does not exist");
    });
};

export const resetPassword = (data, history) => (dispatch) => {
  axios
    .put(affResetPassword, data)
    .then((response) => {
      if (response.data.success === false) {
        toast.error("Could not reset password");
      } else {
        toast.success("Password reset successful");
        setTimeout(() => {
          history.push("/auth/login/");
        });
      }
    })
    .catch((error) => {
      toast.error("Could not reset password");
    });
};

export const signUpAff = (domain, data) => (dispatch) => {
  const campaignUrl = data.campaignUrl;
  delete data.campaignUrl;
  dispatch({ type: SIGN_UP_AFF.PENDING, payload: null });
  axios
    .post(
      `${affSignUp}/${domain}?campaignUrl=${campaignUrl ? campaignUrl : ""}`,
      data
    )
    .then((response) => {
      const affiliateData = new AffiliateData();
      console.log({ "Step1 User Response": response });
      affiliateData.set(response.data);
      const affToken = affiliateData.get().data.token;
      session.set({
        token: affToken,
        expiredAt: new Date(date).setHours(date.getHours() + 20),
      });
      dispatch({ type: SIGN_UP_AFF.SUCCESS, payload: null });
      dispatch(increaseStep());
      toast.success("SignUp Successful");
    })
    .catch((error) => {
      toast.error("Cannot create this account");
      dispatch({ type: SIGN_UP_AFF.ERROR, payload: null });
    });
};

export const signUpAffInvite = (data) => (dispatch) => {
  dispatch({ type: SIGN_UP_AFF.PENDING, payload: null });
  axios
    .post(affSignUpInvite, data)
    .then((response) => {
      console.log({ response });
      const affiliateData = new AffiliateData();
      affiliateData.set(response.data);
      const affToken = affiliateData.get().data.token;
      session.set({
        token: affToken,
        expiredAt: new Date(date).setHours(date.getHours() + 20),
      });
      dispatch({ type: SIGN_UP_AFF.SUCCESS, payload: null });
      dispatch(increaseStep());
      toast.success("SignUp Successful");
    })
    .catch((error) => {
      console.log("aff error", error?.response?.data);
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Cannot create Affiliate account"
      );
      dispatch({ type: SIGN_UP_AFF.ERROR, payload: null });
    });
};

export const completeStepTwo = (data) => (dispatch) => {
  dispatch({ type: SETUP_PROFILE_TWO, payload: data });
  dispatch(increaseStep());
};

export const completeStepThree = (data) => (dispatch) => {
  dispatch({ type: SETUP_PROFILE_THREE, payload: data });
};

export const setupProfile = (domain, id, data, history) => (dispatch) => {
  dispatch({ type: COMPLETE_SETUP.PENDING, payload: null });
  const authSessionToken = new AuthSession().get();
  const headers = {
    Authorization: `Bearer ${authSessionToken.token}`,
  };

  console.log("URL", `${updateProfile}/${domain}/${id}`);
  axios
    .post(`${updateProfile}/${domain}/${id}`, data, { headers })
    .then((response) => {
      toast.success("Profile Created Successfully");
      dispatch({ type: COMPLETE_SETUP.SUCCESS, payload: response.data });
      setTimeout(() => {
        history.push("/affiliate/overview");
      }, 2000);
    })
    .catch((error) => {
      console.log({ error });
      toast.error(error?.response?.data?.message);
      dispatch({ type: COMPLETE_SETUP.ERROR, payload: null });
    });
};
