import React from "react";
import { useTranslation } from "react-i18next";
import BreadCrumb from "components/breadcrumb";
import MainHeader from "components/header";
import Recurring from "components/main/commissions/recurring";

const RecurringCommissions = () => {
  const {t, i18n} = useTranslation('common');
  const nav = [
    {
      url: "/affiliate/overview",
      label: t("general.dashboard"),
    },
    {
      url: "/affiliate/commissions",
      label: t("commission.title"),
    },
    {
      url: "/affiliate/commissions/recurring",
      label: t("commission.recurring"),
      main: true,
    },
  ];
  return (
    <>
      <MainHeader title={t("commission.recurring")} />
      <BreadCrumb links={nav} />
      <Recurring />
    </>
  );
};

export default RecurringCommissions;
