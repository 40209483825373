/** @format */

export const baseUrl = "https://api.metricks.io/api/v1/";

export const fetchAffiliateReports = `${baseUrl}report/affiliate/dashboard/`;
export const fetchAffiliateDetails = `${baseUrl}report/affiliate/details`;
export const addAccountManagerApi = `${baseUrl}accountManager/`;
export const fetchAccountManagersApi = `${baseUrl}accountManager/`;
export const fetchAllAccountManagerApi = `${baseUrl}all/accountManager/`;
export const sendMailApi = `${baseUrl}send/email`;

export const createCommissionBlockApi = `${baseUrl}commission/block/`;
export const createSignupBlockApi = `${baseUrl}signup/block/`;
export const getAllBannedApi = `${baseUrl}banned/`;
export const setMaxCommAmountApi = `${baseUrl}commission/maximum/`;
export const fetchAdvCampaigns = `${baseUrl}report/affiliate/campaigns`;
export const fetchCampaignsApi = `${baseUrl}report/affiliate/advertiser-campaigns`;
export const fetchSingleCampaigns = `${baseUrl}report/affiliate/single-access-campaigns`;
export const joinCampaignApi = `${baseUrl}report/affiliate/campaign/join`;
export const fetchAffCommissionsApi = `${baseUrl}report/affiliate/commissions`;
export const fetchAffRecCommission = `${baseUrl}report/affiliate/commission/recurring`;

export const fetchAffPayoutsOverview = `${baseUrl}report/affiliate/payout/overview`;
export const fetchAffPayoutsHistory = `${baseUrl}report/affiliate/payout/history`;
export const updateAffProfile = `${baseUrl}report/affiliate/update-profile`;
export const updateProfile = `${baseUrl}affiliate/update`;
export const getProfileData = `${baseUrl}report/affiliate/details`;
export const uploadImage = `${baseUrl}report/affiliate/upload-image`;
export const deleteImage = `${baseUrl}report/affiliate/delete-image`;
export const fetchSocialMA = `${baseUrl}report/affiliate/assets/social-media`;
export const fetchIA = `${baseUrl}report/affiliate/assets/image`;
export const fetchVA = `${baseUrl}report/affiliate/assets/video`;
export const fetchDA = `${baseUrl}report/affiliate/assets/document`;
export const fetchSingle = `${baseUrl}report/affiliate/assets`;

// auth
export const affForgotPassword = `${baseUrl}affiliate/forgot/password`;
export const affResetPassword = `${baseUrl}affiliate/reset/password`;
export const affSignUp = `${baseUrl}affiliate/signup`;
export const affSignUpInvite = `${baseUrl}reports/affiliate/invite/finish-signup`;
export const affSignin = `${baseUrl}affiliate/signin/`;
