import { ButtonWrapper } from "./button-styles";
import { Export } from "../icons";

export const ExportButton = ({ caption, fn }) => {
  return (
    <ButtonWrapper>
      <Export />
      <span>{caption}</span>
    </ButtonWrapper>
  );
};
