/** @format */

import styled from "styled-components";

export const Box = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4em 1.5em;

  .back {
    border: none;
    cursor: pointer;
  }
`;

export const Section = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 2em;
`;
export const Main = styled.main`
  padding: 4em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.7em;
  height: 120vh;
  position: relative;
`;

export const BoxTitle = styled.small`
  color: #203038;
  font-size: 1.7rem;
  font-weight: 600;
  align-self: center;

  @media screen and (max-width: 478px) {
    text-align: center;
  }
`;

export const Small = styled.small`
  font-size: 1rem;
  color: #203038;
  text-align: center;
  align-self: center;

  a {
    color: #7211d4;
    text-decoration: none;
  }
`;
export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;

  @media screen and (max-width: 478px) {
    width: 300px;
  }
`;

export const Label = styled.label`
  font-size: 1rem;
  color: #203038;
`;

export const Input = styled.input`
  height: 45px;
  border-radius: 20px;
  padding: 0 2em;
  border: 2px solid #c1c0ff;
  outline: none;
`;

export const CheckBox = styled.input`
  width: 20px;
  height: 20px;
  border-radius: 10px;
`;

export const Button = styled.button`
  height: 60px;
  color: #fff;
  background: #7211d4;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  margin: 1em 0;
`;

export const Recaptcha = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  gap: 1.5em;
  padding: 0 1em;
  margin: 1.5em 0;
  justify-content: space-between;
  width: 80%;
  height: 60px;
  border: 1px solid #c1c0ff;
  border-radius: 20px;
`;
