import React, { useReducer, createContext } from "react";

const setToggleSidebarAction = () => ({
  type: "TOGGLE_SIDEBAR",
});
const initialState = {
  toggle: false,
};

const ToggleSidebarContext = createContext({
  state: initialState,
  dispatch: () => null,
});

function toggleSidebarReducer(state, action) {
  switch (action.type) {
    case "TOGGLE_SIDEBAR": {
      return {
        ...state,
        toggle: !state.toggle,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// eslint-disable-next-line react/prop-types
function ToggleSidebarContextProvider({ children }) {
  const [state, dispatch] = useReducer(toggleSidebarReducer, initialState);

  return (
    <ToggleSidebarContext.Provider value={{ state, dispatch }}>
      {children}
    </ToggleSidebarContext.Provider>
  );
}

export {
  ToggleSidebarContextProvider,
  ToggleSidebarContext,
  setToggleSidebarAction,
};
