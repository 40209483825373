/**
 * /* eslint-disable no-useless-escape
 *
 * @format
 */

import { SmallText } from "components/typography";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { signUpAff, signUpAffInvite } from "redux/actions/auth/auth";
import Input from "../component";
import { Box, BoxTitle, Button, FormGroup, Main, Small } from "../styles";

const Step1 = (props) => {
  const {t, i18n} = useTranslation('common');
  const dispatch = useDispatch();
  var searchParams = new URLSearchParams(window?.location?.search);

  const [confirmPassword, setConfirmPassword] = useState("");

  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
  });

  const [affiliateData, setAffiliateData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    inviteId: 0,
  });

  const [useData, setUserData] = useState({});
  const [counter, setCount] = useState({});
  const [affSubmit, setAffSubmit] = useState(false);

  const paramsToObject = (entries) => {
    let result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  };

  function handleChange(evt) {
    const value = evt.target.value;

    setForm({
      ...form,
      [evt.target.name]: value,
    });

    setAffiliateData({
      ...affiliateData,
      [evt.target.name]: value,
    });
  }

  const passwordRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)^[^ ]+$/;
  function onSubmit() {
    var dom = /:\/\/([^\/]+)/.exec(window.location.origin)[1];
    let subDomain = dom.split(".", -1)[0];
    if (subDomain == 'www') {
      subDomain = `${subDomain}.${dom.split(".")[1]}`;
    }
    let portalUrl;
    const campaignUrl = localStorage.getItem("campaignUrl");
    if (campaignUrl) {
      form.campaignUrl = campaignUrl;
    }

    subDomain.includes("localhost")
      ? (portalUrl = "megsdaam")
      : (portalUrl = subDomain);

    localStorage.setItem("portalUrl", portalUrl);

    // if (form.password.match(passwordRegex)) {
    //   return;
    // }

    // if (form.password !== confirmPassword) {
    //   // toast.error("Passwords do not match");
    //   return;
    // }
    // if (form.password.length < 8 || confirmPassword.length < 8) {
    //   // toast.error("Password should be at least 8 characters");
    //   return;
    // }

    if (passwordRegex.test(form.password) && form.password == confirmPassword) {
      console.log(portalUrl);
      // if (affSubmit == "true") {
      //   var realData = {
      //     ...affiliateData,
      //     inviteId: parseInt(useData?.id),
      //   };
      //   console.log({ inviteID: useData });

      //   // dispatch(signUpAffInvite(realData));
      //   return;
      // }
      dispatch(signUpAff(portalUrl, form));
    }
  }

  const temp = JSON.parse(localStorage.getItem("template"));

  useEffect(() => {
    console.log("window?.location?.search", window?.location?.search);

    // if (window?.location?.search) {
    let firstSplit = searchParams.entries();
    let paramsOBJ = paramsToObject(firstSplit);
    setUserData(paramsOBJ);
    setAffSubmit(useData.affiliate);

    console.log("paramsOBJ affiliate ", useData.affiliate);

    let realData = {
      firstname: paramsOBJ?.firstname,
      lastname: paramsOBJ?.lastname,
      email: paramsOBJ?.email,
      password: paramsOBJ?.password,
    };

    let affiliateData = {
      firstname: paramsOBJ?.firstname,
      lastname: paramsOBJ?.lastname,
      email: paramsOBJ?.email,
      password: paramsOBJ?.password,
      inviteId: paramsOBJ?.id,
    };

    setForm(realData);
    setAffiliateData(affiliateData);
    // }

    setCount(1);
  }, [counter]);

  const history = useHistory();
  return (
    <Main
      style={{
        height: "170vh",
      }}
    >
      {temp ? (
        <Box>
          <BoxTitle>{temp.data.templates.campaign_title}</BoxTitle>
          {/* <button className="back" onClick={() => dispatch(increaseStep())}>
          Skip
        </button> */}
          <Small>{temp.data.templates.campaign_description}</Small>
          <Small
            style={{
              fontWeight: "bold",
            }}
          >
            {t("auth.step1")}
          </Small>
          <FormGroup>
            <Input
              type="text"
              label={t("auth.fName")}
              name="firstname"
              defaultValue={useData?.firstname}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              label={t("auth.lName")}
              name="lastname"
              defaultValue={useData?.lastname}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="email"
              label={t("auth.email")}
              name="email"
              defaultValue={useData?.email}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="password"
              label={t("auth.nPassword")}
              name="password"
              onChange={handleChange}
            />

            {form.password !== undefined &&
              !passwordRegex.test(form.password) && (
                <SmallText style={{ color: "red" }}>
                  {t("auth.passwordError")}
                </SmallText>
              )}
          </FormGroup>
          <FormGroup>
            <Input
              type="password"
              label={t("auth.confirmPassword")}
              name="confirmPassword"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {confirmPassword !== "" && confirmPassword !== form.password && (
              <SmallText style={{ color: "red" }}>
                {t("auth.passwordError2")}
              </SmallText>
            )}
          </FormGroup>

          <FormGroup>
            <Button onClick={onSubmit}>{t("auth.continue")}</Button>
          </FormGroup>

          <p>
            {t("auth.haveAccount")}?
            <span>
              <Link to="/auth/login">{t("auth.signInCta")}</Link>
            </span>
          </p>
        </Box>
      ) : (
        history.push("/")
      )}
    </Main>
  );
};

export default Step1;
