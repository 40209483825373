import React from "react";
import { useTranslation } from "react-i18next";
import BreadCrumb from "components/breadcrumb";
import MainHeader from "components/header";
import VideoA from "components/main/marketing-assets/video";

const VideoAssets = () => {
  const {t, i18n} = useTranslation('common');
  const nav = [
    {
      url: "/affiliate/overview",
      label: t("general.dashboard"),
    },
    {
      url: "/affiliate/marketing-assets/social-media-assets",
      label: t("assets.marketing"),
    },
    {
      url: "/affiliate/marketing-assets/video-assets",
      label: t("assets.video"),
      main: true,
    },
  ];
  return (
    <>
      <MainHeader title={t("assets.video")} />
      <BreadCrumb links={nav} />
      <VideoA />
    </>
  );
};

export default VideoAssets;
