import axios from "axios";
import {
  fetchAffPayoutsOverview,
  fetchAffPayoutsHistory,
} from "redux/apiRoutes";
import { FETCH_AFF_PAYOUTS_OVER, FETCH_AFF_PAYOUTS_HIST } from "redux/types";
import AuthSession from "utils/session-storage";

export const fetchAffiliatePayouts = (data, filterData) => (dispatch) => {
  dispatch({ type: FETCH_AFF_PAYOUTS_OVER.PENDING, payload: null });
  const authSessionToken = new AuthSession().get();
  const headers = {
    Authorization: `Bearer ${authSessionToken.token}`,
  };
  const fetchSearchParams = new URLSearchParams(filterData);
  axios
    .post(`${fetchAffPayoutsOverview}?${fetchSearchParams.toString()}`, data, {
      headers,
    })
    .then((response) => {
      if (response.data.success === false) {
        dispatch({ type: FETCH_AFF_PAYOUTS_OVER.ERROR, payload: null });
      } else {
        dispatch({
          type: FETCH_AFF_PAYOUTS_OVER.SUCCESS,
          payload: response.data,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: FETCH_AFF_PAYOUTS_OVER.ERROR, payload: null });
    });
};

export const fetchAffiliatePayoutsHistory =
  (data, filterData) => (dispatch) => {
    dispatch({ type: FETCH_AFF_PAYOUTS_HIST.PENDING, payload: null });
    const authSessionToken = new AuthSession().get();
    const headers = {
      Authorization: `Bearer ${authSessionToken.token}`,
    };
    const fetchSearchParams = new URLSearchParams(filterData);
    axios
      .post(`${fetchAffPayoutsHistory}?${fetchSearchParams.toString()}`, data, {
        headers,
      })
      .then((response) => {
        if (response.data.success === false) {
          dispatch({ type: FETCH_AFF_PAYOUTS_HIST.ERROR, payload: null });
        } else {
          dispatch({
            type: FETCH_AFF_PAYOUTS_HIST.SUCCESS,
            payload: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch({ type: FETCH_AFF_PAYOUTS_HIST.ERROR, payload: null });
      });
  };
