/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../table.scss";
import Join from "./join";
import { formatDate } from "utils/date-formatters";
import NoData from "components/nodata";
import { MainTable, TableWrapper } from "../styles";
import { useTranslation } from "react-i18next";

const CampaignsRequestTable = () => {
  const {t, i18n} = useTranslation('common');
  const { campaigns } = useSelector((state) => state.aff_campaigns);
  const [cmpId, setCmpId] = useState(null);
  const loading = false;

  const loadingId = 1;
  const [showJoin, setShowJoin] = useState(false);

  const handleCampaignStatusChange = (id, newValue, prevValue) => {
    return true;
  };

  console.log("campaigns", campaigns);

  useEffect(() => {}, [campaigns]);

  const handleJoin = (id) => {
    setShowJoin(true);
    setCmpId(id);
  };
  const renderCampaignsData = () =>
    campaigns &&
    campaigns
      .filter((el) => el.status == 'approved')
      .map((campaign) => {
        const { id, name, commission, status, createdAt } = campaign;
        return (
          <tr key={id}>
            <td>
              <label class="customContainer">
                <input type="checkbox" />
                <span class="customCheckmark"></span>
              </label>
            </td>
            <td>{name}</td>
            <td>{commission ?? t("general.no_commission")}</td>
            <td
              style={{
                color:
                  status === "approved"
                    ? "#5cbb5c"
                    : status === "pending"
                    ? "#ddb13f"
                    : "#ed5565",
              }}
            >
              {status}
            </td>
            <td>{formatDate(new Date(createdAt), "dd, mm, yyyy")}</td>
            {/* <td>
            {status !== "approved" ? (
              <button
                style={{
                  background: "#DBCEE9",
                  border: "none",
                  padding: ".5em 1em",
                  borderRadius: 4,
                  cursor: "pointer",
                }}
                onClick={() => handleJoin(id)}
              >
                Join
              </button>
            ) : null}
          </td> */}
          </tr>
        );
      });
  const renderCampaignsHeader = () => {
    let header = [
      "",
      t("general.campaign"),
      t("general.commission"),
      t("general.status"),
      t("general.signUpDate"),
    ];
    return header.map((key, index) => {
      return <th key={index}>{key}</th>;
    });
  };

  return (
    <>
      {!campaigns ? (
        <NoData />
      ) : (
        <TableWrapper>
          <MainTable>
            <tbody>
              <tr
                style={{
                  width: "100%",
                }}
              >
                {renderCampaignsHeader()}
              </tr>
              {renderCampaignsData()}
            </tbody>
          </MainTable>
        </TableWrapper>
      )}
      {showJoin && (
        <Join status={showJoin} changeStatus={setShowJoin} id={cmpId} />
      )}
    </>
  );
};

export default CampaignsRequestTable;
