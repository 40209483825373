import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import "../table.scss";
import { MainTable, TableWrapper } from "../styles";
import { Skeleton } from "@mui/material";
import { formatDate } from "utils/date-formatters";
import NoData from "components/nodata";

const RecurringCommissionsTable = ({ getData }) => {
  const { recurringLoading, recurring_commissions } = useSelector(
    (state) => state.aff_commissions
  );

  useEffect(() => {
    getData([
      [
        "CAMPAIGN NAME",
        "AFFILIATE ID",
        "CREATED AT",
        "INTERVAL",
        "COMMISSION AMOUNT",
        "CONVERSION AMOUNT",
      ],

      [
        recurring_commissions && recurring_commissions.length > 0
          ? recurring_commissions.map((comm) => comm.campaign_name)
          : "No data",
        recurring_commissions && recurring_commissions.length > 0
          ? recurring_commissions.map((comm) => comm.affId)
          : "No data",

        recurring_commissions && recurring_commissions.length > 0
          ? recurring_commissions.map((comm) => comm.createdAt)
          : "No data",

        recurring_commissions && recurring_commissions.length > 0
          ? recurring_commissions.map((comm) => comm.interval)
          : "No data",

        recurring_commissions && recurring_commissions.length > 0
          ? recurring_commissions.map((comm) => comm.commissionAmount)
          : "No data",
        recurring_commissions && recurring_commissions.length > 0
          ? recurring_commissions.map((comm) => comm.conversionAmount)
          : "No data",
      ],
    ]);
  }, [recurring_commissions]);

  const renderCommissionsData = () => {
    return recurringLoading ? (
      <>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
      </>
    ) : (
      recurring_commissions.map((commission) => {
        const {
          commissionId,
          campaign_name,
          affId,
          createdAt,
          interval,
          commissionAmount,
          conversionAmount,
        } = commission;
        return (
          <tr key={commissionId}>
            <td>
              <label class="customContainer">
                <input type="checkbox" />
                <span class="customCheckmark"></span>
              </label>
            </td>
            <td>{campaign_name}</td>
            <td>{affId}</td>
            <td>{formatDate(new Date(createdAt), "dd, mm, yyyy")}</td>
            <td>{interval}</td>
            <td>{commissionAmount}</td>
            <td>{conversionAmount}</td>
          </tr>
        );
      })
    );
  };

  const renderCommissionsHeader = () => {
    let header = [
      "",
      "campaign",
      "affiliate's ID",
      "date",
      "interval",
      "conversion amount",
      "commission amount",
    ];
    return header.map((key, index) => {
      return <th key={index}>{key}</th>;
    });
  };

  return (
    <>
      {recurring_commissions.length === 0 && !recurringLoading ? (
        <NoData />
      ) : (
        <TableWrapper>
          <MainTable>
            <tbody>
              <tr
                style={{
                  width: "100%",
                }}
              >
                {renderCommissionsHeader()}
              </tr>
              {renderCommissionsData()}
            </tbody>
          </MainTable>
        </TableWrapper>
      )}
    </>
  );
};

export default RecurringCommissionsTable;
