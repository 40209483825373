/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Intro, ControlGroup } from "components/containers";
import PayoutsTable from "components/tables/payouts";
import { Title, Description } from "components/typography";
import { Section, FilterActions, FilterTitle, Wrapper } from "../styles";
import PayoutsFilterer from "components/filters/payouts";
import { ExportButton } from "components/buttons";
import { fetchAffiliatePayouts } from "redux/actions/payouts";
import AffiliateData from "utils/user-data";
import MainSelect from "components/main-select";
import { CSVLink } from "react-csv";

const Payout = () => {
  const [showFilter, setShowFilter] = useState(false);
  const { campaigns } = useSelector((state) => state.aff_campaigns);
  console.log(campaigns);

  const dispatch = useDispatch();
  const loggedInAff = new AffiliateData();

  const options =
    campaigns === null
      ? []
      : campaigns.map((el) => ({ label: el.name, value: el.id }));

  useEffect(() => {
    dispatch(
      fetchAffiliatePayouts(
        {
          domain: loggedInAff.get().data.affiliate.domain,
          affId: loggedInAff.get().data.affiliate.id,
        },
        {}
      )
    );
  }, []);

  const [csvData, setCsvData] = useState([]);
  const getData = (data) => {
    setCsvData(data);
  };

  return (
    <Section>
      <Intro>
        <Title>All Payouts</Title>
        <Description>
          Find a summary of all commissions you have received for your
          conversions.
        </Description>
      </Intro>
      <Wrapper>
        <FilterActions>
          <FilterTitle onClick={() => null}>
            {/* Filter
            <FaIcons.FaFilter
              style={{
                background: "#dbcee9",
                padding: 7,
                fontSize: 28,
              }}
            /> */}
          </FilterTitle>
          <ControlGroup>
            <MainSelect label={null} options={options} />

            <CSVLink
              data={csvData}
              filename={"Payouts report.csv"}
              style={{ textDecoration: "none" }}
            >
              <ExportButton caption="Export Report" />
            </CSVLink>
          </ControlGroup>
        </FilterActions>
        {showFilter && <PayoutsFilterer />}
        <PayoutsTable getData={getData} />
      </Wrapper>
    </Section>
  );
};

export default Payout;
