/**
 * /* eslint-disable no-useless-escape
 *
 * @format
 */

/**
 * /* eslint-disable no-useless-escape
 *
 * @format
 */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DOMAIN } from "constant";
import { FormalWrapper, TemplateWrapper, Button, TextDiv } from "./styles";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const LandingPage = (props) => {
  const {t, i18n} = useTranslation('common');
  var dom = /:\/\/([^\/]+)/.exec(window.location.origin)[1];
  let subDomain = dom.split(".", -1)[0];
  if (subDomain == 'www') {
    subDomain = `${subDomain}.${dom.split(".")[1]}`;
  }
  let searchParams = new URLSearchParams(window.location.search);

  const [template, setTemplate] = useState({});


  
  useEffect(() => {
  
    localStorage.setItem(
      "portalUrl",
      `${subDomain.includes("localhost") ? DOMAIN : subDomain}`
    );
    let campaign;
    if (searchParams.has("campaign")) {
      campaign = searchParams.get("campaign");
      localStorage.setItem("campaignUrl", campaign);
      axios
      .get(
        `https://api.metricks.io/api/v1/template/settings?domain=${
          subDomain.includes("localhost") ? DOMAIN : subDomain
        }&campaign=${campaign}`
      )
      .then((response) => {
        localStorage.setItem("template", JSON.stringify(response.data));
        setTemplate(response.data);
        console.log(response.data?.data?.templates);
      });
    } else {
      axios
      .get(
        `https://api.metricks.io/api/v1/template/settings?domain=${
          subDomain.includes("localhost") ? DOMAIN : subDomain
        }`
      )
      .then((response) => {
        localStorage.setItem("template", JSON.stringify(response.data));
        setTemplate(response.data);
        console.log(response.data?.data?.templates);
      });
    }

    console.log("this is the template data ", template);
  }, []);

  function handleClick() {
    window.location.href = `${window.location.origin}/auth/signup/${window.location.search}`;
  }

  return (
    <TemplateWrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {template?.data?.templates?.campaign_title ?? "Metricks.io"}
        </title>
        <meta
          name="description"
          content={
            template?.data?.templates?.campaign_description ??
            "Metricks.io Affiliate Program "
          }
        />
        <link rel="canonical" href={`${window.location.origin}`} />
        <link
          rel="icon"
          href={
            template?.data?.templates?.campaign_logo
              ? template?.data?.templates?.campaign_logo
              : "https://blog.metricks.io/wp-content/uploads/2021/11/metricks-blue-1.png-e1635854183285-1.png"
          }
          sizes="32x32"
        />
        <link
          rel="icon"
          href={
            template?.data?.templates?.campaign_logo
              ? template?.data?.templates?.campaign_logo
              : "https://blog.metricks.io/wp-content/uploads/2021/11/metricks-blue-1.png-e1635854183285-1.png"
          }
          sizes="192x192"
        />
        <link
          rel="apple-touch-icon"
          href={
            template?.data?.templates?.campaign_logo
              ? template?.data?.templates?.campaign_logo
              : "https://blog.metricks.io/wp-content/uploads/2021/11/metricks-blue-1.png-e1635854183285-1.png"
          }
        ></link>
      </Helmet>
      <FormalWrapper>
        <Link to="/" exact>
          <img
            width="50px"
            height="50px"
            src={
              template?.data?.templates?.campaign_logo
                ? template?.data?.templates?.campaign_logo
                : "https://blog.metricks.io/wp-content/uploads/2021/11/metricks-blue-1.png-e1635854183285-1.png"
            }
            alt="Campaign Logo"
          />
        </Link>

        <TextDiv>
          <h1>
            {t("welcome.title", {
              title: template?.data?.templates?.campaign_title,
            })}
          </h1>
          <p>
            {t("welcome.description", {
              description: template?.data?.templates?.campaign_description,
            })}
          </p>
        </TextDiv>
        <div>
          <Button
            onClick={handleClick}
            bg={template?.data?.templates?.primary_color}
            color="#000"
          >
            {t("welcome.cta")}
          </Button>
        </div>

        <svg
          className="svg"
          style={{ position: "absolute", top: 0, right: "-200px" }}
          width="900"
          height="1025"
          viewBox="0 0 900 1025"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M269.918 991.025L0.964844 0.396576H305.405L598.639 991.025H269.918Z"
            fill={
              template?.data?.templates?.primary_color
                ? template?.data?.templates?.primary_color
                : "#B1A8EB"
            }
          />
          <path
            d="M1025.8 975.766L695.516 0.396576H1030.97L1025.8 975.766Z"
            fill={
              template?.data?.templates?.primary_color
                ? template?.data?.templates?.primary_color
                : "#B1A8EB"
            }
          />
          <path
            d="M589.26 1024.4L920.465 0.396576H589.26L270.248 990.9L589.26 1024.4Z"
            fill={
              template?.data?.templates?.secondary_color
                ? template?.data?.templates?.secondary_color
                : "#44419A"
            }
          />
        </svg>
      </FormalWrapper>
    </TemplateWrapper>
  );
};

export default LandingPage;

// Lolavivianfemi@gmail.com
// Pass!mide1234
