import { useContext } from "react";
import {
  setToggleSidebarAction,
  ToggleSidebarContext,
} from "context/toggle-sidebar";

export const useToggleSidebar = () => {
  const context = useContext(ToggleSidebarContext);
  const { state, dispatch } = context;

  function setToggleSidebar() {
    dispatch(setToggleSidebarAction());
  }

  if (context === undefined) {
    throw new Error(
      "useToggleSidebar must be used within a ToggleSidebarContextProvider"
    );
  }

  return {
    toggle: state.toggle,
    setToggleSidebar,
  };
};
