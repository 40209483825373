import axios from "axios";
import { toast } from "react-toastify";
import {
  fetchAdvCampaigns,
  fetchCampaignsApi,
  joinCampaignApi,
  fetchSingleCampaigns,
} from "redux/apiRoutes";
import {
  FETCH_ADV_CAMPAIGNS,
  JOIN_CAMPAIGN,
  FETCH_CAMPAIGNS,
} from "redux/types";
import AuthSession from "utils/session-storage";

export const fetchAdvertiserCampaigns = (data, filterData) => (dispatch) => {
  dispatch({ type: FETCH_ADV_CAMPAIGNS.PENDING, payload: null });
  const authSessionToken = new AuthSession().get();
  const headers = {
    Authorization: `Bearer ${authSessionToken.token}`,
  };
  const fetchSearchParams = new URLSearchParams(filterData);
  axios
    .post(`${fetchAdvCampaigns}?${fetchSearchParams.toString()}`, data, {
      headers,
    })
    .then((response) => {
      dispatch({ type: FETCH_ADV_CAMPAIGNS.SUCCESS, payload: response.data });
    })
    .catch((err) => {
      dispatch({ type: FETCH_ADV_CAMPAIGNS.ERROR, payload: null });
    });
};

export const fetchCampaigns = (data, filterData) => (dispatch) => {
  dispatch({ type: FETCH_CAMPAIGNS.PENDING, payload: null });
  const authSessionToken = new AuthSession().get();
  const headers = {
    Authorization: `Bearer ${authSessionToken.token}`,
  };
  const fetchSearchParams = new URLSearchParams(filterData);
  axios
    .post(`${fetchSingleCampaigns}?${fetchSearchParams.toString()}`, data, {
      headers,
    })
    .then((response) => {
      dispatch({ type: FETCH_CAMPAIGNS.SUCCESS, payload: response.data });
    })
    .catch((err) => {
      dispatch({ type: FETCH_CAMPAIGNS.ERROR, payload: null });
    });
};

export const joinCampaign = (data) => (dispatch) => {
  dispatch({ type: JOIN_CAMPAIGN.PENDING, payload: null });
  const authSessionToken = new AuthSession().get();
  const headers = {
    Authorization: `Bearer ${authSessionToken.token}`,
  };
  axios
    .post(joinCampaignApi, data, { headers })
    .then((response) => {
      console.log({ response });
      if (response.data.success === false) {
        dispatch({ type: JOIN_CAMPAIGN.ERROR, payload: null });
        toast.success(response.data.message);
      } else {
        dispatch({ type: JOIN_CAMPAIGN.SUCCESS, payload: null });
        toast.success(response.data.message);
      }
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      dispatch({ type: JOIN_CAMPAIGN.ERROR, payload: null });
    });
};
