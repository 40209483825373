import React from "react";
import { AuthWrapper } from "./styles";
import { Bubbles } from "components/svgs/bubbles";

const AuthLayout = ({ children }) => {
  return (
    <AuthWrapper>
      <Bubbles />
      {children}
    </AuthWrapper>
  );
};

export default AuthLayout;
