/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Intro } from "components/containers";
import CampaignsRequestTable from "components/tables/campaigns";
import { Description, Title } from "components/typography";
import { FilterActions, Section, Wrapper } from "../styles";
import { fetchAdvertiserCampaigns } from "redux/actions/campaigns";
import AffiliateData from "utils/user-data";

const Campaign = () => {
  const dispatch = useDispatch();

  const affData = new AffiliateData().get();

  const data = {
    domain: affData.data.affiliate.domain,
    affId: affData.data.affiliate.id,
  };
  useEffect(() => {
    dispatch(fetchAdvertiserCampaigns(data, {}));
  }, []);

  return (
    <Section>
      <Intro>
        <Title>Overview,</Title>
        <Description>
          Find useful details and stats of every campaign you signed up for.
        </Description>
      </Intro>
      <Wrapper>
        <FilterActions></FilterActions>
        <CampaignsRequestTable />
      </Wrapper>
    </Section>
  );
};

export default Campaign;
