import React from "react";
import { useHistory, Link } from "react-router-dom";
import { Box, FormGroup, BoxTitle, Small, Button, Main } from "../styles";

const ProceedRequest = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/auth/reset-password/");
  };

  return (
    <Main style={{ height: "100vh" }}>
      <Box>
        <BoxTitle>Almost there!</BoxTitle>
        <Small>
          A password reset link has been sent to your email address. Please
          click on the link to reset password. Check your spam folder if you
          don't receive an email from us in the next few minutes.
        </Small>
        <Small style={{ color: "#7211d4" }}>
          <Link to="/auth/forgot-password/">Request a new link</Link>
        </Small>

        <FormGroup>
          <Button
            style={{
              cursor: "pointer",
            }}
            onClick={handleClick}
          >
            RESET PASSWORD
          </Button>
        </FormGroup>
        <Small style={{ width: "100%" }}>
          If you require further assistance. contact{" "}
          <a href="mailto:support@metricks.io">support@metricks.io</a>
        </Small>
      </Box>
    </Main>
  );
};

export default ProceedRequest;
