import React from "react";
import { useTranslation } from "react-i18next";
import BreadCrumb from "components/breadcrumb";
import MainHeader from "components/header";
import DocA from "components/main/marketing-assets/document";

const DocAssets = () => {
  const {t, i18n} = useTranslation('common');
  const nav = [
    {
      url: "/affiliate/overview",
      label: t("general.dashboard"),
    },
    {
      url: "/affiliate/marketing-assets/social-media-assets",
      label: t("assets.marketing"),
    },
    {
      url: "/affiliate/marketing-assets/document-assets",
      label: t("assets.document"),
      main: true,
    },
  ];
  return (
    <>
      <MainHeader title={t("assets.document")} />
      <BreadCrumb links={nav} />
      <DocA />
    </>
  );
};

export default DocAssets;
