/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Intro, Controls, ControlGroup } from "../../containers";
import { Description, Title } from "components/typography";
import { Section, Wrapper } from "../styles";
import { fetchImageAssets } from "redux/actions/mktassets";
import AffiliateData from "utils/user-data";
import { ExportButton } from "components/buttons";
import ImageAssetsTable from "components/tables/image-assets";
import MainSelect from "components/main-select";

const ImageA = () => {
  const dispatch = useDispatch();
  const loggedInAff = new AffiliateData();
  const { campaigns } = useSelector((state) => state.aff_campaigns);

  const options =
    campaigns === null
      ? []
      : campaigns.map((el) => ({ label: el.name, value: el.id }));

  useEffect(() => {
    dispatch(
      fetchImageAssets(
        {
          domain: loggedInAff.get().data.affiliate.domain,
          affId: loggedInAff.get().data.affiliate.id,
        },
        {}
      )
    );
  }, []);

  return (
    <Section>
      <Intro>
        <Title>image assets</Title>
        <Description>
          These images have been provided to aid your content marketing. You can
          share them on your blog or website
        </Description>
      </Intro>
      <Wrapper>
        <Controls
          style={{
            padding: "1em",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
        >
          {/* <ExportButton caption="Export Report" /> */}
          <ControlGroup>
            <MainSelect options={options} label={null} />
          </ControlGroup>
        </Controls>
        <ImageAssetsTable />
      </Wrapper>
    </Section>
  );
};

export default ImageA;
