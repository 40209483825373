import React, { useRef, useState } from "react";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { Component, Input, Label, InputIcon } from "./style";

const InputComponent = ({
  required,
  label,
  placeholder,
  onChange,
  onInput,
  onClick,
  type,
  dropdown,
  outline,
  color,
  name,
  id,
  nested,
  error,
  defaultValue,
  ...props
}) => {
  const [passwordState, setPasswordState] = useState(true);
  const ref = useRef(null);
  const togglePassword = () => {
    const type =
      ref.current.getAttribute("type") === "password" ? "text" : "password";
    ref.current.setAttribute("type", type);
    setPasswordState(!passwordState);
  };
  return (
    <Component>
      <Label outline={outline}>
        {label} {required && "*"}
      </Label>
      <Input
        outline={outline}
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        onInput={onInput}
        {...props}
        style={{
          border: nested
            ? "2px solid #c1c0ff"
            : `2px solid ${error ? "#ff0000" : "#c1c0ff"}`,
        }}
        id={id}
        name={name}
        ref={ref}
        defaultValue={defaultValue}
      />
      {type === "password" ? (
        <InputIcon outline={outline} onClick={togglePassword}>
          {passwordState ? <VisibilityOff /> : <Visibility />}
        </InputIcon>
      ) : null}
    </Component>
  );
};

export default InputComponent;
