/** @format */

import styled from "styled-components";

export const BubbleWrapper = styled.div`
  position: absolute;
  top: 10%;
  left: 10%;

  @media screen and (max-width: 1023px) {
    display: none;
  }
`;
