/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as FaIcons from "react-icons/fa";
import { Intro } from "components/containers";
import Filter from "components/filters/campaigns";
import AdvertiserCampaignsTable from "components/tables/campaigns/advertiser-campaigns";
import { Description, Title } from "components/typography";
import { FilterActions, FilterTitle, Section, Wrapper } from "../styles";
import { fetchCampaigns } from "redux/actions/campaigns";
import AffiliateData from "utils/user-data";
import { useTranslation } from "react-i18next";

const AdvertiserCampaign = () => {
  const {t, i18n} = useTranslation('common');
  const [showFilter, setShowFilter] = useState(false);
  const dispatch = useDispatch();

  const hideFilter = () => setShowFilter(false);

  const affData = new AffiliateData().get();

  const data = {
    domain: affData.data.affiliate.domain,
    affiliateId: affData.data.affiliate.id,
  };
  useEffect(() => {
    dispatch(fetchCampaigns(data, {}));
  }, []);

  return (
    <Section>
      <Intro>
        <Title>{t("campaign.join")}</Title>
        <Description>{t("campaign.description")}</Description>
      </Intro>
      <Wrapper>
        <FilterActions>
          <FilterTitle onClick={() => setShowFilter(!showFilter)}>
          {t("campaign.filter")}
            <FaIcons.FaFilter
              style={{
                background: "#dbcee9",
                padding: 7,
                fontSize: 28,
              }}
            />
          </FilterTitle>
        </FilterActions>
        {showFilter && <Filter hide={hideFilter} />}
        <AdvertiserCampaignsTable />
      </Wrapper>
    </Section>
  );
};

export default AdvertiserCampaign;
