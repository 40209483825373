/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAffReports } from "redux/actions/dashboard";
import { campaignOtions, dateOtions } from "utils/select-data";
import { ExportButton } from "components/buttons";
import { CSVLink } from "react-csv";
import {
  Box,
  BoxContent,
  ControlGroup,
  Controls,
  Intro,
  Reports,
  ReportsContainer,
  Stats,
} from "components/containers";
import { CBIcon } from "components/icons/image-icons";
import { Description, SmallText, Title } from "components/typography";
import { IconWrapper, Section, SmallSelectInButton } from "../styles";
import AffiliateData from "utils/user-data";
import MainSelect from "components/main-select";

const Report = () => {
  const loggedInAff = new AffiliateData();

  const dispatch = useDispatch();
  const { loading, reports } = useSelector((state) => state.aff_reports);
  const { campaigns } = useSelector((state) => state.aff_campaigns);

  const options =
    campaigns === null
      ? []
      : campaigns.map((el) => ({ label: el.name, value: el.id }));

  const [campaign, setCampaign] = useState(campaignOtions[1]);
  const [date, setDate] = useState(dateOtions[2]);

  const [startDate, setStartDate] = useState(
    new Date().setDate(new Date().getDate() - 10)
  );

  const handleDaySelect = (days) => {
    console.log(parseInt(days));
    const event = new Date();
    event.setDate(new Date().getDate() - parseInt(days));
    // console.log(startDateGenerator);
    console.log(event);
    setStartDate(event);
  };

  useEffect(() => {
    dispatch(
      fetchAffReports(
        {
          domain: loggedInAff.get().data.affiliate.domain,
          affId: loggedInAff.get().data.affiliate.id,
        },
        {
          campId: loggedInAff.get().data.campaign.id,
        }
      )
    );
  }, []);

  const csvData = [
    ["AFFILIATE URL", reports && reports.affiliateURL],
    ["APPROVED COMMISSION", reports && reports.approvedCommissions],
    ["CONVERSIONS", reports && reports.conversions],
    ["DECLINED COMMISSION", reports && reports.declinedCommissions],
    ["OVERIDE COMMISSION", reports && reports.overrideCommissions],
    ["PAID COMMISSION", reports && reports.paidCommissions],
    ["PENDING COMMISSION", reports && reports.pendingCommissions],
    // [
    //   "TRAFFIC CONVERSION",
    //   reports && reports.trafficConversion.length < 1
    //     ? 0
    //     : reports.trafficConversion.map((conv) => ` ${conv}`),
    // ],
    // [
    //   "TRAFFIC LEADS",
    //   reports && reports.trafficLeads.length < 1
    //     ? 0
    //     : reports.trafficLeads.map((lead) => ` ${lead}`),
    // ],
  ];

  return (
    <Section>
      <Intro>
        <Title>Overview</Title>
        <Description>Find important stats on your performance </Description>
      </Intro>
      <Reports>
        <Controls>
          <CSVLink
            data={csvData}
            filename={"General report.csv"}
            style={{ textDecoration: "none" }}
          >
            <ExportButton caption="Export Report" />
          </CSVLink>

          <ControlGroup>
            <MainSelect
              label={null}
              options={options}
              placeholder="Select Campaign"
            />
            <MainSelect
              options={dateOtions}
              label={null}
              placeholder="Select Date"
            />
          </ControlGroup>
        </Controls>
        <ReportsContainer>
          <Stats>
            <IconWrapper>
              <CBIcon />
            </IconWrapper>
            <div>
              <SmallText>
                {reports ? (
                  "Conversions"
                ) : (
                  <Skeleton variant="text" width={150} />
                )}
              </SmallText>
              <Title>
                {reports ? reports.conversions : <Skeleton width={150} />}
              </Title>
            </div>
          </Stats>
          <Stats>
            <IconWrapper>
              <CBIcon />
            </IconWrapper>
            <div>
              <SmallText>
                {reports ? (
                  "Paid commissions"
                ) : (
                  <Skeleton variant="text" width={150} />
                )}
              </SmallText>
              <Title>
                {reports ? reports.paidCommissions : <Skeleton width={150} />}
              </Title>
            </div>
          </Stats>
          <Stats>
            <IconWrapper>
              <CBIcon />
            </IconWrapper>
            <div>
              <SmallText>
                {reports ? (
                  "Pending commissions"
                ) : (
                  <Skeleton variant="text" width={150} />
                )}
              </SmallText>
              <Title>
                {reports ? (
                  reports.pendingCommissions
                ) : (
                  <Skeleton width={150} />
                )}
              </Title>
            </div>
          </Stats>

          <Stats>
            <IconWrapper>
              <CBIcon />
            </IconWrapper>
            <div>
              <SmallText>
                {reports ? (
                  "Overridden commissions"
                ) : (
                  <Skeleton variant="text" width={150} />
                )}
              </SmallText>
              <Title>
                {reports ? (
                  reports.overrideCommissions
                ) : (
                  <Skeleton width={150} />
                )}
              </Title>
            </div>
          </Stats>

          <Stats>
            <IconWrapper>
              <CBIcon />
            </IconWrapper>
            <div>
              <SmallText>
                {reports ? (
                  "Approved commissions"
                ) : (
                  <Skeleton variant="text" width={150} />
                )}
              </SmallText>
              <Title>
                {reports ? (
                  reports.approvedCommissions
                ) : (
                  <Skeleton width={150} />
                )}
              </Title>
            </div>
          </Stats>

          <Stats>
            <IconWrapper>
              <CBIcon />
            </IconWrapper>
            <div>
              <SmallText>
                {reports ? (
                  "Declined commissions"
                ) : (
                  <Skeleton variant="text" width={150} />
                )}
              </SmallText>
              <Title>
                {reports ? (
                  reports.declinedCommissions
                ) : (
                  <Skeleton width={150} />
                )}
              </Title>
            </div>
          </Stats>
        </ReportsContainer>
      </Reports>
    </Section>
  );
};

export default Report;
