/* eslint-disable jsx-a11y/alt-text */
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { ImageCardWrapper } from "./card.styles";
import { File } from "./svgs";
import DefaultImage from "assets/images/default.png";

export const ImageCard = () => {
  return (
    <ImageCardWrapper>
      <div style={{ height: 270, overflowX: "hidden" }}>
        <img src={DefaultImage} />
      </div>
      <div>
        <File />
        <small>lorem ipsum</small>
        <MoreHorizIcon style={{ cursor: "pointer" }} />
      </div>
    </ImageCardWrapper>
  );
};
