import React from "react";
import { useTranslation } from "react-i18next";
import BreadCrumb from "components/breadcrumb";
import MainHeader from "components/header";
import Report from "components/main/report";

const Reports = () => {
  const {t, i18n} = useTranslation('common');
  const nav = [
    {
      url: "/affiliate/overview",
      label: t("general.dashboard"),
      main: true,
    },
    {
      url: "/affiliate/reports",
      label: t("report.title"),
    },
  ];
  return (
    <>
      <MainHeader title={t("report.title")} />
      <BreadCrumb links={nav} />
      <Report />
    </>
  );
};

export default Reports;
