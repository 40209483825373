import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  PromptBox,
  PromptHeader,
  PromptContent,
  Warning,
  PromptActions,
  FilledButton,
  BorderedButton,
} from "./styles";
import { logout } from "redux/actions/authState";

export const LogoutPrompt = ({ onClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(logout(history));
  };

  return (
    <>
      <PromptBox>
        <PromptHeader>Log Out?</PromptHeader>
        <PromptContent>
          <Warning>Are you sure you want to log out ?</Warning>
          <PromptActions>
            <FilledButton onClick={handleLogout}>YES LOGOUT</FilledButton>
            <BorderedButton onClick={onClose}>NO STAY</BorderedButton>
          </PromptActions>
        </PromptContent>
      </PromptBox>
    </>
  );
};
