import Facebook from "assets/icons/facebook.png";
import Instagram from "assets/icons/instagram.png";
import Twitter from "assets/icons/twitter.png";
import Gmail from "assets/icons/gmail.png";
import Cashback from "assets/icons/cashback.png";

export const FBIcon = () => {
  return <img src={Facebook} alt="Facebook" height={25} />;
};

export const IGIcon = () => {
  return <img src={Instagram} alt="Instagram" height={25} />;
};

export const TWIcon = () => {
  return <img src={Twitter} alt="Twitter" height={25} />;
};

export const GMIcon = () => {
  return <img src={Gmail} alt="Gmail" height={25} />;
};

export const CBIcon = () => {
  return <img src={Cashback} alt="Cashback" height={25} />;
};
