import styled from "styled-components";

export const TableWrapper = styled.div`
  background: ${({ theme }) => theme.tableBg};
  margin-top: 1em;
  padding-bottom: 2em;
  position: relative;
`;

export const MainTable = styled.table`
  text-align: left;
  border-collapse: collapse;
  border: none;
  background: ${({ theme }) => theme.tableBg};
  width: 100%;

  td,
  th {
    border: none;
    padding: 15px;
  }

  tr {
    border-bottom: ${({ theme }) => theme.mainBorder};
    background: ${({ theme }) => theme.tableBg};
  }

  tr:hover {
    background-color: ${({ theme }) => theme.trHoverColor};
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background: ${({ theme }) => theme.primaryColor};
    color: ${({ theme }) => theme.mainText};
    border: none;
    font-size: 14px;
    text-transform: capitalize;
  }
`;
