import {
  SIGN_UP_STEP,
  SIGN_UP_AFF,
  SETUP_PROFILE_THREE,
  SETUP_PROFILE_TWO,
  COMPLETE_SETUP,
} from "redux/types";

const INITIAL_STATE = {
  step: 1,
  loading: false,
  profile: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_UP_STEP.INCREASE: {
      return { ...state, step: state.step + 1 };
    }
    case SIGN_UP_STEP.DECREASE: {
      return { ...state, step: state.step - 1 };
    }
    case SIGN_UP_AFF.PENDING: {
      return { ...state, loading: true };
    }
    case SIGN_UP_AFF.SUCCESS: {
      return { ...state, loading: false };
    }
    case SIGN_UP_AFF.ERROR: {
      return { ...state, loading: false };
    }
    case SETUP_PROFILE_TWO:
      return {
        ...state,
        profile: { ...state.profile, ...action.payload },
      };
    case SETUP_PROFILE_THREE:
      return {
        ...state,
        profile: { ...state.profile, ...action.payload },
      };
    case COMPLETE_SETUP.PENDING: {
      return { ...state, loading: true };
    }
    case COMPLETE_SETUP.SUCCESS: {
      return { ...state, loading: false };
    }
    case COMPLETE_SETUP.ERROR: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
};

export default reducer;
