import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Input from "../component";
import { BoxTitle, Small, FormGroup, Box, Button, Main } from "../styles";
import { completeStepTwo } from "redux/actions/auth/auth";

const Step2 = () => {
  const {t, i18n} = useTranslation('common');
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.aff_signup);
  const [form, setForm] = useState({
    username: "",
    address: "",
    postal_code: "",
    country: "",
  });

  function handleChange(evt) {
    const value = evt.target.value;
    setForm({
      ...form,
      [evt.target.name]: value,
    });
  }

  function onSubmit() {
    dispatch(completeStepTwo(form));
  }

  const temp = JSON.parse(localStorage.getItem("template"));

  return (
    <Main style={{ minHeight: "170vh" }}>
      <Box>
        <BoxTitle>{temp.data.templates.campaign_title}</BoxTitle>

        {/* <button className="back" onClick={() => dispatch(decreaseStep())}>
          Back
        </button> */}

        <Small
          style={{
            fontWeight: "bold",
          }}
        >
          {t("auth.step2")}
        </Small>
        <FormGroup>
          <Input
            defaultValue={profile?.username}
            type="text"
            label={t("auth.username")}
            name="username"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Input
            defaultValue={profile?.address}
            type="text"
            label={t("auth.address")}
            name="address"
            onChange={handleChange}
          />
        </FormGroup>

        <FormGroup>
          <Input
            defaultValue={profile?.postal_code}
            type="text"
            label={t("auth.pCode")}
            name="postal_code"
            onChange={handleChange}
          />
        </FormGroup>

        <FormGroup>
          <Input
            defaultValue={profile?.country}
            type="text"
            label={t("auth.country")}
            name="country"
            onChange={handleChange}
          />
        </FormGroup>

        <FormGroup>
          <Button onClick={onSubmit}>{t("auth.next")}</Button>
        </FormGroup>
      </Box>
    </Main>
  );
};

export default Step2;
