import styled from "styled-components";
import { Small } from "globals/styles";

export const FilterActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  padding: 0 2em;
`;

export const FilterTitle = styled.h3`
  display: flex;
  gap: 1em;
`;

export const Box = styled.div`
  background: #fff;
  border-radius: 15px;
  padding: 2em 4em;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.5em;

  & .stat {
    background: #fff;
    border-radius: 10px;
    display: flex;
    padding: 1em 0;
    align-items: center;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  }
`;

export const FormBox = styled.div`
  position: relative;
  padding: 2em 3em;
  background: #fff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-bottom: 2.5em;
  height: 550px;
`;

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5%;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 0.5em 0;
`;

export const Label = styled.label`
  color: #19073b;
  font-size: ${Small};
`;

export const Input = styled.input`
  height: 45px;
  background: #f1edf6;
  border: 1px solid #7211d4;
  outline-color: #7211d4;
  padding: 0 2em;
  border-radius: 15px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

/* Firefox */
