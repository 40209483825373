import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
import AuthLayout from "components/auth/layout";
import SignUp from "components/auth/signup";
import SignIn from "components/auth/sigin";
import ForgotPassword from "components/auth/forgot-password";
import ProceedRequest from "components/auth/forgot-password/proceed";
import ResetPassword from "components/auth/reset";

const AffiliateAuthWrapper = () => {
  const { path } = useRouteMatch();

  return (
    <AuthLayout>
      <Route path={`${path}/login/`}>
        <SignIn />
      </Route>
      <Route path={`${path}/signup/`}>
        <SignUp />
      </Route>
      <Route path={`${path}/forgot-password/`}>
        <ForgotPassword />
      </Route>
      <Route path={`${path}/proceed-reset/`}>
        <ProceedRequest />
      </Route>
      <Route path={`${path}/reset-password/`}>
        <ResetPassword />
      </Route>
    </AuthLayout>
  );
};

export default AffiliateAuthWrapper;
