export const defaultMode = {
  sidebarBg: "#7211D4",
  primaryColor: "#7211d4",
  secondaryBg: "#fff",
  mainBorder: "2.22292px solid #E0E2EA;",
  navActive: "#D4B8F1",
  mainText: "#19073b",
  secondaryText: "#44547C",
  navbarBg: "#fff",
  searchBox: "#F3F3F9",
  iconBg: "#c1c0ff",
  nameText: "#7211d4",
  breadCrumbBg: "#f3f3f9",
  cardBg: "#fff",
  cardShadow: "0px 2px 20px rgba(180, 175, 186, 0.25)",
  sectionBg: "#fff",
  bordered: false,
  boxColor: "#44547C",
  tableBg: "#fafafb",
  selectColor: "#19073b",
  trHoverColor: "#ddd",
};

export const darkMode = {
  sidebarBg: "#171819",
  primaryColor: "#CC68AC",
  secondaryBg: "#171819",
  mainBorder: "0.5px solid rgba(255,255,255,0.3)",
  navActive: "rgba(204, 104, 172, 0.2)",
  mainText: "#EBE8E9",
  secondaryText: "rgba(235, 232, 233, 0.6)",
  navbarBg: "#171819",
  searchBox: "#2B2C2E",
  cardBg: "#2B2C2E",
  cardShadow:
    " 0px 0px 200px rgba(0, 0, 0, 0.07), 0px 15.375px 81.6986px rgba(0, 0, 0, 0.0322476), 0px 6.38599px 33.9334px rgba(0, 0, 0, 0.0193288), 0px 2.30969px 12.273px rgba(0, 0, 0, 0.0131277)",
  iconBg: "#383C44",
  nameText: "#fff",
  sectionBg: "#171819",
  breadCrumbBg: "#2B2C2E",
  bordered: true,
  boxColor: "#EBE8E9",
  tableBg: "#171819",
  selectColor: "#EBE8E9",
  trHoverColor: "rgba(43, 44, 46, 0.5)",
};

export const lightMode2 = {
  sidebarBg: "#CC68AC",
  primaryColor: "#CC68AC",
  secondaryBg: "#fff",
  mainBorder: "0.5px solid rgba(0,0,0,0.3)",
  navActive: "rgba(204, 104, 172, 0.2)",
  mainText: "#19073b",
  secondaryText: "rgba(235, 232, 233, 0.6)",
  navbarBg: "#fff",
  searchBox: "#f3f3f9",
  cardBg: "#fff",
  cardShadow:
    " 0px 0px 200px rgba(0, 0, 0, 0.07), 0px 15.375px 81.6986px rgba(0, 0, 0, 0.0322476), 0px 6.38599px 33.9334px rgba(0, 0, 0, 0.0193288), 0px 2.30969px 12.273px rgba(0, 0, 0, 0.0131277)",
  iconBg: "#ffe0f4",
  nameText: "#19073b",
  sectionBg: "#fff",
  breadCrumbBg: "#F3F3F9",
  bordered: false,
  boxColor: "#19073b",
  tableBg: "#fff",
  selectColor: "#19073b",
  trHoverColor: "#ffe0f4",
};
