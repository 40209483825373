import axios from "axios";
import {
  fetchSocialMA,
  fetchIA,
  fetchVA,
  fetchDA,
  fetchSingle,
} from "redux/apiRoutes";
import {
  FETCH_SOCIAL_ASSETS,
  FETCH_IMAGE_ASSETS,
  FETCH_VIDEO_ASSETS,
  FETCH_DOC_ASSETS,
  FETCH_SINGLE_ASSET,
} from "redux/types";
import AuthSession from "utils/session-storage";

export const fetchSocialMediaAssets = (data, filterData) => (dispatch) => {
  dispatch({ type: FETCH_SOCIAL_ASSETS.PENDING, payload: null });
  const authSessionToken = new AuthSession().get();
  const headers = {
    Authorization: `Bearer ${authSessionToken.token}`,
  };
  const fetchSearchParams = new URLSearchParams(filterData);
  axios
    .post(`${fetchSocialMA}?${fetchSearchParams.toString()}`, data, { headers })
    .then((response) => {
      if (response.data.success === false) {
        dispatch({ type: FETCH_SOCIAL_ASSETS.ERROR, payload: null });
      } else {
        dispatch({ type: FETCH_SOCIAL_ASSETS.SUCCESS, payload: response.data });
      }
    })
    .catch((err) => {
      dispatch({ type: FETCH_SOCIAL_ASSETS.ERROR, payload: null });
    });
};

export const fetchImageAssets = (data, filterData) => (dispatch) => {
  dispatch({ type: FETCH_IMAGE_ASSETS.PENDING, payload: null });
  const authSessionToken = new AuthSession().get();
  const headers = {
    Authorization: `Bearer ${authSessionToken.token}`,
  };
  const fetchSearchParams = new URLSearchParams(filterData);
  axios
    .post(`${fetchIA}?${fetchSearchParams.toString()}`, data, { headers })
    .then((response) => {
      if (response.data.success === false) {
        dispatch({ type: FETCH_IMAGE_ASSETS.ERROR, payload: null });
      } else {
        dispatch({ type: FETCH_IMAGE_ASSETS.SUCCESS, payload: response.data });
      }
    })
    .catch((err) => {
      dispatch({ type: FETCH_IMAGE_ASSETS.ERROR, payload: null });
    });
};

export const fetchVideoAssets = (data, filterData) => (dispatch) => {
  dispatch({ type: FETCH_VIDEO_ASSETS.PENDING, payload: null });
  const authSessionToken = new AuthSession().get();
  const headers = {
    Authorization: `Bearer ${authSessionToken.token}`,
  };
  const fetchSearchParams = new URLSearchParams(filterData);
  axios
    .post(`${fetchVA}?${fetchSearchParams.toString()}`, data, { headers })
    .then((response) => {
      if (response.data.success === false) {
        dispatch({ type: FETCH_VIDEO_ASSETS.ERROR, payload: null });
      } else {
        dispatch({ type: FETCH_VIDEO_ASSETS.SUCCESS, payload: response.data });
      }
    })
    .catch((err) => {
      dispatch({ type: FETCH_VIDEO_ASSETS.ERROR, payload: null });
    });
};

export const fetchDocAssets = (data, filterData) => (dispatch) => {
  dispatch({ type: FETCH_DOC_ASSETS.PENDING, payload: null });
  const authSessionToken = new AuthSession().get();
  const headers = {
    Authorization: `Bearer ${authSessionToken.token}`,
  };
  const fetchSearchParams = new URLSearchParams(filterData);
  axios
    .post(`${fetchDA}?${fetchSearchParams.toString()}`, data, { headers })
    .then((response) => {
      if (response.data.success === false) {
        dispatch({ type: FETCH_DOC_ASSETS.ERROR, payload: null });
      } else {
        dispatch({ type: FETCH_DOC_ASSETS.SUCCESS, payload: response.data });
      }
    })
    .catch((err) => {
      dispatch({ type: FETCH_DOC_ASSETS.ERROR, payload: null });
    });
};

export const fetchSingleAsset = (url, id, data) => (dispatch) => {
  dispatch({ type: FETCH_SINGLE_ASSET.PENDING, payload: null });
  const authSessionToken = new AuthSession().get();
  const headers = {
    Authorization: `Bearer ${authSessionToken.token}`,
  };
  axios
    .post(`${fetchSingle}/${url}/${id}}`, data, {
      headers,
    })
    .then((response) => {
      console.log(response.data);
      dispatch({ type: FETCH_SINGLE_ASSET.SUCCESS, payload: response.data });
    })
    .catch((err) => {
      dispatch({ type: FETCH_SINGLE_ASSET.ERROR, payload: null });
    });
};
