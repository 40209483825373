import React from "react";
import { NotificationItem, NotificationWrapper } from "components/containers";
import { Message, NotificationTitle, SmallText } from "components/typography";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Bluedot, TextBlock } from "../styles";

const NotificationModal = () => {
  return (
    <NotificationWrapper>
      <NotificationTitle>Your Notifications</NotificationTitle>
      {[].map((el, i) => (
        <NotificationItem key={i}>
          <div style={{ alignSelf: "center" }}>
            <Bluedot />
          </div>
          <TextBlock>
            <Message>
              {/* Lorem, ipsum dolor sit amet consectetur adipisicing elit, aborum
              aut expedita. */}
            </Message>
            {/* <SmallText>now</SmallText> */}
          </TextBlock>
          <MoreHorizIcon
            sx={{
              cursor: "pointer",
            }}
          />
        </NotificationItem>
      ))}
    </NotificationWrapper>
  );
};

export default NotificationModal;
