/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExportButton } from "components/buttons";
import { ControlGroup, Intro } from "components/containers";
import CommissionsFilterer from "components/filters/commissions";
import CommissionsTable from "components/tables/commissions";
import { Description, Title } from "components/typography";
import { FilterActions, FilterTitle, Section, Wrapper } from "../styles";
import { fetchAffiliateCommissions } from "redux/actions/commissions";
import AffiliateData from "utils/user-data";
import MainSelect from "components/main-select";
import { CSVLink } from "react-csv";

const Commission = () => {
  const dispatch = useDispatch();
  const loggedInAff = new AffiliateData();
  const [showFilter, setShowFilter] = useState(false);

  const { campaigns } = useSelector((state) => state.aff_campaigns);

  const options =
    campaigns === null
      ? []
      : campaigns.map((el) => ({ label: el.name, value: el.id }));

  useEffect(() => {
    dispatch(
      fetchAffiliateCommissions(
        {
          domain: loggedInAff.get().data.affiliate.domain,
          affId: loggedInAff.get().data.affiliate.id,
        },
        {}
      )
    );
  }, []);

  const [csvData, setCsvData] = useState([]);
  const getData = (data) => {
    setCsvData(data);
  };

  return (
    <Section>
      <Intro>
        <Title>Overview,</Title>
        <Description>Find useful details of all your commissions.</Description>
      </Intro>
      <Wrapper>
        <FilterActions>
          <FilterTitle onClick={() => null}>
            {/* Filter
            <FaIcons.FaFilter
              style={{
                background: "#dbcee9",
                padding: 7,
                fontSize: 28,
              }}
            /> */}
          </FilterTitle>
          <ControlGroup>
            <MainSelect label={null} options={options} />

            <CSVLink
              data={csvData}
              filename={"Commissions report.csv"}
              style={{ textDecoration: "none" }}
            >
              <ExportButton caption="Export Report" />
            </CSVLink>
          </ControlGroup>
        </FilterActions>
        {showFilter && <CommissionsFilterer />}
        <CommissionsTable getData={getData} />
      </Wrapper>
    </Section>
  );
};

export default Commission;
