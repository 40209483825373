/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./styles.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ArrowDown } from "../icons";

const PayoutsFilterer = () => {
  const [day, setDay] = useState(null);
  const [limit, setLimit] = useState(null);
  const [showDayFilter, setShowDayFilter] = useState(false);
  const [showLimitFilter, setShowLimitFilter] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isNumberDay, setIsNumberDay] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const handleDatePicked = (date, start) => {
    console.log(date);
    start ? setStartDate(date) : setEndDate(date);
  };
  const handleDaySelect = (days) => {
    setDay(days);
    console.log(parseInt(days));
    const event = new Date();
    event.setDate(new Date().getDate() - parseInt(days));
    console.log(event);
    setStartDate(event);
  };
  return (
    <div
      className="Conversions
    filterer"
    >
      <div className="col widerCol">
        <div className="box">
          <div
            className="selectBox"
            onClick={() => setShowDayFilter(!showDayFilter)}
          >
            {day ? (isNumberDay ? `Last ${day} day(s)` : day) : "Select Date"}
            <ArrowDown />
          </div>
          {showDayFilter && (
            <>
              <div className="check">
                <div>
                  <label class="container">
                    <input
                      type="radio"
                      name="day"
                      value={1}
                      onChange={(e) => handleDaySelect(e.target.value)}
                    />
                    last day
                    <span class="radiobutt"></span>
                  </label>
                </div>
                <div>
                  <label class="container">
                    {" "}
                    <input
                      type="radio"
                      name="day"
                      value={15}
                      onChange={(e) => handleDaySelect(e.target.value)}
                    />
                    last 15 days
                    <span class="radiobutt"></span>
                  </label>
                </div>
                <div>
                  <label class="container">
                    {" "}
                    <input
                      type="radio"
                      name="day"
                      value={30}
                      onChange={(e) => handleDaySelect(e.target.value)}
                    />
                    last 30 days
                    <span class="radiobutt"></span>
                  </label>
                </div>
                <div>
                  <label class="container">
                    <input
                      type="radio"
                      name="day"
                      value={90}
                      onChange={(e) => handleDaySelect(e.target.value)}
                    />
                    last quarter
                    <span class="radiobutt"></span>
                  </label>
                </div>
                <div>
                  <label class="container">
                    last year
                    <input
                      type="radio"
                      name="day"
                      value={365}
                      onChange={(e) => handleDaySelect(e.target.value)}
                    />
                    <span class="radiobutt"></span>
                  </label>
                </div>
                <hr />
                <button
                  onClick={() => {
                    setShowDatePicker(!showDatePicker);
                    setIsNumberDay(false);
                    setDay("Custom Date");
                  }}
                >
                  Custom Date
                </button>
              </div>
              {showDatePicker && (
                <div className="customDates">
                  <div>
                    <small style={{ margin: "0px 10px" }}>Start Date</small>

                    <DatePicker
                      className="dateInput"
                      selected={startDate}
                      onChange={(date) => handleDatePicked(date, true)}
                    />
                  </div>

                  <div>
                    <small style={{ margin: "0px 10px" }}>End Date</small>

                    <DatePicker
                      className="dateInput"
                      selected={startDate}
                      onChange={(date) => handleDatePicked(date, false)}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="box">
          <div
            className="selectBox"
            onClick={() => setShowLimitFilter(!showLimitFilter)}
          >
            {limit ? limit : "Sorts Per Page"}
            <ArrowDown />
          </div>
          {showLimitFilter && (
            <div className="check">
              <div>
                <label class="container">
                  10
                  <input
                    type="radio"
                    name="limit"
                    value={10}
                    onChange={(e) => setLimit(e.target.value)}
                  />
                  <span class="radiobutt"></span>
                </label>
              </div>
              <div>
                <label class="container">
                  50
                  <input
                    type="radio"
                    name="limit"
                    value={50}
                    onChange={(e) => setLimit(e.target.value)}
                  />
                  <span class="radiobutt"></span>
                </label>
              </div>
              <div>
                <label class="container">
                  100
                  <input
                    type="radio"
                    name="limit"
                    value={100}
                    onChange={(e) => setLimit(e.target.value)}
                  />
                  <span class="radiobutt"></span>
                </label>
              </div>
              <div>
                <label class="container">
                  200
                  <input
                    type="radio"
                    name="limit"
                    value={200}
                    onChange={(e) => setLimit(e.target.value)}
                  />
                  <span class="radiobutt"></span>
                </label>
              </div>
              <div>
                <label class="container">
                  500
                  <input
                    type="radio"
                    name="limit"
                    value={500}
                    onChange={(e) => setLimit(e.target.value)}
                  />
                  <span class="radiobutt"></span>
                </label>
              </div>
            </div>
          )}
        </div>
      </div>

      <hr style={{ border: "solid 0.5px #a7a7a7", opacity: 0.3 }} />

      <div className="btns">
        <button className="cancel">Cancel</button>
        <button className="apply">Apply Filter</button>
      </div>
    </div>
  );
};

export default PayoutsFilterer;
