import styled from "styled-components";

export const BreadCrumbWrapper = styled.ul`
  background: #2b2c2e;
  padding: 10px 2em;
  list-style: none;
  background-color: ${({ theme }) => theme.breadCrumbBg};

  a {
    text-decoration: none;
  }

  .fade a {
    color: rgba(33, 62, 113, 0.6);
  }
`;
