import styled from "styled-components";

export const PromptBox = styled.div`
  background: #fff;
  border-radius: 20px;
  position: fixed;
  width: 30%;
  top: 20%;
  left: 35%;
  z-index: 2000;
  // transform: translate(-50%, -50%);
`;

export const PromptHeader = styled.h1`
  color: #fff;
  background: ${({ theme }) => theme.primaryColor};
  border-radius: 20px 20px 0px 0px;
  padding: 1em;
`;

export const Warning = styled.p`
  background: ${({ theme }) => theme.iconBg};
  color: #213e71;
  padding: 2em;
`;

export const PromptContent = styled.div`
  padding: 2em;
`;

export const PromptActions = styled.div`
  display: flex;
  justify-content: center;
  gap: 2em;
  padding: 2em;
`;

export const FilledButton = styled.div`
  width: 192px;
  height: 50px;
  background: ${({ theme }) => theme.primaryColor};
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items center;
  color: #fff;
  font-weight: bold;
`;

export const BorderedButton = styled.div`
  width: 192px;
  cursor: pointer;
  height: 50px;
  border: solid 1px #213e71;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items center;
  font-weight: bold;
`;

export const OverLay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: transparent;
`;
