export const payoutsHistory = [
  {
    id: 1,
    name: "metrics",
    date: "21/03/2021",
    status: "approved",
    conAmo: "10000",
    balance: "12000",
  },
  {
    id: 2,
    name: "metrics",
    date: "21/03/2021",
    status: "declined",
    conAmo: "10000",
    balance: "12000",
  },
  {
    id: 3,
    name: "metrics",
    date: "21/03/2021",
    status: "approved",
    conAmo: "10000",
    balance: "12000",
  },
  {
    id: 4,
    name: "metrics",
    date: "21/03/2021",
    status: "pending",
    conAmo: "10000",
    balance: "12000",
  },
  {
    id: 5,
    name: "metrics",
    date: "21/03/2021",
    status: "approved",
    conAmo: "10000",
    balance: "12000",
  },
];
