import { useSelector } from "react-redux";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";

const SignUp = () => {
  const { step } = useSelector((state) => state.aff_signup);

  switch (step) {
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    default:
      return <Step1 />;
  }
};

export default SignUp;
