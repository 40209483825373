/** @format */

import axios from "axios";
import { fetchAffiliateReports, fetchAffiliateDetails } from "redux/apiRoutes";
import { FETCH_REPORTS, FETCH_DETAILS } from "redux/types";

import AuthSession from "utils/session-storage";

export const fetchAffReports = (data, filterData) => (dispatch) => {
  dispatch({ type: FETCH_REPORTS.PENDING, payload: null });
  const authSessionToken = new AuthSession().get();
  const headers = {
    Authorization: `Bearer ${authSessionToken.token}`,
  };
  const fetchSearchParams = new URLSearchParams(filterData);
  axios
    .post(`${fetchAffiliateReports}?${fetchSearchParams.toString()}`, data, {
      headers,
    })
    .then((response) => {
      if (response.data.success === false) {
        dispatch({ type: FETCH_REPORTS.ERROR, payload: null });
      } else {
        dispatch({ type: FETCH_REPORTS.SUCCESS, payload: response.data });
      }
    })
    .catch((err) => {
      dispatch({ type: FETCH_REPORTS.ERROR, payload: null });
    });
};

export const fetchAffDetails = (payload) => (dispatch) => {
  dispatch({ type: FETCH_DETAILS.PENDING, payload: null });
  const authSessionToken = new AuthSession().get();
  const headers = {
    Authorization: `Bearer ${authSessionToken.token}`,
  };
  axios
    .post(fetchAffiliateDetails, payload, {
      headers,
    })
    .then((response) => {
      if (response.data.success === false) {
        dispatch({ type: FETCH_DETAILS.ERROR, payload: null });
      } else {
        dispatch({
          type: FETCH_DETAILS.SUCCESS,
          payload: response.data,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: FETCH_DETAILS.ERROR, payload: null });
    });
};
