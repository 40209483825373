import React from "react";
import "../table.scss";
import { Skeleton } from "@mui/material";
import { payoutsHistory } from "utils/payouts-history";
import NoData from "components/nodata";
import { formatDate } from "utils/date-formatters";
import { MainTable, TableWrapper } from "../styles";

const PayoutsHistoryTable = () => {
  const loading = false;
  const renderPayoutsData = () => {
    return loading ? (
      <>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
      </>
    ) : (
      payoutsHistory.map((payout) => {
        const { id, name, date, status, conAmo, balance } = payout;
        return (
          <tr key={id}>
            <td>
              <label class="customContainer">
                <input type="checkbox" />
                <span class="customCheckmark"></span>
              </label>
            </td>
            <td>{name}</td>
            <td>{formatDate(new Date(date), "dd, mm, yyyy")}</td>
            <td
              style={{
                color:
                  status === "approved"
                    ? "#5cbb5c"
                    : status === "pending"
                    ? "#ddb13f"
                    : "#ed5565",
              }}
            >
              {status}
            </td>
            <td>{conAmo}</td>
            <td>{balance}</td>
          </tr>
        );
      })
    );
  };

  const renderPayoutsHeader = () => {
    let header = [
      "",
      "campaign",
      "date",
      "status",
      "conversion amount",
      "balance",
    ];
    return header.map((key, index) => {
      return <th key={index}>{key}</th>;
    });
  };

  return (
    <>
      {payoutsHistory.length === 0 && !loading ? (
        <NoData />
      ) : (
        <TableWrapper>
          <MainTable>
            <tbody>
              <tr
                style={{
                  width: "100%",
                }}
              >
                {renderPayoutsHeader()}
              </tr>
              {renderPayoutsData()}
            </tbody>
          </MainTable>
        </TableWrapper>
      )}
    </>
  );
};

export default PayoutsHistoryTable;
