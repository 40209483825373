import React from "react";
import styled from "styled-components";
import { Heading } from "globals/styles";

export const HeaderTitle = ({ title }) => {
  return <Title>{title}</Title>;
};

export const Title = styled.span`
  font-size: ${Heading};
  font-weight: bold;
  color: #19073b;
  text-transform: capitalize;
  padding-left: 20px;
`;
