import styled from "styled-components";

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

export const ImageCardWrapper = styled.div`
  border-radius: 0px 0px 15px 15px;
  height: 340px;
  width: 315px;
  background: #fefefe;
  border: 0.5px solid #d1c4c4;
  border-radius: 15px;

  & div:nth-child(even) {
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
