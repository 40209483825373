import React from "react";
import { useTranslation } from "react-i18next";
import BreadCrumb from "components/breadcrumb";
import MainHeader from "components/header";
import ImageAssetsView from "components/main/marketing-assets/image-assets-view";

const ImageAssetsViews = (props) => {
  const {t, i18n} = useTranslation('common');
  const { id } = props.match.params;
  const nav = [
    {
      url: "/affiliate/overview",
      label: t("general.dashboard"),
    },
    {
      url: "/affiliate/marketing-assets/image-assets",
      label: t("assets.image"),
    },
    {
      url: `/affiliate/marketing-assets/image-assets/download/${id}`,
      label: t("assets.download"),
      main: true,
    },
  ];
  return (
    <>
      <MainHeader title={t("assets.image")} />
      <BreadCrumb links={nav} />
      <ImageAssetsView assetId={id} />
    </>
  );
};

export default ImageAssetsViews;
