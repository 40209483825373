import styled from "styled-components";

export const Section = styled.div`
  background: ${({ theme }) => theme.secondaryColor};
  padding: 0 2em;
`;

export const Flex = styled.div`
  display: flex;
  width: 400px;
  align-items: center;
  padding: 1em;
  padding-right: 8em;
  background: #fff;
  margin: 0.3em 0;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 51.07px;
  height: 52.07px;
  background: ${({ theme }) => theme.primaryColor};
  border-radius: 8.26487px;
`;

export const Input = styled.input`
  width: 75%;
  height: 55px;
  background: ${({ theme }) => theme.searchBox};
  border: 1px solid ${({ theme }) => theme.primaryColor};
  border-radius: 20px;
  padding: 0 2em;
  outline-color: ${({ theme }) => theme.primaryColor};
`;

export const Bluedot = styled.div`
  width: 10px;
  height: 10px;
  background: #7211d4;
  border-radius: 50%;
`;

export const TextBlock = styled.div`
  display: block;
  line-height: 1.2;
`;

export const FilterActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  background-color: ${({ theme }) => theme.tableBg};
`;

export const FilterTitle = styled.h3`
  display: flex;
  gap: 1em;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.tableBg};
`;

export const BoxDivHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${({ theme }) => theme.sectionBg};
  font-size: 1.25rem;
  font-weight: 600;
  padding: 1em 2em;
  margin-bottom: 0.2em;
  color: ${({ theme }) => theme.mainText};

  .head {
    color: ${({ theme }) => theme.boxColor};
  }
`;

export const GraphTab = styled.div`
  color: ${(props) =>
    props.active ? props.theme.boxColor : props.theme.boxColor};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  cursor: pointer;
  span {
    position: relative;
    bottom: 0%;
    display: ${(props) => (props.active ? "block" : "hidden")};
    background-color: ${(props) => props.theme.primaryColor};
    width: 70px;
    height: 5px;
  }
`;

export const SelectInButton = styled.select`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  min-width: 170px;
  background: transparent;
  border: none;
  box-sizing: border-box;
  filter: drop-shadow(0px 10px 20px rgba(114, 17, 212, 0.25));
  border-radius: 8px;
  padding: 0 1em;
  outline: none;
`;

export const SmallSelectInButton = styled.select`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  min-width: 100px;
  background: transparent;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 1em;
  outline: none;
`;

export const Content = styled.div`
  background: ${({ theme }) => theme.sectionBg};
  padding: 2em;
  color: ${({ theme }) => theme.mainText}; ;
`;
