import { LOGIN_AFF, SIGN_OUT_AFF } from "redux/types";
import AffiliateData from "utils/user-data";
import AffiliateAuthSession from "utils/session-storage";

const affData = new AffiliateData();
const session = new AffiliateAuthSession();

export const logout = (history) => (dispatch) => {
  session.clear();
  affData.clear();
  localStorage.removeItem('campaignUrl');
  dispatch({ type: SIGN_OUT_AFF, payload: null });
  history.push("/auth/login/");
};

export const login = () => (dispatch) => {
  dispatch({ type: LOGIN_AFF, payload: null });
};
