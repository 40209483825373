import styled from "styled-components";

export const Board = styled.div`
  background: #fafafb;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  padding: 3em 0;
`;

export const OuterDiv = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 1em;
  box-shadow: 0px 4px 60px rgba(180, 175, 186, 0.25);
`;
