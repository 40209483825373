/** @format */

import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AffiliateWrapper from "affiliates-wrapper";
import AffiliateAuthWrapper from "auth-wrapper";
import { ProtectedAffiliateDashboard, ProtectedAuth } from "protectedRoutes";
import LandingPage from "pages/landing";
import Toggler from "components/toggler";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "theme/global";
import { defaultMode, darkMode } from "theme";

function App() {
  const [theme, setTheme] = useState("dark");
  const toggleTheme = () => {
    switch (theme) {
      case "light":
        setTheme("dark");
        break;
      case "dark":
        setTheme("light");
        break;
      case "light02":
        setTheme("light02");
        break;
      default:
    }
  };
  return (
    <Router>
      <ThemeProvider theme={theme === "dark" ? defaultMode : darkMode}>
        <ToastContainer />
        <GlobalStyles />
        <Toggler changeTheme={toggleTheme} />
        <Switch>
          <ProtectedAffiliateDashboard
            path="/affiliate"
            component={AffiliateWrapper}
          />
          <ProtectedAuth path="/auth" component={AffiliateAuthWrapper} />
          <Route path="/" exact component={LandingPage} />
        </Switch>
      </ThemeProvider>
    </Router>
  );
}

export default App;
