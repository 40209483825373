import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { DateTime } from 'luxon';
import en from './locales/en/common.json';
import de from './locales/de/common.json';
import es from './locales/es/common.json';
import et from './locales/et/common.json';
import fr from './locales/fr/common.json';
import it from './locales/it/common.json';
import pl from './locales/pl/common.json';
import pt from './locales/pt/common.json';
import uk from './locales/uk/common.json';
import zh from './locales/zh/common.json';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
            common: en               // 'common' is our custom namespace
        },
      de: {
        common: de // 'common' is our custom namespace
      },
      es: {
        common: es // 'common' is our custom namespace
      },
      et: {
        common: et // 'common' is our custom namespace
      },
      pl: {
        common: pl // 'common' is our custom namespace
      },
      fr: {
        common: fr // 'common' is our custom namespace
      },
      it: {
        common: it // 'common' is our custom namespace
      },
      pt: {
        common: pt // 'common' is our custom namespace
      },
      uk: {
        common: uk // 'common' is our custom namespace
      },
      zh: {
        common: zh // 'common' is our custom namespace
      },
    }
  });

i18n.services.formatter.add('DATE_HUGE', (value, lng, options) => {
  return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime.DATE_HUGE)
});

export default i18n;