import React from "react";
import BreadCrumb from "components/breadcrumb";
import MainHeader from "components/header";
import AdvertiserCampaign from "components/main/campaign/advertiser-campaigns";
import { useTranslation } from "react-i18next";

const AdvertiserCampaigns = () => {
  const {t, i18n} = useTranslation('common');
  const nav = [
    {
      url: "/affiliate/overview",
      label: `${t("campaign.dashboard")}`,
    },
    {
      url: "/affiliate/campaigns/join-campaigns",
      label: `${t("campaign.join")}`,
      main: true,
    },
  ];
  return (
    <>
      <MainHeader title={t("campaign.join")} />
      <BreadCrumb links={nav} />
      <AdvertiserCampaign />
    </>
  );
};

export default AdvertiserCampaigns;
