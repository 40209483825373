/* eslint-disable no-unused-vars */
import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import "../table.scss";
import { MainTable, TableWrapper } from "../styles";
import { Skeleton } from "@mui/material";
import NoData from "components/nodata";

const VideoAssetsTable = () => {
  const history = useHistory();
  const { loading, videoAssets } = useSelector((state) => state.aff_assets);

  const renderImageAssetsData = () => {
    return loading ? (
      <>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
      </>
    ) : (
      videoAssets.map((asset) => {
        const { campaignId: id, assetId, campaignName, noOfAsset } = asset;
        return (
          <tr key={id}>
            <td>
              <label class="customContainer">
                <input type="checkbox" />
                <span class="customCheckmark"></span>
              </label>
            </td>
            <td>{campaignName}</td>
            <td>{assetId}</td>
            {/* <td>
                {loadingId === id? (
                  <Skeleton />
                ) : (
                  formatDate(new Date(createdAt), "dd, mm, yyyy")
                )}
              </td> */}
            <td>{noOfAsset}</td>
          </tr>
        );
      })
    );
  };

  const renderImageAssetsHeader = () => {
    let header = ["", "campaign", "asset ID", "number of assets"];
    return header.map((key, index) => {
      return <th key={index}>{key}</th>;
    });
  };

  return (
    <>
      {videoAssets.length === 0 && !loading ? (
        <NoData />
      ) : (
        <TableWrapper>
          <MainTable>
            <tbody>
              <tr
                style={{
                  width: "100%",
                }}
              >
                {renderImageAssetsHeader()}
              </tr>
              {renderImageAssetsData()}
            </tbody>
          </MainTable>
        </TableWrapper>
      )}
    </>
  );
};

export default VideoAssetsTable;
