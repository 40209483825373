import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.secondaryBg};
    color: ${({ theme }) => theme.mainText};
font-family: Poppins, sans-serif;
    transition: all 0.50s linear;
  }
`;
