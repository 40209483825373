import React from "react";
import { useDispatch } from "react-redux";
import "../table.scss";
import { joinCampaign } from "redux/actions/campaigns";
import AffiliateData from "utils/user-data";
import { useTranslation } from "react-i18next";

const Join = ({ status, changeStatus, id }) => {
  const {t, i18n} = useTranslation('common');
  const dispatch = useDispatch();
  const loggedInAff = new AffiliateData();
  console.log(id);

  const handleJoinCampaign = () => {
    console.log("DATA", {
      domain: loggedInAff.get().data.affiliate.domain,
      affId: loggedInAff.get().data.affiliate.id,
      campId: id,
    });
    dispatch(
      joinCampaign({
        domain: loggedInAff.get().data.affiliate.domain,
        affId: loggedInAff.get().data.affiliate.id,
        campId: id,
      })
    );
    changeStatus(false);
    setTimeout(() => window.location.reload(), 1000 / 2);
  };
  return (
    <>
      <div
        className="overlay"
        style={{
          display: status ? "" : "none",
        }}
        onClick={() => changeStatus(false)}
      ></div>
      <div
        className="join"
        style={{
          display: status ? "" : "none",
        }}
      >
        <p>{t("campaign.requestToJoin")}?</p>
        <div className="action">
          <button onClick={handleJoinCampaign} className="yes">
          {t("campaign.yes")}
          </button>
          <button className="no" onClick={() => changeStatus(false)}>
          {t("campaign.cancel")}
          </button>
        </div>
      </div>
    </>
  );
};

export default Join;
