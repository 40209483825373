import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import "../table.scss";
import { MainTable, TableWrapper } from "../styles";
import { Skeleton } from "@mui/material";
import NoData from "components/nodata";

const PayoutsTable = ({ getData }) => {
  const { loading, payouts } = useSelector((state) => state.aff_payouts);

  useEffect(() => {
    // campaignName,
    // totalCommission,
    // totalConversion,

    getData([
      [
        "CAMPAIGN NAME",
        "PAID COMMISSION",
        "UNPAID COMMISSION",
        "TOTAL CONVERSION",
      ],
      [
        payouts && payouts.length > 0
          ? payouts.map((payout) => payout.campaignName)
          : "No data",
        payouts && payouts.length > 0
          ? payouts.map((payout) => payout.paidCommission)
          : "No data",
        payouts && payouts.length > 0
          ? payouts.map((payout) => payout.unPaidCommission)
          : "No data",
        payouts && payouts.length > 0
          ? payouts.map((payout) => payout.totalConversion)
          : "No data",
      ],
    ]);
  }, [payouts]);

  const renderPayoutsData = () => {
    return loading ? (
      <>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
      </>
    ) : (
      payouts.map((payout) => {
        const {
          campaignId: id,
          campaignName,
          paidCommission,
          unPaidCommission,
          totalConversion,
        } = payout;
        return (
          <tr key={id}>
            <td>
              <label class="customContainer">
                <input type="checkbox" />
                <span class="customCheckmark"></span>
              </label>
            </td>
            <td>{campaignName}</td>
            <td>{paidCommission}</td>
            <td>{unPaidCommission}</td>
            <td>{totalConversion}</td>
          </tr>
        );
      })
    );
  };

  const renderPayoutsHeader = () => {
    let header = [
      "",
      "campaign",
      "Paid commissions",
      "Unpaid commissions",
      "total conversion",
    ];
    return header.map((key, index) => {
      return <th key={index}>{key}</th>;
    });
  };

  return (
    <>
      {payouts.length === 0 && !loading ? (
        <NoData />
      ) : (
        <TableWrapper>
          <MainTable>
            <tbody>
              <tr
                style={{
                  width: "100%",
                }}
              >
                {renderPayoutsHeader()}
              </tr>
              {renderPayoutsData()}
            </tbody>
          </MainTable>
        </TableWrapper>
      )}
    </>
  );
};

export default PayoutsTable;
