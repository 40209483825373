import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { ToggleSidebarContextProvider } from "context/toggle-sidebar";
import App from "App";
import store from "redux/store";
import "./index.css";
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <CookiesProvider>
        <ToggleSidebarContextProvider>
          <App />
        </ToggleSidebarContextProvider>
      </CookiesProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
