import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Input from "../component";
import { Box, FormGroup, BoxTitle, Small, Button, Main } from "../styles";
import { resetPassword } from "redux/actions/auth/auth";

const ResetPassword = () => {
  const {t, i18n} = useTranslation('common');
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();
  //   const signin = useSelector(state => state.signin);
  const history = useHistory();

  const data = {
    password: password,
    domain: "trace",
    token: "36931",
  };

  const onSubmit = (e) => {
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    e.preventDefault();
    console.log(data);
    dispatch(resetPassword(data, history));
  };

  return (
    <Main style={{ height: "100vh" }}>
      <Box>
        <BoxTitle>{t("auth.forgotTitle")}</BoxTitle>
        <Small>{t("auth.resetTitle")}</Small>
        <FormGroup>
          <Input
            type="password"
            label={t("auth.nPassword")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Input
            type="password"
            label={t("auth.confirmPassword")}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </FormGroup>

        <FormGroup>
          <Button
            disabled={!password || !confirmPassword ? true : false}
            style={{
              cursor: !password || !confirmPassword ? "not-allowed" : "pointer",
            }}
            onClick={onSubmit}
          >
            {t("auth.forgotCta")}
          </Button>
        </FormGroup>
        <div>
          <Small style={{ width: "100%" }}>
          {t("auth.forgotHelp")}{" "}
            <a href="mailto:support@metricks.io">support@metricks.io</a>
          </Small>
        </div>
      </Box>
    </Main>
  );
};

export default ResetPassword;
