/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DOMAIN } from "constant";
import Input from "../component";
import {
  CheckBox,
  Box,
  FormGroup,
  BoxTitle,
  Small,
  Button,
  Main,
} from "../styles";
import { signInAff } from "redux/actions/auth/signin";

const SignIn = () => {
  const {t, i18n} = useTranslation('common');
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  const data = {
    email: email,
    password: password,
    history: history,
  };

  const onSubmit = () => {
    var dom = /:\/\/([^\/]+)/.exec(window.location.origin)[1];
    let subDomain = dom.split(".", -1)[0];
    if (subDomain == 'www') {
      subDomain = `${subDomain}.${dom.split(".")[1]}`;
    }
    let domain;
    subDomain.includes("localhost") ? (domain = DOMAIN) : (domain = subDomain);
    console.log(domain);
    const campaignUrl = localStorage.getItem("campaignUrl");
    if (campaignUrl) {
      data.campaignUrl = campaignUrl;
    }
    dispatch(signInAff(data, domain));
  };

  return (
    <Main>
      <Box>
        <BoxTitle>{t("auth.welcomeBack")}</BoxTitle>
        <Small>{t("auth.affiliateLogin")}</Small>
        <FormGroup>
          <Input
            type="email"
            label={t("auth.email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Input
            type="password"
            label={t("auth.password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormGroup>

        <FormGroup
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <CheckBox
              type="checkbox"
              style={{
                accentColor: "#81bed4",
              }}
            />
            {t("auth.rememberMe")}
          </div>

          <Link to="/auth/forgot-password/">{t("auth.forgotPassword")}?</Link>
        </FormGroup>

        <FormGroup>
          <Button
            disabled={!email || !password ? true : false}
            style={{
              cursor: !email || !password ? "not-allowed" : "pointer",
            }}
            onClick={onSubmit}
          >
            {t("auth.continue")}
          </Button>
        </FormGroup>

        <p>
        {t("auth.noAccount")}?
          <span>
            <Link to="/">{t("auth.signUpCta")}</Link>
          </span>
        </p>

        {/* <Recaptcha>
          <CheckBox
            type="checkbox"
            style={{
              accentColor: "#81bed4",
            }}
          />
          <small>I'm not a Robot</small>
          <img src={Rcapt} alt="Recaptcha" height={30} />
        </Recaptcha> */}
      </Box>
    </Main>
  );
};

export default SignIn;
