import { FETCH_AFF_PAYOUTS_HIST, FETCH_AFF_PAYOUTS_OVER } from "redux/types";

const INITIAL_STATE = {
  loading: false,
  historyLoading: false,
  payouts: [],
  payouts_history: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_AFF_PAYOUTS_OVER.PENDING: {
      return { ...state, loading: true };
    }
    case FETCH_AFF_PAYOUTS_OVER.SUCCESS: {
      return { ...state, loading: false, payouts: action.payload.data };
    }
    case FETCH_AFF_PAYOUTS_OVER.ERROR: {
      return { ...state, loading: false };
    }
    case FETCH_AFF_PAYOUTS_HIST.PENDING: {
      return { ...state, loading: true };
    }
    case FETCH_AFF_PAYOUTS_HIST.SUCCESS: {
      return { ...state, loading: false, payouts_history: action.payload.data };
    }
    case FETCH_AFF_PAYOUTS_HIST.ERROR: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
};

export default reducer;
