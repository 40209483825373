import { COLLAPSE_SIDEBAR } from "redux/types";

const INITIAL_STATE = {
  collapsed: true,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COLLAPSE_SIDEBAR:
      return { collapsed: !state.collapsed };
    default:
      return state;
  }
};

export default reducer;
