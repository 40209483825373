/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import BreadCrumb from "components/breadcrumb";
import MainHeader from "components/header";
import ProfileSettings from "components/main/dashboard/profile-settings";
import { getProfileInfo } from "redux/actions/profile";

const ProfileSetup = () => {
  const {t, i18n} = useTranslation('common');
  const dispatch = useDispatch();
  const nav = [
    {
      url: "/affiliate/overview",
      label: t("general.dashboard"),
    },
    {
      url: "/affiliate/profile-settings",
      label: t("profile.settings"),
      main: true,
    },
  ];

  useEffect(() => {
    dispatch(getProfileInfo());
  }, []);
  return (
    <>
      <MainHeader title={t("general.dashboard")} />
      <BreadCrumb links={nav} />
      <ProfileSettings />
    </>
  );
};

export default ProfileSetup;
