import { combineReducers } from "redux";

import collapserReducer from "redux/reducers/collapser";

import affiliateReport from "redux/reducers/dashboard/dashboard";
import affSiginInReducer from "redux/reducers/auth/signin";
import affSignUpReducer from "redux/reducers/auth/signup";
import affCampaignsReducer from "redux/reducers/campaigns";
import affCommissionsReducer from "redux/reducers/commissions";
import affPayoutsReducer from "redux/reducers/payouts";
import affProfileReducer from "redux/reducers/profile";
import affAssetsReducer from "redux/reducers//mktassets";
import authStateReducer from "redux/reducers/authState";

const rootReducer = combineReducers({
  auth_state: authStateReducer,
  aff_reports: affiliateReport,
  aff_signin: affSiginInReducer,
  aff_campaigns: affCampaignsReducer,
  aff_commissions: affCommissionsReducer,
  aff_payouts: affPayoutsReducer,
  aff_profile: affProfileReducer,
  aff_assets: affAssetsReducer,
  aff_signup: affSignUpReducer,
  collapser: collapserReducer,
});

export default rootReducer;
