/** @format */

import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Input from "../component";
import {
  BoxTitle,
  Small,
  FormGroup,
  Box,
  Label,
  Button,
  Main,
} from "../styles";
import PayPal from "assets/images/paypal.png";
import {
  setupProfile,
  completeStepThree,
  decreaseStep,
} from "redux/actions/auth/auth";
import AffiliateData from "utils/user-data";

const Step3 = () => {
  const {t, i18n} = useTranslation('common');
  const history = useHistory();
  const loggedInAff = new AffiliateData();
  const { profile } = useSelector((state) => state.aff_signup);

  const dispatch = useDispatch();
  const domain = localStorage.getItem("portalUrl");

  const [form, setForm] = useState({
    company: "",
    description: "",
    website: "",
    paypal_email: "",
  });

  useEffect(() => {
    dispatch(completeStepThree(form));
  }, [form]);

  function handleChange(evt) {
    const value = evt.target.value;
    setForm({
      ...form,
      [evt.target.name]: value,
    });
  }

  const search = useLocation().search;

  const affId = new URLSearchParams(search).get("id");

  function onSubmit() {
    dispatch(
      setupProfile(
        domain,
        loggedInAff.get().data.affiliateId != undefined
          ? loggedInAff.get().data.affiliateId
          : affId,
        profile,
        history
      )
    );
  }

  const temp = JSON.parse(localStorage.getItem("template"));

  return (
    <Main>
      <Box
        style={{
          top: "45%",
        }}
      >
        <BoxTitle>{temp.data.templates.campaign_title}</BoxTitle>

        <button className="back" onClick={() => dispatch(decreaseStep())}>
        {t("auth.back")}
        </button>
        <Small>{t("auth.step3Desc")}</Small>
        <Small
          style={{
            fontWeight: "bold",
          }}
        >
          {t("auth.step3")}
        </Small>
        <FormGroup>
          <Input
            defaultValue={profile?.company}
            type="text"
            label={t("auth.company")}
            name="company"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Input
            defaultValue={profile?.description}
            type="text"
            label={t("auth.stepDescription")}
            name="description"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Input
            defaultValue={profile?.website}
            type="text"
            label={t("auth.website")}
            name="website"
            onChange={handleChange}
          />
        </FormGroup>

        <FormGroup>
          <Label>Payout Method details</Label>
          <img src={PayPal} alt="paypal" width={139} />
        </FormGroup>

        <FormGroup>
          <Input
            defaultValue={profile?.paypal_email}
            type="text"
            label={t("auth.paypalEmail")}
            name="paypal_email"
            onChange={handleChange}
          />
        </FormGroup>

        <FormGroup>
          <Button onClick={onSubmit}>{t("auth.next")}</Button>
        </FormGroup>
      </Box>
    </Main>
  );
};

export default Step3;
