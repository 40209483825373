import React, { useState } from "react";
import { Intro } from "components/containers";
import { Title, Description } from "components/typography";
import { Section, FilterActions, FilterTitle, Wrapper } from "../../styles";
import ReportsFilterer from "components/filters/reports";
import * as FaIcons from "react-icons/fa";
import { ExportButton } from "components/buttons";
import PayoutsHistoryTable from "components/tables/payouts-history";

const PayoutHistory = () => {
  const [showFilter, setShowFilter] = useState(false);

  return (
    <Section>
      <Intro>
        <Title>Payouts History</Title>
        <Description>
          Find a summary of all commissions you have received for your
          conversions.
        </Description>
      </Intro>
      <Wrapper>
        <FilterActions>
          <FilterTitle onClick={() => setShowFilter(!showFilter)}>
            Filter
            <FaIcons.FaFilter
              style={{
                background: "#dbcee9",
                padding: 7,
                fontSize: 28,
              }}
            />
          </FilterTitle>

          <ExportButton caption="Export Payout" />
        </FilterActions>
        {showFilter && <ReportsFilterer />}
        <PayoutsHistoryTable />
      </Wrapper>
    </Section>
  );
};

export default PayoutHistory;
