import {
  FETCH_SOCIAL_ASSETS,
  FETCH_IMAGE_ASSETS,
  FETCH_VIDEO_ASSETS,
  FETCH_DOC_ASSETS,
  FETCH_SINGLE_ASSET,
} from "redux/types";

const INITIAL_STATE = {
  loading: false,
  smAssets: [],
  imageAssets: [],
  videoAssets: [],
  docAssets: [],
  singleAsset: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SOCIAL_ASSETS.PENDING: {
      return { ...state, loading: true };
    }
    case FETCH_SOCIAL_ASSETS.SUCCESS: {
      return { ...state, loading: false, smAssets: action.payload.data };
    }
    case FETCH_SOCIAL_ASSETS.ERROR: {
      return { ...state, loading: false };
    }
    case FETCH_IMAGE_ASSETS.PENDING: {
      return { ...state, loading: true };
    }
    case FETCH_IMAGE_ASSETS.SUCCESS: {
      return { ...state, loading: false, imageAssets: action.payload.data };
    }
    case FETCH_IMAGE_ASSETS.ERROR: {
      return { ...state, loading: false };
    }
    case FETCH_VIDEO_ASSETS.PENDING: {
      return { ...state, loading: true };
    }
    case FETCH_VIDEO_ASSETS.SUCCESS: {
      return { ...state, loading: false, videoAssets: action.payload.data };
    }
    case FETCH_VIDEO_ASSETS.ERROR: {
      return { ...state, loading: false };
    }
    case FETCH_DOC_ASSETS.PENDING: {
      return { ...state, loading: true };
    }
    case FETCH_DOC_ASSETS.SUCCESS: {
      return { ...state, loading: false, docAssets: action.payload.data };
    }
    case FETCH_DOC_ASSETS.ERROR: {
      return { ...state, loading: false };
    }
    case FETCH_SINGLE_ASSET.PENDING: {
      return { ...state, loading: true };
    }
    case FETCH_SINGLE_ASSET.SUCCESS: {
      return { ...state, loading: false, singleAsset: action.payload.data };
    }
    case FETCH_SINGLE_ASSET.ERROR: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
};

export default reducer;
