import React from "react";
import { BubbleWrapper } from "./svg.styles";

const Bubbles = () => {
   return (
      <BubbleWrapper>
         <svg
            width="1192"
            height="309"
            viewBox="0 0 1192 309"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <circle
               cx="29.8744"
               cy="30.2972"
               r="29.3284"
               transform="rotate(-16.739 29.8744 30.2972)"
               fill="url(#paint0_linear_0_1)"
            />
            <circle
               cx="115.964"
               cy="261.075"
               r="47.3831"
               transform="rotate(-16.739 115.964 261.075)"
               fill="url(#paint1_linear_0_1)"
            />
            <circle
               cx="1147.16"
               cy="48.5595"
               r="44.0511"
               transform="rotate(47.4513 1147.16 48.5595)"
               fill="url(#paint2_linear_0_1)"
            />
            <circle
               cx="958.772"
               cy="218.894"
               r="27.8932"
               transform="rotate(47.4513 958.772 218.894)"
               fill="url(#paint3_linear_0_1)"
            />
            <path d="M1035.68 107.327L1014.39 128.679" stroke="#D6CFDF" />
            <defs>
               <linearGradient
                  id="paint0_linear_0_1"
                  x1="9.66596"
                  y1="0.968719"
                  x2="34.0411"
                  y2="45.3957"
                  gradientUnits="userSpaceOnUse"
               >
                  <stop stopColor="#7211D4" />
                  <stop offset="1" stopColor="white" stopOpacity="0" />
               </linearGradient>
               <linearGradient
                  id="paint1_linear_0_1"
                  x1="83.3147"
                  y1="213.692"
                  x2="122.695"
                  y2="285.468"
                  gradientUnits="userSpaceOnUse"
               >
                  <stop stopColor="#4665A8" />
                  <stop offset="1" stopColor="white" stopOpacity="0" />
               </linearGradient>
               <linearGradient
                  id="paint2_linear_0_1"
                  x1="1116.81"
                  y1="4.50847"
                  x2="1153.42"
                  y2="71.2374"
                  gradientUnits="userSpaceOnUse"
               >
                  <stop stopColor="#FB9B54" />
                  <stop offset="1" stopColor="white" stopOpacity="0" />
               </linearGradient>
               <linearGradient
                  id="paint3_linear_0_1"
                  x1="939.552"
                  y1="191.001"
                  x2="962.735"
                  y2="233.253"
                  gradientUnits="userSpaceOnUse"
               >
                  <stop stopColor="#C44CC0" />
                  <stop offset="1" stopColor="white" stopOpacity="0" />
               </linearGradient>
            </defs>
         </svg>
      </BubbleWrapper>
   );
};

export { Bubbles };
