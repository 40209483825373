/** @format */

import { asyncType } from "redux/types/asyncTypes";

export {
  SIGN_IN_AFF,
  LOGIN_AFF,
  SIGN_OUT_AFF,
  SIGN_UP_STEP,
  SIGN_UP_AFF,
  SETUP_PROFILE_THREE,
  SETUP_PROFILE_TWO,
  COMPLETE_SETUP,
} from "./auth";

export const COLLAPSE_SIDEBAR = "COLLAPSE_SIDEBAR";
export { FETCH_REPORTS, FETCH_DETAILS } from "./dashboard";
export { FETCH_ADV_CAMPAIGNS, JOIN_CAMPAIGN } from "./campaigns";
export const FETCH_CAMPAIGNS = asyncType("FETCH_ADV_CAMPAIGNS");
export {
  FETCH_AFF_COMMISSIONS,
  FETCH_AFF_REC_COMMISSIONS,
} from "./commissions";
export { FETCH_AFF_PAYOUTS_OVER, FETCH_AFF_PAYOUTS_HIST } from "./payouts";
export {
  FETCH_SOCIAL_ASSETS,
  FETCH_IMAGE_ASSETS,
  FETCH_DOC_ASSETS,
  FETCH_VIDEO_ASSETS,
  FETCH_SINGLE_ASSET,
} from "./mktassets";
export const UPDATE_PROFILE = asyncType("UPDATE_PROFILE");
export const GET_PROFILE = asyncType("GET_PROFILE");
export const PROFILE_ACTION = asyncType("PROFILE_ACTION");
