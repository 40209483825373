import axios from "axios";
import { toast } from "react-toastify";
import {
  updateAffProfile,
  getProfileData,
  uploadImage,
  deleteImage,
} from "redux/apiRoutes";
import { UPDATE_PROFILE, GET_PROFILE, PROFILE_ACTION } from "redux/types";
import AuthSession from "utils/session-storage";
import AffiliateData from "utils/user-data";

export const updateAffiliateProfile = (data) => (dispatch) => {
  dispatch({ type: UPDATE_PROFILE.PENDING, payload: null });
  const authSessionToken = new AuthSession().get();
  const headers = {
    Authorization: `Bearer ${authSessionToken.token}`,
  };
  axios
    .post(updateAffProfile, data, {
      headers,
    })
    .then((response) => {
      dispatch({ type: UPDATE_PROFILE.SUCCESS, payload: null });
      toast.success("Update successful");
      dispatch(getProfileInfo());
    })
    .catch((err) => {
      dispatch({ type: UPDATE_PROFILE.ERROR, payload: null });
      toast.error("Error Updating");
    });
};

export const getProfileInfo = () => (dispatch) => {
  dispatch({ type: GET_PROFILE.PENDING, payload: null });
  const authSessionToken = new AuthSession().get();
  const headers = {
    Authorization: `Bearer ${authSessionToken.token}`,
  };
  const affData = new AffiliateData().get();

  const data = {
    affId: affData.data.affiliateId,
    domain: affData.data.affiliate.domain,
  };
  axios
    .post(getProfileData, data, {
      headers,
    })
    .then((response) => {
      dispatch({ type: GET_PROFILE.SUCCESS, payload: response.data });
    })
    .catch((err) => {
      dispatch({ type: GET_PROFILE.ERROR, payload: null });
      toast.error("Eroor fetching data");
    });
};

export const uploadProfileImage = (file) => (dispatch) => {
  let formData = new FormData();
  const affData = new AffiliateData().get();
  const authSessionToken = new AuthSession().get();

  dispatch({ type: PROFILE_ACTION.PENDING, payload: null });
  const headers = {
    Authorization: `Bearer ${authSessionToken.token}`,
    "Content-Type": "multipart/form-data",
  };

  formData.append("image", file);
  formData.append("affId", `${affData.data.affiliateId}`);
  axios
    .post(uploadImage, formData, { headers })
    .then((response) => {
      dispatch({ type: PROFILE_ACTION.SUCCESS, payload: null });
      toast.success("Image uploaded sucessfully");
      dispatch(getProfileInfo());
    })
    .catch((err) => {
      dispatch({ type: PROFILE_ACTION.ERROR, payload: null });
      toast.error("Error Uploading Image");
    });
};

export const deleteProfileImage = () => (dispatch) => {
  dispatch({ type: PROFILE_ACTION.PENDING, payload: null });
  const authSessionToken = new AuthSession().get();
  const headers = {
    Authorization: `Bearer ${authSessionToken.token}`,
  };
  const affData = new AffiliateData().get();
  const data = {
    affId: affData.data.affiliateId,
  };
  axios
    .post(deleteImage, data, { headers })
    .then((response) => {
      dispatch({ type: PROFILE_ACTION.SUCCESS, payload: null });
      toast.success("Profile Image removed");
      dispatch(getProfileInfo());
    })
    .catch((err) => {
      dispatch({ type: PROFILE_ACTION.ERROR, payload: null });
      toast.error("Error deleting profile image");
    });
};
