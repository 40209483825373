import React from "react";
import Logo from "assets/images/metricks-white.png";
import Logo2 from "assets/images/metricks.png";
import { BrandImageStyledContainer, BrandImageStyledComponent } from "./brand-image.styles";

const BrandImage = () => {
   return (
      <BrandImageStyledContainer>
         <BrandImageStyledComponent src={Logo} alt="Metricks" />
      </BrandImageStyledContainer>
   );
};

const BrandImage2 = () => {
   return (
      <BrandImageStyledContainer>
         <BrandImageStyledComponent src={Logo2} alt="Metricks" />
      </BrandImageStyledContainer>
   );
};

export { BrandImage, BrandImage2 };
