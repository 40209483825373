/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ImageCard } from "components/card";
import { fetchSingleAsset } from "redux/actions/mktassets";
import AffiliateData from "utils/user-data";

const ImageAssetsView = ({ assetId }) => {
  const dispatch = useDispatch();
  const loggedInAff = new AffiliateData();

  const data = {
    domain: loggedInAff.get().data.affiliate.domain,
    affId: loggedInAff.get().data.affiliate.id,
  };

  useEffect(() => {
    dispatch(fetchSingleAsset("image", assetId, data));
  }, []);

  return (
    <div style={style}>
      <ImageCard />
    </div>
  );
};

const style = {
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: "2em",
  padding: "2em",
};

export default ImageAssetsView;
