/**
 * /* eslint-disable no-useless-escape
 *
 * @format
 */

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Skeleton, Modal } from "@mui/material";
import { ClickAwayListener, Tooltip } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { ThemeContext } from "styled-components";
import { Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchAffDetails, fetchAffReports } from "redux/actions/dashboard";
import { campaignOtions, dateOtions } from "utils/select-data";
import { ExportButton } from "components/buttons";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";

import {
  FacebookShareCount,
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
  TelegramIcon,
} from "react-share";
import {
  Box,
  BoxContent,
  ControlGroup,
  Controls,
  Intro,
  Reports,
  ReportsContainer,
  Stats,
} from "components/containers";
import { CBIcon } from "components/icons/image-icons";
import { BoxTitle, Description, SmallText, Title } from "components/typography";
import {
  BoxDivHead,
  Content,
  GraphTab,
  IconWrapper,
  Input,
  Section,
  SmallSelectInButton,
} from "../styles";
import AffiliateData from "utils/user-data";
import MainSelect from "components/main-select";
import { useHistory } from "react-router-dom";

const title = "Metricks";

const AffiliateDashBoard = () => {
  const {t, i18n} = useTranslation('common');
  const loggedInAff = new AffiliateData();
  const theme = useContext(ThemeContext);

  const shareUrl = loggedInAff.get().data.affiliate.affiliate_url;

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const LINK = window.location.origin;

  const dispatch = useDispatch();
  const { loading, reports } = useSelector((state) => state.aff_reports);
  const { campaigns } = useSelector((state) => state.aff_campaigns);

  const options =
    campaigns === null
      ? []
      : campaigns.map((el) => ({ label: el.name, value: el.id }));

  const [campaign, setCampaign] = useState(campaignOtions[1]);
  const [date, setDate] = useState(dateOtions[2]);

  const [graphData, setGraphData] = useState("conv");
  const [startDate, setStartDate] = useState(
    new Date().setDate(new Date().getDate() - 10)
  );

  const filterCampaign = (campId) => {
    dispatch(
      fetchAffReports(
        {
          domain: loggedInAff.get().data.affiliate.domain,
          affId: loggedInAff.get().data.affiliate.id,
        },
        {
          campId,
        }
      )
    );
  };

  const chartData = (canvas, graphType) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 300);

    gradient.addColorStop(0, "#C4C4C4");
    gradient.addColorStop(1, "rgba(196, 196, 196, 0)");
    return {
      labels:
        graphType === "conv" && reports
          ? reports.trafficConversion.map((conv) => conv.dateString)
          : reports && reports.trafficLeads.map((lead) => lead.dateString),
      datasets: [
        {
          label: "",
          data:
            graphType === "conv" && reports
              ? reports.trafficConversion.map((conv) => conv.amount)
              : reports && reports.trafficLeads.map((lead) => lead.leads),
          backgroundColor: gradient,
          borderColor: [
            "#7211D4",
            "#7211D4",
            "#7211D4",
            "#E211D4",
            "#7211D4",
            "#7211D4",
          ],
          borderWidth: 0.5,
          fill: "start",
        },
      ],
    };
  };

  const handleDaySelect = (days) => {
    console.log(parseInt(days));
    const event = new Date();
    event.setDate(new Date().getDate() - parseInt(days));
    // console.log(startDateGenerator);
    console.log(event);
    setStartDate(event);
  };
  const history = useHistory();
  console.log({ currentAffiliate: loggedInAff.get().data });
  useEffect(() => {
    if (!loggedInAff.get().data.affiliate.domain) {
      toast.error("no domain");
      history.push("/auth/login");
    } else {
      dispatch(
        fetchAffReports(
          {
            domain: loggedInAff.get().data.affiliate.domain,
            affId: loggedInAff.get().data.affiliate.id,
          },
          {
            campId: loggedInAff.get().data.campaign.id,
          }
        )
      );
      dispatch(
        fetchAffDetails({
          domain: loggedInAff.get().data.affiliate.domain,
          affId: loggedInAff.get().data.affiliate.id,
        })
      );
    }
  }, []);

  const csvData = [
    ["AFFILIATE URL", reports && reports.affiliateURL],
    ["APPROVED COMMISSION", reports && reports.approvedCommissions],
    ["CONVERSIONS", reports && reports.conversions],
    ["DECLINED COMMISSION", reports && reports.declinedCommissions],
    ["OVERIDE COMMISSION", reports && reports.overrideCommissions],
    ["PAID COMMISSION", reports && reports.paidCommissions],
    ["PENDING COMMISSION", reports && reports.pendingCommissions],
    // [
    //   "TRAFFIC CONVERSION",
    //   reports && reports.trafficConversion.length < 1
    //     ? 0
    //     : reports.trafficConversion.map((conv) => ` ${conv}`),
    // ],
    // [
    //   "TRAFFIC LEADS",
    //   reports && reports.trafficLeads.length < 1
    //     ? 0
    //     : reports.trafficLeads.map((lead) => ` ${lead}`),
    // ],
  ];

  return (
    <>
      {loggedInAff.get().data.affiliate ? (
        <Section>
          <Intro>
            <Title>
              Welcome Back, {loggedInAff.get().data.affiliate.firstname}
            </Title>
            <Description>Here's your latest report. </Description>
          </Intro>
          <Reports>
            <Controls>
              <CSVLink
                data={csvData}
                filename={"General report.csv"}
                style={{ textDecoration: "none" }}
              >
                <ExportButton caption={t("dashboard.exportReport")} />
              </CSVLink>

              <ControlGroup>
                <MainSelect
                  label={null}
                  options={options}
                  placeholder={t("dashboard.selectCampaign")}
                  onChange={(e) => {
                    // setCampaign(e);
                    filterCampaign(e.value);
                  }}
                />
                <MainSelect
                  options={dateOtions}
                  label={null}
                  placeholder={t("dashboard.selectDate")}
                />
              </ControlGroup>
            </Controls>
            <ReportsContainer>
              <Stats>
                <IconWrapper>
                  <CBIcon />
                </IconWrapper>
                <div>
                  <SmallText>
                    {reports ? (
                      t("dashboard.conversions")
                    ) : (
                      <Skeleton variant="text" width={150} />
                    )}
                  </SmallText>
                  <Title>
                    {reports ? reports.conversions : <Skeleton width={150} />}
                  </Title>
                </div>
              </Stats>
              <Stats>
                <IconWrapper>
                  <CBIcon />
                </IconWrapper>
                <div>
                  <SmallText>
                    {reports ? (
                      t("dashboard.paidCommissions")
                    ) : (
                      <Skeleton variant="text" width={150} />
                    )}
                  </SmallText>
                  <Title>
                    {reports ? (
                      reports.paidCommissions
                    ) : (
                      <Skeleton width={150} />
                    )}
                  </Title>
                </div>
              </Stats>
              <Stats>
                <IconWrapper>
                  <CBIcon />
                </IconWrapper>
                <div>
                  <SmallText>
                    {reports ? (
                      t("dashboard.pendingCommissions")
                    ) : (
                      <Skeleton variant="text" width={150} />
                    )}
                  </SmallText>
                  <Title>
                    {reports ? (
                      reports.pendingCommissions
                    ) : (
                      <Skeleton width={150} />
                    )}
                  </Title>
                </div>
              </Stats>

              <Stats>
                <IconWrapper>
                  <CBIcon />
                </IconWrapper>
                <div>
                  <SmallText>
                    {reports ? (
                      t("dashboard.overriddenCommissions")
                    ) : (
                      <Skeleton variant="text" width={150} />
                    )}
                  </SmallText>
                  <Title>
                    {reports ? (
                      reports.overrideCommissions
                    ) : (
                      <Skeleton width={150} />
                    )}
                  </Title>
                </div>
              </Stats>

              <Stats>
                <IconWrapper>
                  <CBIcon />
                </IconWrapper>
                <div>
                  <SmallText>
                    {reports ? (
                      t("dashboard.approvedCommissions")
                    ) : (
                      <Skeleton variant="text" width={150} />
                    )}
                  </SmallText>
                  <Title>
                    {reports ? (
                      reports.approvedCommissions
                    ) : (
                      <Skeleton width={150} />
                    )}
                  </Title>
                </div>
              </Stats>

              <Stats>
                <IconWrapper>
                  <CBIcon />
                </IconWrapper>
                <div>
                  <SmallText>
                    {reports ? (
                      t("dashboard.declinedCommissions")
                    ) : (
                      <Skeleton variant="text" width={150} />
                    )}
                  </SmallText>
                  <Title>
                    {reports ? (
                      reports.declinedCommissions
                    ) : (
                      <Skeleton width={150} />
                    )}
                  </Title>
                </div>
              </Stats>
            </ReportsContainer>
          </Reports>

          {/* <Box>
            <BoxTitle>View Traffic</BoxTitle>
            <BoxDivHead>
              <div
                className="head"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "1.0rem",
                  fontWeight: "lighter",
                  justifyContent: "space-between",
                  width: "65%",
                  marginRight: "2rem",
                }}
              >
                <GraphTab
                  className="slide-this"
                  active={graphData === "conv"}
                  onClick={() => setGraphData("conv")}
                >
                  Conversions
                  <span></span>
                </GraphTab>
                <GraphTab
                  className="slide-this"
                  active={graphData === "leads"}
                  onClick={() => setGraphData("leads")}
                >
                  Leads
                  <span></span>
                </GraphTab>
              </div>

              <div
                className="head"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "0.82rem",
                  fontWeight: "lighter",
                  width: "35%",
                }}
              >
                <label for="graph" style={{ margin: "auto 0" }}>
                  From last
                </label>
                <SmallSelectInButton
                  onChange={(e) => handleDaySelect(e.target.value)}
                  id="graph"
                >
                  <option value={10}>10 days</option>
                  <option value={15}>15 days</option>
                  <option value={30}>30 days</option>
                  <option value={90}>quarter</option>
                  <option value={365}>year</option>
                </SmallSelectInButton>
              </div>
            </BoxDivHead>
            <Content>
              {loading ? (
                <Skeleton />
              ) : (
                <Line
                  data={(canvas) => chartData(canvas, graphData)}
                  options={{
                    scales: {
                      y: {
                        beginAtZero: true,
                      },
                    },
                  }}
                />
              )}
            </Content>
          </Box> */}

          <Box>
            <BoxTitle>{t("dashboard.affiliateUrl")}</BoxTitle>
            <BoxContent>
              <p style={{ maxWidth: 600 }}>
                {t("dashboard.shareMessage")}{" "}
              </p>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1em",
                  margin: "1.5em 0",
                }}
              >
                <Input
                  value={reports ? reports.affiliateURL : ""}
                  disabled
                  // placeholder={loggedInAff.get().data.affiliate.affiliate_url}
                />

                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Copied"
                  >
                    <span
                      style={{ color: theme.primaryColor, cursor: "pointer" }}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${reports.affiliateURL}`
                        );
                        handleTooltipOpen();
                      }}
                    >
                      Copy link
                    </span>
                  </Tooltip>
                </ClickAwayListener>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1em",
                  margin: "3.5em 0",
                }}
              >
                <p>Share to:</p>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1em" }}
                >
                  <div className="Demo__some-network">
                    <FacebookShareButton
                      url={shareUrl}
                      quote={title}
                      className="Demo__some-network__share-button"
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>

                    <div>
                      <FacebookShareCount
                        url={shareUrl}
                        className="Demo__some-network__share-count"
                      >
                        {(count) => count}
                      </FacebookShareCount>
                    </div>
                  </div>

                  <div className="Demo__some-network">
                    <TwitterShareButton
                      url={shareUrl}
                      title={title}
                      className="Demo__some-network__share-button"
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                  </div>

                  <div className="Demo__some-network">
                    <TelegramShareButton
                      url={shareUrl}
                      title={title}
                      className="Demo__some-network__share-button"
                    >
                      <TelegramIcon size={32} round />
                    </TelegramShareButton>
                  </div>

                  <div className="Demo__some-network">
                    <LinkedinShareButton
                      url={shareUrl}
                      className="Demo__some-network__share-button"
                    >
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                  </div>

                  <div className="Demo__some-network">
                    <EmailShareButton
                      url={shareUrl}
                      subject={title}
                      body="body"
                      className="Demo__some-network__share-button"
                    >
                      <EmailIcon size={32} round />
                    </EmailShareButton>
                  </div>
                </div>
              </div>
            </BoxContent>
          </Box>
        </Section>
      ) : (
        history.push("/auth/login")
      )}
    </>
  );
};

export default AffiliateDashBoard;
